import { useEffect, forwardRef, memo } from "react";
import classNames from "classnames";

import { Handle } from "components/ui-elements/drag-and-drop/components/Item/components/Handle/Handle";
import styles from "components/ui-elements/drag-and-drop/components/Item/Item.module.scss";

export const Item = memo(
  forwardRef(
    (
      {
        color,
        children,
        dragOverlay,
        dragging,
        actions,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        item,
        onClick,
        wrapperStyle,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay) return;
        document.body.style.cursor = "grabbing";
        return () => (document.body.style.cursor = "");
      }, [dragOverlay]);

      const itemClck = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick(value, e);
      };

      return (
        <li
          onClick={itemClck}
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay,
            "border-primary",
            "border-2",
            "border-start",
            item?.className,
          )}
          style={{
            ...wrapperStyle,
            transition: [transition, wrapperStyle?.transition]
              .filter(Boolean)
              .join(", "),
            "--translate-x": transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            "--translate-y": transform
              ? `${Math.round(transform.y)}px`
              : undefined,
            "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
            "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
            "--index": index,
            "--color": "#7193f1",
          }}
          ref={ref}
        >
          <div
            className={classNames(
              styles.Item,
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color,
            )}
            style={style}
            data-cypress="draggable-item"
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {children}
            <span className={styles.Actions}>
              {!disabled && <Handle {...handleProps} {...listeners} />}
              {actions}
            </span>
          </div>
        </li>
      );
    },
  ),
);
