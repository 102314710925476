import React, { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import ReactHtmlParser from 'html-react-parser';
import { useSelector } from 'react-redux';
import { _getUser } from '../auth/loginSlice';

export function EditorTinyMCE({selectedItem, setSelectedItem, type, label}) {
    const [wysiwyg, setWysiwyg] = useState('');
    const editorRef = useRef(null);
    const [editState, setEditState] = useState(selectedItem[type] === null)
    const log = () => {
        if (editorRef.current) {
            let content = editorRef.current.getContent();
            setWysiwyg(content);
            if (selectedItem) {
                setSelectedItem({...selectedItem, [type]:  content?.length > 0 ? content : null});
            }
        }
    };
    const user = useSelector(_getUser);
    const tinymcepath = user?.use_local_tinymce? process.env.REACT_APP_ENDPOINT + '/tinymce/tinymce.min.js': null;

    useEffect(() => {
        setWysiwyg(selectedItem[type] ? selectedItem[type] : '');
    }, [selectedItem]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const myModal = document.getElementById('work_flow_modal');
        myModal.addEventListener('shown.bs.modal', focusTinyMCE);
        return (() => {
            myModal.removeEventListener('shown.bs.modal', focusTinyMCE);
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const focusTinyMCE = (e) => {
        e.stopImmediatePropagation();
        if (editorRef?.current) {
            editorRef.current.focus();
        }
    };
    const initEditor = {
        elementpath: false,
        branding: false,
        height: 800,
        plugins: 'print preview powerpaste casechange importcss searchreplace autolink save directionality advcode visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap emoticons advtable export',
        mobile: {
            plugins: 'print preview powerpaste casechange importcss searchreplace autolink save directionality advcode visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap emoticons advtable'
        },
        menubar: 'file edit view insert format tools table tc help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        image_advtab: true,
        importcss_append: true,
        // templates: [
        //         { title: 'New Table', description: 'creates a new table', content: '<div className="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
        //     { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
        //     { title: 'New list with dates', description: 'New List with dates', content: '<div className="mceTmpl"><span className="cdate">cdate</span><br /><span className="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
        // ],
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
        contextmenu: 'link image imagetools table configurepermanentpen',
        a11y_advanced_options: true,
        powerpaste_word_import: 'prompt',
        powerpaste_html_import: 'prompt',
        image_title: true,
        file_picker_types: 'image',
        default_link_target: '_blank',
        extended_valid_elements : "a[href|target=_blank]"
    }

    const getImagesOnDialog = async (ref, callback) => {
        let img = [];
        const dialog = ref.current.windowManager.open(dialogConfig(callback, []));
        dialog.block('Loading');
        // img = await getAllImages(id);
        dialog.unblock();
        dialog.close();
        ref.current.windowManager.open(dialogConfig(callback, img));
    };

    const dialogConfig = (callback, images) => ({
        title: 'Image Manager',
        body: {
            type: 'panel', // The root body type - a Panel or TabPanel
            items: images ? images.map((i, idx) => (
                {
                    type: 'htmlpanel', 
                    html: `<div class="gallery-div">
                            <label class="label-image">
                                <input style="display: none;" type="radio" name="image"  value="${i.path}" data-name="${i.name}"/>
                                <span><img class="image-media" src="${i.path}" width="40" height="40"/>${i.name}</span>
                            </label>
                        </div>`
                })) : []
        },
        buttons: [
          {
            type: 'cancel',
            name: 'closeButton',
            text: 'Cancel'
          },
          {
            type: 'submit',
            name: 'submitButton',
            text: 'Import',
            primary: true
          }
        ],
        onSubmit: function (api) {
          let selectedImage = document.querySelectorAll("input[name=image]:checked")[0]?.value;
          let selectedImageTitle = document.querySelectorAll("input[name=image]:checked")[0]?.getAttribute('data-name');
          if (selectedImage && selectedImageTitle) {
            callback(selectedImage, {title: selectedImageTitle});
          }
          api.close();
        }
    });
    

    return (<>
        <label className='label-description'>{label}</label><i className="bi bi-pencil-fill ms-2" style={{cursor: 'pointer'}} onClick={() => setEditState(!editState)}></i>
        <div className='mt-4 mb-5'>
            {editState ? <Editor tinymceScriptSrc={tinymcepath}
                apiKey='cmnnd5qnqu6rfr85uibg46q9tzjq6gg9qa9hzgx4f6wo45go'
                onBlur={log}
                initialValue={wysiwyg}
                init={{...initEditor, 
                    file_picker_callback: async function(callback, value, meta) {
                        if (editorRef.current) {
                            getImagesOnDialog(editorRef, callback);
                        }
                    }}}
                onInit={(evt, editor) => { editorRef.current = editor; editor.focus();}}
            /> : ReactHtmlParser(selectedItem[type] ? selectedItem[type] : '') }
        </div>
        
    </>);
}
