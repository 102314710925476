import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import apiClient from "../../services/api";

const initialState = {
    value: false,
    user: null,
    status: "idle",
};

export const signIn = createAsyncThunk(
    "login/signIn",
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiClient.post("/login", data);
            return { data: response.data, status: response.status };
        } catch (err) {
            if (!err.response) {
                throw err;
            }

            return rejectWithValue(err.response.data);
        }
    }
);

export const getUser = createAsyncThunk(
    "login/getUser",
    async (any, {rejectWithValue}) => {
        try {
            const response = await apiClient.get("/api/user");
            return response.data;
        } catch (error) {
            rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setLoggedOut: (state) => {
            state.value = false;
            state.user = null;
            localStorage.clear();
            window.Intercom("shutdown");
            document.cookie =
                "XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie =
                "laravel_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getUser.fulfilled, (state, action) => {
            // Add user to the state array
            state.user = action.payload;
            state.value = true;
            if (action.payload) {
                Sentry.setUser({
                    id: action.payload?.id,
                    username: action.payload?.name,
                    email: action.payload?.email,
                    ip_address: "{{auto}}"
                });
        
                console.log("intercom update user");
                window.Intercom("update", {
                    name: action.payload?.name,
                    email: action.payload?.email,
                    user_id: action.payload?.id
                });
            }
        });
        builder.addCase(getUser.rejected, (state, action) => {
            // Add user to the state array 
            state.user = null;
            state.value = false;
            window.Intercom("shutdown");
        });
    },
});

export const { setLoggedOut } = loginSlice.actions;

export const isLoggedIn = (state) => state.login.value;
export const _getUser = (state) => state.login.user;

export default loginSlice.reducer;
