import { createPortal } from "react-dom";
import { DragOverlay } from "@dnd-kit/core";
import { dropAnimation } from "helpers/ui-elements/drag-and-drop/dragAndDrop";
import { Item } from "components/ui-elements/drag-and-drop/components/Item/Item";

const renderSortableItemDragOverlay = ({
  id,
  handle,
  wrapperStyle,
  getIndex,
  item,
  containerId,
  getItemStyles,
  children,
}) => {
  return (
    <Item
      value={id}
      handle={handle}
      style={getItemStyles({
        containerId,
        overIndex: -1,
        index: getIndex(id),
        value: id,
        isSorting: true,
        isDragging: true,
        isDragOverlay: true,
      })}
      item={item}
      wrapperStyle={wrapperStyle({ index: 0 })}
      dragOverlay
    >
      {children}
    </Item>
  );
};

const SortableDndItemOverlay = ({
  activeId,
  wrapperStyle,
  index,
  containerId,
  getItemStyles,
  getIndex,
  children,
}) => {
  return createPortal(
    <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
      {activeId
        ? renderSortableItemDragOverlay({
            id: activeId,
            handle: true,
            wrapperStyle,
            dragOverlay: false,
            index,
            getIndex,
            containerId,
            getItemStyles,
            overIndex: -1,
            children,
            item: activeId,
          })
        : null}
    </DragOverlay>,
    document.body,
  );
};

export default SortableDndItemOverlay;
