import React, { useEffect } from "react";
import { Loading } from "../loader/Loading";
import { getTimeInterval } from "helpers/help";
import { DoughnutChart } from "../../components/DoughnutChart";
import axios from "axios";
import { Link } from "react-router-dom";
import PreviewEmail from "../../components/PreviewEmail";

export function EventUserCampaign({ userCampaign }) {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    return () => {
      source.cancel("axios request canceled");
    };
  }, [userCampaign]); // eslint-disable-line react-hooks/exhaustive-deps

  const setChartData = (
    chartData,
    percentage,
    labels = ["Unopened", "Opened"],
  ) => {
    return {
      labels,
      datasets: [
        {
          data: chartData,
          backgroundColor: ["#0000001a", "#0d6efd"],
          text: percentage + "%",
        },
      ],
    };
  };

  const getHeaderDateAndTime = (date) => {
    const hour = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return (
      <>
        {`${weekday[date.getDay()]}`}
        <br />
        {`${hour}:${minutes}`}
      </>
    );
  };

  return (
    <>
      <div className="card p-3">
        {!userCampaign ? (
          <Loading fullScreen="true" />
        ) : (
          <>
            {userCampaign ? (
              <div className="d-flex flex-column overflow-hidden">
                <div className="d-flex flex-wrap flex-column">
                  <div className="wrapper-steps d-flex">
                    {userCampaign.steps?.map((s, idx) => (
                      <div
                        key={idx}
                        className={`flex-wrap item ${s.done ? "bg-green" : ""}`}
                      >
                        {`S${idx + 1}`}
                        <span>
                          {getHeaderDateAndTime(new Date(s.add_date))}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="wrapper-data flex-wrap d-flex">
                    <div className="wrapper-preview" title="Preview email">
                      <PreviewEmail
                        signature={userCampaign.signature}
                        emailBody={userCampaign.email_body}
                        emailSubject={userCampaign.email_subjects}
                        id={`${userCampaign.id}-${new Date(userCampaign.send_date).getTime()}`}
                        icon={<i className="bi bi-eye"></i>}
                      />
                    </div>
                    <div className="d-flex flex-column col wrapper-interval">
                      <div className="time-interval">
                        {getTimeInterval(new Date(userCampaign.send_date))}
                      </div>
                      <div className="d-flex justify-content-center flex-column wrapper-path">
                        <div className="pt-1 pe-2">
                          {userCampaign.path.split(">")[0]}
                          {userCampaign.path.substring(
                            userCampaign.path.indexOf(">") + 1,
                          ) && userCampaign.path.split(">").length > 1 ? (
                            <span className="color-grey">
                              {"> " +
                                userCampaign.path.substring(
                                  userCampaign.path.indexOf(">") + 1,
                                )}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="pb-1">
                          <Link to={`/campaigns/${userCampaign.id}`}>
                            <i>{userCampaign.name}</i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="email-count col-md-2 col">
                      <div>Emails count</div>
                      <div>
                        {userCampaign.mails_count}
                        <i className="ms-1 bi bi-send"></i>
                      </div>
                    </div>
                    {userCampaign.statistics ? (
                      <>
                        <div className="divider"></div>
                        <div className="break-row"></div>
                        <div className="col flex-row d-flex justify-content-center wrapper-donut">
                          <div className="d-flex flex-column align-items-center ms-2">
                            <div>Opened</div>
                            <DoughnutChart
                              data={setChartData(
                                [
                                  userCampaign.statistics.items_count -
                                    userCampaign.statistics.opened_count,
                                  userCampaign.statistics.opened_count,
                                ],
                                userCampaign.statistics.opened_percentage,
                              )}
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center ms-2">
                            <div>Bounced</div>
                            <DoughnutChart
                              data={setChartData(
                                [
                                  userCampaign.statistics.items_count -
                                    userCampaign.statistics.bounce_count,
                                  userCampaign.statistics.bounce_count,
                                ],
                                userCampaign.statistics.bounce_percentage,
                                ["Not Bounced", "Bounced"],
                              )}
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center ms-2">
                            <div>Unsubscribed</div>
                            <DoughnutChart
                              data={setChartData(
                                [
                                  userCampaign.statistics.items_count -
                                    userCampaign.statistics.unsubscribed_count,
                                  userCampaign.statistics.unsubscribed_count,
                                ],
                                userCampaign.statistics.unsubscribed_percentage,
                                ["Not Unsubscribed", "Unsubscribed"],
                              )}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
}
