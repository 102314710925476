import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../services/api";

export const fetchCmCampaignStatuses = createAsyncThunk(
    'cm-campaign-statuses/fetchCmCampaignStatuses',
    async ({source}) => {
        const response = await apiClient.get(`/api/campaigns-manager/statuses`, { cancelToken: source.token });
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const fetchCmCampaignStatusHistory = createAsyncThunk(
    'cm-campaign-statuses/fetchCmCampaignStatusHistory',
    async ({id, source}) => {
        const response = await apiClient.get(`/api/campaigns-manager/status-history/${id}`, { cancelToken: source.token });
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const storeCmCampaignStatusHistory = createAsyncThunk(
    'cm-campaign-statuses/storeCmCampaignStatusHistory',
    async ({data, source}) => {
        const response = await apiClient.post(`/api/campaigns-manager/status-history`, { cancelToken: source.token, ...data });
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const removeStatusHistory = createAsyncThunk(
    'removeStatusHistory',
    async ({source, id}) => {
      try {
        await apiClient.delete(`/api/campaigns-manager/status-history/${id}`, { cancelToken: source.token });
        return id;
      } catch (error) {
        if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
        }
      }
    }
  );

const initialState = {
    campaignStatuses: [],
    campaignStatusHistory: [],
    loading: false,
};

const campaignStatusesSlice = createSlice({
  name: "campaignStatuses",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
    .addCase(fetchCmCampaignStatuses.pending, (state, action) => {
        state.loading = true;
    })
    .addCase(fetchCmCampaignStatuses.fulfilled, (state, action) => {
        state.campaignStatuses = action.payload;
        state.loading = false;
    })
    .addCase(fetchCmCampaignStatuses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
    })
    .addCase(fetchCmCampaignStatusHistory.pending, (state, action) => {
        state.loading = true;
    })
    .addCase(fetchCmCampaignStatusHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignStatusHistory = action.payload;
    })
    .addCase(fetchCmCampaignStatusHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
    })
    .addCase(storeCmCampaignStatusHistory.pending, (state, action) => {
        state.loading = true;
    })
    .addCase(storeCmCampaignStatusHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignStatusHistory.push(action.payload);
    })
    .addCase(storeCmCampaignStatusHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
    })
    .addCase(removeStatusHistory.pending, (state, action) => {
        state.loading = true;
    })
    .addCase(removeStatusHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignStatusHistory = state.campaignStatusHistory.filter((item) => item.id !== action.payload);
    })
    .addCase(removeStatusHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
    })
  },
});
// export const {  } = campaignStatusesSlice.actions;
export const getCmCampaignStatuses = (state) => state.campaignStatuses.campaignStatuses;
export const getCmCampaignStatusHistory = (state) => state.campaignStatuses.campaignStatusHistory;
export const getLoading = (state) => state.campaignStatuses.loading;

export default campaignStatusesSlice.reducer;