import { SORTABLE_DND_ACTIONS } from "constants/store/SortableDndActions";

const SortableDndReducer = (state, action) => {
  switch (action.type) {
    case SORTABLE_DND_ACTIONS.RE_FETCH: {
      return {
        ...state,
        dataFetch: Math.random().toString(36).substring(7),
      };
    }

    case SORTABLE_DND_ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case SORTABLE_DND_ACTIONS.INITIALISE: {
      return {
        ...state,
        initialised: true,
        ...action.payload,
      };
    }

    case SORTABLE_DND_ACTIONS.SET_ITEMS: {
      return {
        ...state,
        items: action.payload,
      };
    }

    case SORTABLE_DND_ACTIONS.UPDATE_ITEM_PROP: {
      const { id, prop, value } = action.payload;
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              [prop]: value,
            };
          }
          return item;
        }),
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default SortableDndReducer;
