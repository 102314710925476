import apiClient from "../../services/api";

export async function fetchEvents(user_id, filter, source) {
  try {
    const response = await apiClient.get(
      `/api/logs/events/${user_id}/${filter}`,
      { cancelToken: source.token },
    );
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message);
    } else if (error?.message) {
      throw new Error(error?.message);
    }
  }
}

export async function fetchEventsUsers(source) {
  try {
    const response = await apiClient.get(`/api/logs/events-users`, {
      cancelToken: source.token,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message);
    } else if (error?.message) {
      throw new Error(error?.message);
    }
  }
}

export async function fetchUserCampaigns(source) {
  try {
    const response = await apiClient.get(`/api/logs/user-campaigns`, {
      cancelToken: source.token,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message);
    } else if (error?.message) {
      throw new Error(error?.message);
    }
  }
}

export async function fetchMailsInQueue(source) {
  try {
    const response = await apiClient.get(`/api/logs/mails-in-queue`, {
      cancelToken: source.token,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message);
    } else if (error?.message) {
      throw new Error(error?.message);
    }
  }
}

export async function fetchLatestCampaings(source) {
  try {
    const response = await apiClient.get(`/api/logs/latest-campaigns-send`, {
      cancelToken: source.token,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message);
    } else if (error?.message) {
      throw new Error(error?.message);
    }
  }
}

export async function fetchLatestPressReleases(source) {
  try {
    const response = await apiClient.get(`/api/logs/latest-press-releases`, {
      cancelToken: source.token,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message);
    } else if (error?.message) {
      throw new Error(error?.message);
    }
  }
}

export async function createUserLog(name, source) {
  try {
    const response = await apiClient.post(
      `/api/user-logs/create-user-log/${name}`,
      { cancelToken: source.token },
    );
    localStorage.setItem("first_login_current_day", "0");
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message);
    } else if (error?.message) {
      throw new Error(error?.message);
    }
  }
}
