import { useEffect, useRef, useState } from "react";
import {
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { coordinateGetter } from "helpers/campaign/workflow/multipleContainersKeyboardCoordinates";
import { multipleContainers } from "helpers/ui-elements/drag-and-drop/containers";
import { useCollisionDetectionStrategy } from "hooks/drag-and-drop/useCollisionDetectionStrategy";
import { useSortableDragAndDropMultiContainerActions } from "hooks/drag-and-drop/useSortableDragAndDropMultiContainerActions";
import { groupByColumnWithBase } from "helpers/ui-elements/drag-and-drop/dragAndDrop";
import { useSortableDndActions } from "hooks/drag-and-drop/useSortableDndActions";

export const useDragAndDrop = ({
  serverItems = null,
  serverContainers,
  beforeDragStart,
  dragEnd,
  dragOver,
  beforeDragEnd,
  beforeDragOver,
  onItemClick,
  activeId,
  setActiveId,
}) => {
  const { initialise } = useSortableDndActions();

  const [items, setItems] = useState(
    groupByColumnWithBase(
      serverItems,
      serverContainers.map((column) => column.id),
    ),
  );

  useEffect(() => {
    initialise({
      items: serverItems,
      itemsGrouped: items,
      containers: serverContainers,
    });
  }, [serverItems, serverContainers]);

  const { containers, getIndex, findContainer } = multipleContainers({
    arrayItems: serverItems,
    rawContainers: serverContainers,
    items,
  });

  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  const [clonedItems, setClonedItems] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  const { onDragEnd, onDragOver, onDragStart } =
    useSortableDragAndDropMultiContainerActions({
      activeId,
      clonedItems,
      items,
      setItems,
      findContainer,
      getIndex,
      recentlyMovedToNewContainer,
      setActiveId,
      setClonedItems,
      beforeDragStart,
      dragEnd,
      dragOver,
      beforeDragEnd,
      beforeDragOver,
      onItemClick,
    });

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const collisionDetectionStrategy = useCollisionDetectionStrategy({
    activeId,
    items,
    lastOverId,
    recentlyMovedToNewContainer,
  });

  const measuring = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  return {
    collisionDetectionStrategy,
    isSortingContainer,
    sensors,
    measuring,
    onDragEnd,
    onDragOver,
    onDragStart,
    getIndex,
    findContainer,
    containers,
    items,
  };
};
