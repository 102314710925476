import { SORTABLE_DND_ACTIONS } from "constants/store/SortableDndActions";
import { useSortableDndContext } from "hooks/context/useSortableDndContext";

export const useSortableDndActions = () => {
  const { items, containers, dispatch, initialised } = useSortableDndContext();
  const initialise = ({ items = [], containers = [], itemsGrouped }) => {
    dispatch({
      type: SORTABLE_DND_ACTIONS.INITIALISE,
      payload: {
        items,
        itemsGrouped,
        containers,
        containerIds: containers.map((c) => c.id),
      },
    });
  };

  const getItemById = (id) => {
    return items?.find((item) => item?.id === id);
  };
  const getContainerByItemId = (id, getter = "column") => {
    return items?.find((item) => item?.id === id)[getter];
  };
  const getContainerById = (id) => {
    return containers?.find((item) => item?.id === id);
  };

  const changeItemContainer = (
    itemId,
    containerId,
    position,
    getter = "column",
  ) => {
    const item = getItemById(itemId);
    const container = getContainerById(containerId);
    const oldContainer = getContainerByItemId(itemId);
    const newItems = items.filter((i) => i.id !== itemId);
    const newContainerItems = container.items;
    const oldContainerItems = oldContainer.items;
    const newItem = { ...item, [container[getter]]: container.id };
    newItems.splice(position, 0, newItem);
    dispatch({
      type: SORTABLE_DND_ACTIONS.CHANGE_POSITION,
      payload: {
        items: newItems,
        containers: containers.map((c) => {
          if (c.id === containerId) {
            return { ...c, items: newContainerItems };
          }
          if (c.id === oldContainer.id) {
            return { ...c, items: oldContainerItems };
          }
          return c;
        }),
      },
    });
  };

  const changeItemPosition = (itemId, position) => {
    const item = getItemById(itemId);
    const newItems = items.filter((i) => i.id !== itemId);
    newItems.splice(position, 0, item);
    dispatch({
      type: SORTABLE_DND_ACTIONS.CHANGE_POSITION,
      payload: {
        items: newItems,
      },
    });
  };

  return {
    initialise,
    getItemById,
    getContainerByItemId,
    getContainerById,
    changeItemPosition,
    changeItemContainer,
    initialised,
  };
};
