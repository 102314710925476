import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MailsInQueue } from '../../components/MailsInQueue';
import Nav from '../../components/Nav';
import { checkActiveModule, checkPermission } from 'helpers/help';
import Events from './Events';
import LatestActivity from './LatestActivity';
import { TrainingItems } from './TrainingItems';
import SuccessfulCampaigns from './SuccessfulCampaigns';
import AssignedCampaigns from './AssignedCampaigns';
import TeamSuccessfulCampaigns from '../../components/TeamSuccessfulCampaigns';
import { _getUser } from '../auth/loginSlice';
import TopOpenCampaigns from './TopOpenCampaigns';

const Home = () => {
  const user = useSelector(_getUser);
  const dispatch = useDispatch();
  // const [viewMode, setViewMode] = useState(sessionStorage.getItem('viewModeHome') ? sessionStorage.getItem('viewModeHome') : 'Dashboard');
  const [viewMode, setViewMode] = useState("Dashboard");
  const [showLastMonth, setShowLastMonth] = useState(true);
  const [showThreeMonths, setShowThreeMonths] = useState(true);
  const [hideSwitch, setHideSwitch] = useState({
    ownCampaigns: false,
    allCampaigns: false,
  });

  useEffect(() => {
    document.title = "Home - JournoResearch";
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const setView = (viewMode) => {
    sessionStorage.setItem("viewModeHome", viewMode);
    setViewMode(viewMode);
  };

  return (
    <>
      <Nav selectedListItem={{ name: viewMode }} spaceBetween={false}>
        <MailsInQueue />
        <li className="nav-item pe-2 ms-auto"></li>
        {(!hideSwitch.ownCampaigns && viewMode !== "Successful Campaigns") ||
        (!hideSwitch.allCampaigns && viewMode === "Successful Campaigns") ? (
          <>
            <div className="form-check form-switch me-1 mt-1 mb-1">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="last_month"
                checked={!showLastMonth}
                onChange={() => setShowLastMonth(!showLastMonth)}
              />
              <label className="form-check-label" htmlFor="last_month" readOnly>
                Show all
              </label>
            </div>
            {!showLastMonth ? (
              <div className="form-switch me-2 mt-1 mb-1 form-three-month">
                <label className="form-check-label me-1 ms-1" readOnly>
                  6M
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="three_month"
                  checked={showThreeMonths}
                  onChange={() => setShowThreeMonths(!showThreeMonths)}
                />
                <label
                  className="form-check-label  me-1 ms-1"
                  htmlFor="three_month"
                  readOnly
                >
                  3M
                </label>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <button
          className={`btn me-2 mt-1 mb-1 ${viewMode === "Dashboard" ? "btn-primary" : "btn-secondary"}`}
          onClick={() => setView("Dashboard")}
        >
          Dashboard
        </button>
        <button
          className={`btn me-2 mt-1 mb-1 ${viewMode === "Table" ? "btn-primary" : "btn-secondary"}`}
          onClick={() => setView("Table")}
        >
          Table
        </button>
        {checkPermission("role_pr", "fe-successful-campaigns") ? (
          <button
            className={`btn me-2 mt-1 mb-1 ${viewMode === "Successful Campaigns" ? "btn-primary" : "btn-secondary"}`}
            onClick={() => setView("Successful Campaigns")}
          >
            Successful Campaigns
          </button>
        ) : (
          <></>
        )}
        <button
          className={`btn me-2 mt-1 mb-1 ${viewMode === "Latest Activity" ? "btn-primary" : "btn-secondary"}`}
          onClick={() => setView("Latest Activity")}
        >
          Latest Activity
        </button>
        {checkPermission("role_pr", "fe-successful-campaigns") ? (
          <button
            className={`btn me-2 mt-1 mb-1 ${viewMode === "Top open campaigns" ? "btn-primary" : "btn-secondary"}`}
            onClick={() => setView("Top open campaigns")}
          >
            Top open campaigns
          </button>
        ) : (
          <></>
        )}
      </Nav>
      {viewMode === "Dashboard" ? (
        <SuccessfulCampaigns
          showOwnCampaigns={true}
          showLastMonth={showLastMonth}
          setHideSwitch={setHideSwitch}
          hideSwitch={hideSwitch}
          showThreeMonths={showThreeMonths}
        />
      ) : (
        <></>
      )}
      {viewMode === "Dashboard" ? <AssignedCampaigns /> : <></>}
      {checkActiveModule(user, "training-items") && viewMode === "Dashboard" ? (
        <TrainingItems />
      ) : (
        ""
      )}
      {viewMode === "Table" ? <LatestActivity /> : <></>}
      {viewMode === "Successful Campaigns" &&
      checkPermission("role_pr", "fe-successful-campaigns") ? (
        <>
          <SuccessfulCampaigns
            showLastMonth={showLastMonth}
            setHideSwitch={setHideSwitch}
            hideSwitch={hideSwitch}
            showThreeMonths={showThreeMonths}
          />
          <TeamSuccessfulCampaigns />
        </>
      ) : (
        <></>
      )}
      {viewMode === "Latest Activity" ? <Events /> : <></>}
      {checkPermission("role_pr", "fe-successful-campaigns") &&
      viewMode === "Top open campaigns" ? (
        <TopOpenCampaigns />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(Home);
