import { prepareNewStoreData } from "services/campaign/workflow/dragArrayActions";
import { SORTABLE_DND_ACTIONS } from "constants/store/SortableDndActions";
import apiClient from "services/api";
import { UPDATE_CAMPAIGN_WORKFLOW_EXECUTIVES } from "constants/api/campaign/apiCampaignEndPoints";

/**
 * Drag start event is called when the user ends dragging the element on same and new container
 * @param dispatch
 * @param storeItems
 * @param params
 */
export const dragEnd =
  ({ dispatch, storeItems, getContainerByItemId }) =>
  async (params) => {
    const { revertItems, newItems, overContainer, activeId, activeIndex } =
      params;

    const newIndex = newItems[overContainer].findIndex(
      (item) => item === activeId,
    );
    const activeContainer = getContainerByItemId(activeId);

    if (!guard(activeContainer, overContainer)) return;

    if (
      (activeContainer === overContainer && newIndex === activeIndex) ||
      newIndex === -1
    )
      return;

    dispatch({
      type: SORTABLE_DND_ACTIONS.UPDATE_ITEM_PROP,
      payload: {
        id: activeId,
        prop: "disabled",
        value: true,
      },
    });

    const preparedItems = prepareNewStoreData(newItems, storeItems);
    apiClient
      .put(UPDATE_CAMPAIGN_WORKFLOW_EXECUTIVES, {
        id: activeId,
        index: newIndex,
        column: overContainer,
        fromColumn: newItems[getContainerByItemId(activeId)],
        toColumn: newItems[overContainer],
      })
      .then(() => {
        dispatch({
          type: SORTABLE_DND_ACTIONS.SET_ITEMS,
          payload: preparedItems,
        });
      })
      .catch(() => {
        revertItems && revertItems();
      })
      .finally(() => {
        dispatch({
          type: SORTABLE_DND_ACTIONS.UPDATE_ITEM_PROP,
          payload: {
            id: params.activeId,
            prop: "disabled",
            value: false,
          },
        });
      });
  };

const updateItem = (dispatch, id, prop, value) => {};

/**
 * Drag over event is called when the user is dragging the element over the target in new container
 * @param params
 * @param dispatch
 * @returns {(function(*): Promise<void>)|*}
 */
export const dragOver =
  ({ dispatch }) =>
  async (params) => {
    console.log("onDragOver called");
    console.log("params", params);
  };

/**
 * Before drag end event is called when the user is about to finish dragging the element same container
 * @param params
 * @param dispatch
 * @returns {function(*): Promise<boolean>}
 */
export const beforeDragEnd =
  ({ getContainerByItemId, dispatch, getContainerById }) =>
  (params) => {
    const { activeId, overContainer } = params;
    const activeContainer = getContainerByItemId(activeId);
    return guard(activeContainer, overContainer);
  };

/**
 *  Before drag over event is called when the user is dragging the element over the target in new container
 * @param getContainerByItemId
 * @param dispatch
 * @param params
 */
export const beforeDragOver =
  ({ getContainerByItemId }) =>
  (params) => {
    const { activeId, overContainer } = params;
    const activeContainer = getContainerByItemId(activeId);
    return guard(activeContainer, overContainer);
  };

const guard = (activeContainer, overContainer) => {
  return true;
  switch (activeContainer) {
    case "myCampaigns": {
      return (
        overContainer === "myCampaigns" || overContainer === "ideationStage"
      );
    }
    case "ideationStage": {
      return (
        overContainer === "ideationStage" || overContainer === "ideaStatus"
      );
    }
    case "ideaStatus": {
      return (
        overContainer === "ideaStatus" ||
        overContainer === "dataStage" ||
        overContainer === "dataStatus"
      );
    }
    case "dataStage": {
      return overContainer === "dataStage" || overContainer === "dataStatus";
    }
    case "dataStatus": {
      return (
        overContainer === "dataStatus" || overContainer === "pressReleaseStage"
      );
    }
    case "pressReleaseStage": {
      return (
        overContainer === "pressReleaseStage" ||
        overContainer === "pressReleaseStatus"
      );
    }
    default: {
      return false;
    }
  }
};
