import React from "react";

export default function Modal({
  props,
  children,
  footer,
  clickOutside = true,
  canClose = true,
  scrollable = false,
  closeBtnRef = null,
  userAssigned = true,
  messsageAlert = false,
  loading = false,
}) {
  const onClickClose = () => {
    if (!canClose && closeBtnRef && !userAssigned) {
      if (window.confirm("No one person is assigned, do you want to leave?")) {
        closeBtnRef.current.click();
      }
    }
    if (!canClose && closeBtnRef && userAssigned) {
      if (window.confirm("You have changes, do you want to leave?")) {
        closeBtnRef.current.click();
      }
    }
    if (!canClose && messsageAlert) {
      window.alert(messsageAlert);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id={props.target}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-keyboard={clickOutside}
        data-bs-backdrop={clickOutside ? "true" : "static"}
      >
        <button
          type="button"
          className="d-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          ref={closeBtnRef}
        >
          Close
        </button>
        <div
          className={`modal-dialog modal-dialog-centered ${props.size ? `modal-${props.size}` : ""} ${scrollable ? "modal-dialog-scrollable" : ""}`}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {props.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss={canClose ? "modal" : ""}
                aria-label="Close"
                onClick={() => onClickClose()}
              ></button>
            </div>
            <div className="modal-body">{children}</div>
            <div
              className={`modal-footer ${props.disableFooter || loading ? "d-none" : ""}`}
            >
              {footer ? footer : <></>}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss={canClose ? "modal" : ""}
                onClick={() => onClickClose()}
              >
                Close
              </button>
              {props.nameSaveButton === "unset" ? (
                ""
              ) : (
                <button
                  type="button"
                  className={`btn ${props.danger ? "btn-danger" : "btn-primary"} ${!canClose ? "disabled" : ""}`}
                  data-bs-dismiss={
                    props.canSave === undefined || props.canSave ? "modal" : ""
                  }
                  onClick={() => props.add()}
                >
                  {props.nameSaveButton ? props.nameSaveButton : "Save changes"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
