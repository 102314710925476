import React, { useState, useEffect } from "react";
import Select from "react-select";
import { checkPermission, formatDateTime } from "helpers/help";
import { Tooltip } from "../../components/Tooltip";

export function CampaignSelect({ notionCampaigns, campaign, setCampaign }) {
  const [data, setData] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    if (campaign && campaign.notion_campaigns) {
      let attachedCampaigns = campaign.notion_campaigns.map((n) => ({
        name: n.campaign_name,
        id: n.notion_id,
      }));
      if (notionCampaigns) {
        setCampaigns(
          filterAttachedNotionCampaign(notionCampaigns, attachedCampaigns),
        );
        setData(attachedCampaigns);
      }
    }
  }, [notionCampaigns]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCampaign({
      ...campaign,
      notion_campaigns: data?.map((d) => ({
        notion_id: d.id,
        campaign_name: d.name,
      })),
    });
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const createNewEntry = () => {
    if (selectedCampaign) {
      const foundName = data?.find((e) => e.id === selectedCampaign.value);
      if (!foundName) {
        setData([
          { name: selectedCampaign.label, id: selectedCampaign.value },
          ...data,
        ]);
        setCampaigns(
          campaigns.filter((c) => c.value !== selectedCampaign.value),
        );
        setSelectedCampaign(null);
      }
    }
  };

  const deleteItem = (idx) => {
    setCampaigns([
      ...campaigns,
      { value: data[idx].id, label: data[idx].name },
    ]);
    let d = data.filter((v, index) => idx !== index);
    setData(d);
  };

  const filterAttachedNotionCampaign = (campaigns, attachedCampaigns) => {
    let results = [];
    campaigns.forEach((c) => {
      if (
        !attachedCampaigns ||
        attachedCampaigns.every((a) => a.id !== c.notion_id)
      ) {
        results.push({ value: c.notion_id, label: c.campaign_name });
      }
    });
    return results;
  };

  return (
    <>
      <div>{campaign?.site}</div>
      <div className="wrapper-invoice">
        <a
          href={campaign?.xero_link}
          className="unset-element"
          target="_blank"
          rel="noreferrer"
        >
          {campaign?.invoice}
        </a>
        <div className="unset-element">{campaign?.start_date_formatted}</div>
        <Tooltip text={campaign?.notes ? campaign.notes : ""}>
          <div className="unset-element ellipsis box-notes">
            {campaign?.notes}
          </div>
        </Tooltip>
      </div>
      <div className="card-remaining mb-2">{campaign?.remaining}</div>

      {campaign?.site_preference_form_url ? (
        <div className="input-group mb-3">
          <span className="input-group-text">Site prefs form</span>
          <a
            className="btn btn-outline-secondary"
            type="button"
            href={campaign.site_preference_form_url}
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-link"></i>
          </a>
        </div>
      ) : (
        ""
      )}

      {campaign?.client_prefs ? (
        <div className="mb-2">
          <div className="input-group mb-3">
            <span className="input-group-text">Client prefs</span>
            <a
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              href={campaign.client_prefs}
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-link"></i>
            </a>
          </div>
        </div>
      ) : (
        ""
      )}

      {campaign?.communication_officer ? (
        <div className="mb-2">
          <div className="mb-1">
            <span>Comm Officer: </span>
            {campaign.communication_officer.replace(";", ", ")}
          </div>
        </div>
      ) : (
        ""
      )}

      {campaign?.type_of_client || campaign?.contact_frequency ? (
        <div className="mb-2">
          <span>
            {campaign?.type_of_client}{" "}
            {campaign?.type_of_client && campaign?.contact_frequency
              ? " | "
              : ""}{" "}
            {campaign?.contact_frequency}
          </span>
        </div>
      ) : (
        ""
      )}

      {checkPermission("role_pr", "fe-access-campaign-manager") ? (
        <>
          <div className="form-check form-switch mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={campaign?.completed}
              onChange={() =>
                setCampaign({
                  ...campaign,
                  completed: !campaign?.completed,
                  completed_date:
                    !campaign?.completed && !campaign?.completed_date
                      ? formatDateTime(false, true)
                      : null,
                })
              }
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Mark as completed
            </label>
          </div>
          {campaign?.completed_date && campaign?.completed ? (
            <div className="form-input mb-3 completed-date">
              <label className="form" htmlFor="completed-date">
                COMPLETED DATE:
              </label>
              <input
                className="form-control"
                type="datetime-local"
                id="completed-date"
                value={formatDateTime(campaign?.completed_date, true)}
                onChange={(date) =>
                  setCampaign({
                    ...campaign,
                    completed_date: date.target.value,
                  })
                }
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <></>
      )}

      <div className="form-check form-switch mb-3">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          checked={campaign?.manual_override}
          onChange={() =>
            setCampaign({
              ...campaign,
              manual_override: !campaign?.manual_override,
            })
          }
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          Manual Override
        </label>
      </div>
      <div>
        HT LINKS:{" "}
        <input
          className="form-control"
          type="number"
          value={campaign?.ht_links}
          readOnly={!campaign?.manual_override}
          onChange={(e) =>
            setCampaign({ ...campaign, ht_links: e.target.value })
          }
        ></input>
      </div>
      <div>
        LINKS:{" "}
        <input
          className="form-control"
          type="number"
          value={campaign?.links}
          readOnly={!campaign?.manual_override}
          onChange={(e) => setCampaign({ ...campaign, links: e.target.value })}
        ></input>
      </div>
      <div className="mt-3">
        Min HT LINKS:{" "}
        <input
          className="form-control"
          type="number"
          value={campaign?.min_ht_links}
          onChange={(e) =>
            setCampaign({ ...campaign, min_ht_links: e.target.value })
          }
        ></input>
      </div>
      <div>
        Min LINKS:{" "}
        <input
          className="form-control"
          type="number"
          value={campaign?.min_links}
          onChange={(e) =>
            setCampaign({ ...campaign, min_links: e.target.value })
          }
        ></input>
      </div>
      <div className="table-modal-work-flow mt-5">
        <label>Assign to campaign</label>
        {campaigns ? (
          <div className="d-flex align-items-center">
            <div style={{ flex: 1 }}>
              <Select
                options={campaigns}
                onChange={(e) => setSelectedCampaign(e)}
                value={selectedCampaign}
              />
            </div>
            <i
              className={`cursor-pointer bi bi-plus-lg text-primary ps-1 pe-1 ${selectedCampaign ? "" : "disabled"}`}
              onClick={() => createNewEntry()}
            />
          </div>
        ) : (
          ""
        )}
        <table className="table table-striped mt-2">
          <tbody style={{ fontSize: "0.8em" }}>
            {data?.map((data, index) => (
              <tr key={index}>
                <td>{data.name}</td>
                <td className="text-center">
                  <i
                    className="bi bi-dash text-danger ps-1 pe-1"
                    onClick={() => deleteItem(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-3">
        <label htmlFor="notes">Notes</label>
        <input
          type="text"
          id="notes"
          className="form-control"
          value={campaign?.notes ? campaign.notes : ""}
          onChange={(e) => setCampaign({ ...campaign, notes: e.target.value })}
        />
      </div>
    </>
  );
}
