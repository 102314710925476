import { useSortable } from "@dnd-kit/sortable";
import { useMountStatus } from "hooks/drag-and-drop/useMountStatus";
import { Item } from "components/ui-elements/drag-and-drop/components/Item/Item";
import { useSortableDndActions } from "hooks/drag-and-drop/useSortableDndActions";

export function SortableDndItem({
  disabled,
  id,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  item,
  actions,
  children,
  wrapperStyle = () => ({}),
  onItemClick = () => {},
}) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  const { getItemById } = useSortableDndActions();

  const itm = getItemById(id);

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      value={id}
      onClick={onItemClick}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      item={item}
      disabled={itm.disabled}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      actions={actions(id)}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
    >
      {children}
    </Item>
  );
}

export default SortableDndItem;
