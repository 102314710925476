import { Tooltip } from "components/Tooltip";

export default function CampaignTimeline({ campaign }) {
  return (
    <>
      <div
        className="progressbar mb-5"
        style={{ height: "35px", margin: "1.5rem" }}
      >
        <div
          className="indicator"
          style={{ left: campaign.current_progress_percentage + "%" }}
        ></div>
        <div
          className="indicator-progress"
          style={{ width: campaign.current_progress_percentage + "%" }}
        >
          {/* <span className="total-links" title="Progress">{parseInt(campaign.current_progress_percentage) + '%;' }</span> */}
        </div>
        <div
          className="tick"
          style={{ left: campaign.current_progress_percentage + "%" }}
        >
          <span className="absolute text-slate-400 current-date">
            Today ({campaign?.current_date})
          </span>
          <span className="line"></span>
          <span className="tick-status">{campaign.status_campaign}</span>
        </div>
        <div
          className="ideation"
          style={{ width: campaign.ideation_percentage + "%" }}
        >
          <span className="absolute item-date">
            {campaign.short_start_date}
          </span>
          <span style={{ zIndex: 9 }}>Ideation</span>
          <span className="line"></span>
        </div>
        <Tooltip
          text={
            campaign.ideas_external_days
              ? "The Ideation stage, was with the client for " +
                campaign.ideas_external_days +
                " days"
              : ""
          }
        >
          <div
            className="extra"
            style={{
              width: campaign.ideas_external_days_percentage + "%",
              backgroundColor: "#FC8403",
            }}
          ></div>
        </Tooltip>
        <div
          className="execution"
          style={{
            width: campaign.execution_percentage + "%",
            left: campaign.ideation_percentage + "%",
          }}
        >
          <span className="absolute item-date">
            {campaign.execution_start_date}
          </span>
          <span style={{ zIndex: 9 }}>Execution</span>
          <span className="line"></span>
        </div>
        <Tooltip
          text={
            campaign.execution_external_days
              ? "The Execution stage, was with the client for " +
                campaign.execution_external_days +
                " days"
              : ""
          }
        >
          <div
            className="extra"
            style={{
              width: campaign.execution_external_days_percentage + "%",
              left: campaign.ideation_percentage + "%",
              backgroundColor: "#89CFF0",
            }}
          ></div>
        </Tooltip>
        <div
          className="pitching"
          style={{
            width: campaign.pitching_percentage + "%",
            left:
              campaign.ideation_percentage +
              campaign.execution_percentage +
              "%",
          }}
        >
          <span className="absolute item-date">
            {campaign.pitching_start_date}
          </span>
          <span style={{ zIndex: 9 }}>Pitching</span>
          <span className="line"></span>
        </div>
        <div
          className="links"
          style={{
            width: campaign.links_percentage + "%",
            left:
              campaign.ideation_percentage +
              campaign.execution_percentage +
              campaign.pitching_percentage +
              "%",
          }}
        >
          <span className="absolute item-date">
            {campaign.links_start_date}
          </span>
          <span className="absolute item-last-date">
            {campaign.short_end_date}
          </span>
          <span style={{ zIndex: 9 }}>Links</span>
          <span className="line"></span>
        </div>
      </div>
      {campaign?.over_six_days ? (
        <div>
          You must submit ideas for your {campaign?.client} - {campaign?.site}{" "}
          campaign by the end of today to a member of management, unless you
          have done so already.
        </div>
      ) : (
        ""
      )}
    </>
  );
}
