import { useSortableDndActions } from "hooks/drag-and-drop/useSortableDndActions";

export function RenderDyanimcWorkflowData({ itemID }) {
  const { getItemById } = useSortableDndActions();
  const item = getItemById(itemID);

  if (!item) return <></>;

  const { id, content, className } = item;
  return (
    <div className={className}>
      {content.map((row, rowIndex) => (
        <div className={"small"} key={`${id}_row_${rowIndex}`}>
          {row.map((item, itemIndex) => (
            <span
              key={`${id}_item_${itemIndex}`}
              className={item.className || ""}
            >
              {item.label}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
}
