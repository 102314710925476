import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Alert from "../../components/Alert";
import { Loading } from "../loader/Loading";
import "./dataWarehouse.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataWarehouse,
  getDataWarehouse,
  getLoading,
  getPerPage,
  getTotalRows,
  setPerPage,
  setLoading,
  fetchCategories,
  getParamsForURLQuery,
  setColumn,
  setSortDirection,
  getError,
  resetParams,
  fetchTypes,
} from "./dataWarehouseSlice";
import Nav from "../../components/Nav";
import AddWarehouse from "./AddWarehouse";
import FilterName from "./FilterName";
import FilterSource from "./FilterSource";
// import FilterDate from './FilterDate';
import FilterType from "./FilterType";
import FilterCategory from "./FilterCategory";
import Wrapper from "../../components/Wrapper";
import { checkPermission } from "helpers/help";

export default function DataWarehouse() {
  const dispatch = useDispatch();
  const error = useSelector(getError);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const dataWarehouse = useSelector(getDataWarehouse);
  const perPage = useSelector(getPerPage);
  const totalRows = useSelector(getTotalRows);
  const loading = useSelector(getLoading);
  const params = useSelector(getParamsForURLQuery);
  const [resetModal, setResetModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDataWarehouse, setSelectedDataWarehouse] = useState(null);
  const [onOpeningModal, setOnOpeningModal] = useState(0);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(resetParams());
      source.cancel("axios request canceled");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      key: "name",
      cell: (row) =>
        row.id === 0 ? (
          <FilterName />
        ) : (
          <div
            className="wrapper-row-name"
            data-bs-toggle="modal"
            data-bs-target="#new_data_warehouse"
            onClick={() => {
              setIsEditing(true);
              setSelectedDataWarehouse(row);
              setOnOpeningModal(onOpeningModal + 1);
            }}
          >
            <div className="row-name">{row.name}</div>
          </div>
        ),
      width: "400px",
    },
    {
      name: "Type",
      cell: (row) =>
        row.id === 0 ? (
          <FilterType />
        ) : (
          <>
            {row.types.map((c, idx) => (
              <Wrapper key={idx}>
                <span className="tag-categories">{c.name}</span>
                {idx !== row.types.length - 1 ? ", " : ""}
              </Wrapper>
            ))}
          </>
        ),
      style: {
        width: "150px",
        minWidth: "150px",
      },
    },
    {
      name: "Source",
      selector: (row) => row.source,
      sortable: true,
      key: "source",
      cell: (row) =>
        row.id === 0 ? (
          <FilterSource />
        ) : (
          <div className="wrapper-sources">
            {row.source.map((s, index) => (
              <div key={index}>
                <a href={s.name} target="_blank" rel="noreferrer">
                  {s.name}
                </a>
              </div>
            ))}
          </div>
        ),
      width: "500px",
    },
    // {
    // 	name: 'Last Release',
    // 	selector: row => row.last_release_date,
    // 	sortable: true,
    // 	key: 'last_release_date',
    // 	cell: row => row.id === 0 ? (<FilterDate />) : row.last_release_date,
    // 	width: '200px'

    // },
    // {
    // 	name: 'Explanation',
    // 	selector: row => row.explanation,
    // 	sortable: true,
    // 	key: 'explanation',
    // 	cell: row => row.id === 0 ? (<FilterExplanation />) : row.explanation,
    // 	width: '400px'

    // },
    {
      name: "Categories",
      cell: (row) =>
        row.id === 0 ? (
          <FilterCategory />
        ) : (
          <>
            {row.categories.map((c, idx) => (
              <Wrapper key={idx}>
                <span className="tag-categories">{c.name}</span>
                {idx !== row.categories.length - 1 ? ", " : ""}
              </Wrapper>
            ))}
          </>
        ),
      style: {
        width: "100%",
        minWidth: "200px",
      },
    },
  ];

  const loadDataWarehouse = ({
    page,
    perPage,
    sortDirection = null,
    column = null,
    searchName = null,
  }) => {
    dispatch(
      fetchDataWarehouse({
        perPage,
        page,
        source,
        sortDirection,
        column,
        searchName,
      }),
    );
  };

  const loadCategories = () => {
    dispatch(fetchCategories({ source }));
  };

  const loadTypes = () => {
    dispatch(fetchTypes({ source }));
  };

  const handlePageChange = (page) => {
    dispatch(
      fetchDataWarehouse({
        ...params,
        page: page,
        source,
      }),
    );
  };

  const handlePerRowsChange = (newPerPage, page) => {
    dispatch(
      fetchDataWarehouse({
        ...params,
        perPage: newPerPage,
        page: page,
        source,
      }),
    );
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    dispatch(
      fetchDataWarehouse({
        ...params,
        page: 1,
        sortDirection: sortDirection,
        column: column.key,
        source,
      }),
    );
    dispatch(setColumn(column.key));
    dispatch(setSortDirection(sortDirection));
  };

  const loadData = async () => {
    try {
      loadDataWarehouse({ page: 1, perPage });
      loadCategories();
      loadTypes();
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setLoading(false));
      }
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        border: "2px solid black",
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
        // userSelect: "none",
        // pointerEvents: "none"
      },
    },
  ];

  return (
    <>
      {error ? <Alert error={error}></Alert> : ""}
      <AddWarehouse
        resetModal={resetModal}
        isEditing={isEditing}
        selectedDataWarehouse={selectedDataWarehouse}
        onOpeningModal={onOpeningModal}
      />
      <Nav selectedListItem={{ name: "Data Warehouse" }} spaceBetween={false}>
        {!loading && checkPermission("role_pr", "fe-edit-data-warehouse") ? (
          <button
            className="btn btn-primary ms-auto"
            data-bs-toggle="modal"
            data-bs-target="#new_data_warehouse"
            onClick={() => {
              setResetModal(!resetModal);
              setIsEditing(false);
              setSelectedDataWarehouse(null);
            }}
          >
            Add new data source
          </button>
        ) : (
          ""
        )}
      </Nav>
      <DataTable
        className="data-table-ideas"
        title=""
        columns={columns}
        data={dataWarehouse}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={params.perPage}
        paginationRowsPerPageOptions={[10, 30, 50, 100, 250]}
        // expandableRows
        // expandableRowsComponent={ExpandedComponent}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        // onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
        persistTableHead
        progressComponent={<Loading fullScreen="true" />}
        defaultSortFieldId={3}
        defaultSortAsc={false}
      />
    </>
  );
}
