import GenericModal from "components/ui-elements/modal/GenericModal";
import { CAMPAIGN_MODAL } from "constants/campaign/modals";
import { useGenericModal } from "hooks/modal/useGenericModal";
import { useEffect } from "react";

const CampaignModal = () => {
  const { setProps } = useGenericModal({
    id: CAMPAIGN_MODAL,
  });

  useEffect(() => {
    setProps({
      loading: true,
    });
    setTimeout(() => {
      setProps({
        loading: false,
      });
    }, 5000);
  }, []);

  return (
    <GenericModal loadingMinHeight={500} id={CAMPAIGN_MODAL}></GenericModal>
  );
};

export default CampaignModal;
