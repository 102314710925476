
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getError, resetError } from './errorHandlingSlice';

export default function Alert() {

    const dispatch = useDispatch();
    const error = useSelector(getError);

    useEffect(() => {
        if (error === 'axios request canceled') {
            return;
        }
        if (error === 'Network Error') {
            return;
        }
        if (error?.length > 0) {
            document.querySelector('.toast').classList.add('show');
            document.querySelector('.toast-message').innerHTML = error;
            setTimeout(() => {
                document.querySelector('.toast').classList.remove('show');
                dispatch(resetError());
            }, 8000);
        }
        return () => {
            document.querySelector('.toast').classList.remove('show');
            document.querySelector('.toast-message').innerHTML = '';
        }
    }, [error, dispatch]);

    return (
        <div className="toast-container position-absolute p-3 bottom-0 start-50 translate-middle-x" id="toastPlacement">
            <div className="text-white bg-danger toast fade align-items-center hide" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="d-flex">
                    <div className="toast-body">
                        Something went wrong: <span className="toast-message"></span>
                    </div>
                    <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => dispatch(resetError())}></button>
                </div>
            </div>
        </div>)
}
