import React, { memo, useEffect, useState } from 'react';
import { fetchEventsUsers, fetchUserCampaigns } from './eventsAPI';
import { Loading } from '../loader/Loading';
import './events.scss';
import { EventUser } from './EventUser';
import { EventUserCampaign } from './EventUserCampaign';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { _getUser } from '../auth/loginSlice';
import { setError } from '../error-handling/errorHandlingSlice';

const Events = () => {
  const dispatch = useDispatch();
  const [eventsUsers, setEventsUsers] = useState(null);
  const [userCampaigns, setUserCampaigns] = useState(null);
  const [filter, setFilter] = useState('all');
  const [isLoading, setLoading] = useState(false);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const user = useSelector(_getUser);

  useEffect(() => {
    document.title = 'Home - JournoResearch';
    parallelLoadData();
    return (() => {
      source.cancel('axios request canceled');
      setEventsUsers(null);
      setUserCampaigns(null);
      setFilter('all');
    });

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const parallelLoadData = async () => {
    try {
      await Promise.all([getEventsUsers(), getUserCampaigns()]);
      setLoading(false);
    } catch (error) {
      if (error?.message !== 'axios request canceled') {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }    
  }

  const getEventsUsers = async () => {
    try {
        setLoading(true);
        const users = await fetchEventsUsers(source);
        setEventsUsers(users);
    } catch (error) {
      if (error?.message !== 'axios request canceled') {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  }

  const getUserCampaigns = async () => {
    try {
      setLoading(true);
      const campaigns = await fetchUserCampaigns(source);
      setUserCampaigns(campaigns);
    } catch (error) {
      if (error?.message !== 'axios request canceled') {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  }

  return (
    <div className="row events">
      {isLoading ? <Loading fullScreen="true" /> : <>
        <div className="mb-2 col-md-6 col-xs-12">
            <div className="d-flex align-items-center flex-wrap">
              <h2>Latest Activity</h2>
              <div className="wrapper-btn-filter ms-2 flex-wrap">
                  <button className={`btn  ms-2 mb-1 ${filter === 'all' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setFilter('all')}>All</button>
                  <button className={`btn  ms-2 mb-1 ${filter === 'campaigns' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setFilter('campaigns')}>Campaigns</button>
                  <button className={`btn  ms-2 mb-1 ${filter === 'press_releases' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setFilter('press_releases')}>Press Releases</button>
              </div>
            </div>
            <div className="logs">
              <div className="wrapper-card mb-4">
                  { eventsUsers?.map((eventUser, idx)=>
                      <div key={idx} className="mt-4">
                      <EventUser event_user={eventUser} filter={filter} key={idx}/>
                      </div>
                  )}
              </div>
            </div>
        </div>
        <div className="mb-2 col-md-6 col-xs-12">
            <h2>Your Recent Sends</h2>
            <div className="logs recent-sents">
              <div className="wrapper-card mb-4">
                    { userCampaigns?.map((campaign, idx)=>
                        <div key={idx} className="mt-4">
                          {idx === 0 ? <div className="card-title">{user?.name}</div> : ''}
                          <EventUserCampaign userCampaign={campaign} key={idx}/>
                        </div>
                    )}
              </div>
            </div>
        </div>
      </>}
    </div>
  );
}

export default memo(Events);
