import { DndContext } from "@dnd-kit/core";
import { verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableDndItem from "components/ui-elements/drag-and-drop/SortableDndItem";
import { SortableDndContainer } from "components/ui-elements/drag-and-drop/SortableDndContainer";
import { useDragAndDrop } from "hooks/drag-and-drop/useDragAndDrop";
import { useSortableDndActions } from "hooks/drag-and-drop/useSortableDndActions";
import { useState } from "react";
import SortableDndItemOverlay from "components/ui-elements/drag-and-drop/SortableDndItemOverlay";
import renderCampaignWorkflowCard from "components/campaign/workflow/renderCampaignWorkflowCard";

export const SortableDndWrapper = ({
  loading = false,
  containerStyle,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  modifiers,
  serverItems,
  serverContainers,
  strategy = verticalListSortingStrategy,
  scrollable,
  dragEnd,
  dragOver,
  beforeDragEnd,
  beforeDragOver,
  onItemClick,
  renderItemCard,
  actions,
}) => {
  const [activeId, setActiveId] = useState(null);

  const {
    collisionDetectionStrategy,
    sensors,
    measuring,
    onDragEnd,
    onDragOver,
    onDragStart,
    getIndex,
    containers,
    items,
    findContainer,
  } = useDragAndDrop({
    serverContainers,
    loading,
    serverItems,
    dragEnd,
    dragOver,
    beforeDragEnd,
    beforeDragOver,
    activeId,
    setActiveId,
  });

  const { initialised } = useSortableDndActions();
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={measuring}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      modifiers={modifiers}
    >
      <div
        style={{
          display: "inline-grid",
          boxSizing: "border-box",
          padding: 20,
          gridAutoFlow: "column",
        }}
      >
        {initialised &&
          containers.map((containerId) => (
            <SortableDndContainer
              key={containerId}
              id={containerId}
              columns={serverContainers.length}
              items={items[containerId]}
              scrollable={scrollable}
              style={containerStyle}
              strategy={strategy}
            >
              {items[containerId].map((value, index) => {
                return (
                  <SortableDndItem
                    id={value}
                    key={value}
                    index={index}
                    onItemClick={onItemClick}
                    style={getItemStyles}
                    wrapperStyle={wrapperStyle}
                    containerId={containerId}
                    getIndex={getIndex}
                    actions={actions}
                  >
                    {renderItemCard(value)}
                  </SortableDndItem>
                );
              })}
            </SortableDndContainer>
          ))}
      </div>

      <SortableDndItemOverlay
        getItemStyles={getItemStyles}
        wrapperStyle={wrapperStyle}
        containerId={findContainer(activeId)}
        getIndex={getIndex}
        index={getIndex(activeId)}
        activeId={activeId}
      >
        {renderCampaignWorkflowCard(activeId)}
      </SortableDndItemOverlay>
    </DndContext>
  );
};
