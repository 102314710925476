import React, { useEffect, useState } from "react";
import axios from "axios";
import apiClient from "../../services/api";
import { Loading } from "../loader/Loading";
import "./assigned-campaigns.scss";
import { formatDateMedium } from "helpers/help";
import { Tooltip } from "../../components/Tooltip";
import CardLegendStatuses from "../../components/CardLegendStatuses";
import { setError } from "../error-handling/errorHandlingSlice";
import { useDispatch } from "react-redux";

export function AssignedCampaignsList({ getCampaign }) {
  const dispatch = useDispatch();
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  // console.log("getCamp", getCampaign);

  const fetchUserAssignedCampaigns = async function (source) {
    try {
      const response = await apiClient.get(
        `/api/campaigns-manager/user-assigned-campaigns`,
        {
          cancelToken: source.token,
        },
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  };

  const getUserAssignedCampaigns = async () => {
    try {
      setLoading(true);
      const obj = await fetchUserAssignedCampaigns(source);
      setData(obj);
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserAssignedCampaigns();
    return () => {
      source.cancel("axios request canceled");
      setData(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (
      <div className="use-assigned-campaigns">
        <Loading fullScreen="true" />
      </div>
    );
  }

  if (!data) {
    return <></>;
  }

  return (
    <>
      {data?.map((camp, idx) => (
        <div
          className={`card card-users ${camp.has_links && !camp.min_links_achieved && !camp.completed ? "has-links" : ""} 
          ${camp.min_links_achieved && !camp.completed ? "min-links-achieved" : ""} 
          ${camp.completed ? "card-completed" : ""}`}
          key={idx}
        >
          <div className="d-flex card-action-btn">
            {camp.status_history ? (
              <Tooltip text={camp.status_history.name}>
                <div
                  className="status-dot"
                  style={{ backgroundColor: camp.status_history.color }}
                ></div>
              </Tooltip>
            ) : (
              <></>
            )}
            {camp.submitted_date ? (
              <i
                className="bi bi-bookmark"
                title={`Submitted Date: ${formatDateMedium(camp.submitted_date)}`}
              ></i>
            ) : (
              ""
            )}
            <i
              style={{ display: "block", marginLeft: "auto" }}
              className="bi bi-pencil text-primary cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#edit_card"
              onClick={() => getCampaign(camp.id)}
            ></i>
          </div>
          <div className="ellipsis">{camp.site}</div>
          <div className="wrapper-invoice">
            <div className="unset-element flex">
              <a
                className="unset-element me-1"
                target="_blank"
                rel="noreferrer"
                href={camp.xero_link}
              >
                {camp.invoice}
              </a>
              <i>{camp.start_date_formatted}</i>
            </div>
            <Tooltip text={camp.notes ? camp.notes : ""}>
              <div className="unset-element ellipsis box-notes">
                {camp.notes}
              </div>
            </Tooltip>
          </div>
          <div
            className={`d-flex mt-auto flex-wrap justify-content-between ${
              !camp.assigned_user ? "align-self-end" : ""
            }`}
          >
            <div className="card-remaining">{camp.remaining}</div>
          </div>
        </div>
      ))}
      {data && data.length > 0 ? <CardLegendStatuses /> : ""}
    </>
  );
}
