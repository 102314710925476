import { MODAL_ACTIONS } from "constants/store/ModalActions";

const ModalReducer = (state, action) => {
  switch (action.type) {
    case MODAL_ACTIONS.REGISTER: {
      if (state.modals.find((modal) => modal.id === action.payload.id))
        return state;
      return {
        ...state,
        modals: [
          ...state.modals,
          {
            id: action.payload.id,
            open: false,
            data: action.payload?.data || {},
            props: action.payload?.props || {},
          },
        ],
      };
    }

    case MODAL_ACTIONS.UNREGISTER: {
      return {
        ...state,
        modals: state.modals.filter((modal) => modal.id !== action.payload),
      };
    }

    case MODAL_ACTIONS.OPEN: {
      return {
        ...state,
        modals: state.modals.map((modal) => {
          if (modal.id === action.payload.id) return { ...modal, open: true };
          return modal;
        }),
      };
    }

    case MODAL_ACTIONS.CLOSE: {
      return {
        ...state,
        modals: state.modals.map((modal) => {
          if (modal.id === action.payload.id) return { ...modal, open: false };
          return modal;
        }),
      };
    }

    case MODAL_ACTIONS.CLOSE_ALL: {
      return {
        ...state,
        modals: state.modals.map((modal) => ({ ...modal, open: false })),
      };
    }

    case MODAL_ACTIONS.SET_DATA: {
      return {
        ...state,
        modals: state.modals.map((modal) => {
          if (modal.id === action.payload.id)
            return {
              ...modal,
              data: {
                ...modal.data,
                ...action.payload.data,
              },
            };
          return modal;
        }),
      };
    }

    case MODAL_ACTIONS.SET_PROPS: {
      return {
        ...state,
        modals: state.modals.map((modal) => {
          if (modal.id === action.payload.id)
            return {
              ...modal,
              props: {
                ...modal.props,
                ...action.payload.props,
              },
            };
          return modal;
        }),
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default ModalReducer;
