import React from "react";
import { Bar } from "react-chartjs-2";

const CampaignDiffBarChart = ({ data }) => {
  const formatDate = (date) => {
    const dateFormat = new Date(date);
    return new Intl.DateTimeFormat("en-GB", {
      dateStyle: "medium",
    }).format(dateFormat);
  };

  const setBarChartData = (chartData, label) => {
    return {
      label: label,
      backgroundColor: chartData.map((item) =>
        item.y < 0 ? "rgba(75, 192, 192, 0.2)" : "rgba(255, 99, 132, 0.2)"
      ),
      data: chartData?.map((s) => ({ x: formatDate(s.x), y: s.y })),
    };
  };
  const options = {
    responsive: true,
    scales: {
      xAxes: [
        {
          type: "time", // Using time scale for x-axis
          time: {
            unit: "month", // Format x-axis per month
            displayFormats: {
              month: "MMM YYYY", // Format for displaying per month
            },
            tooltipFormat: "MMM YYYY", // Format for tooltip
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
    <div
      className="wrapper-sends-stats"
      style={{
        width: "1000px",
        display: "flex",
        justifyContent: "center",
        flex: "1 1",
        marginBottom: "2rem",
      }}
    >
      <Bar
        type="bar"
        data={{
          datasets: [setBarChartData(data, "Campaigns backlog")],
        }}
        options={options}
        style={{ height: "170px" }}
        height={170}
      />
    </div>
  );
};

export default CampaignDiffBarChart;
