import axios from "axios";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const useCancelRequest = (deps = null) => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const { pathname } = useLocation();
  const prevMyStateValueRef = useRef(pathname);

  useEffect(() => {
    const prevMyStateValue = prevMyStateValueRef.current;

    if (prevMyStateValue !== pathname) {
      source.cancel("axios request canceled");
    }
    prevMyStateValueRef.current = pathname;
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    source,
  };
};
