import { useDispatch, useSelector } from "react-redux";
import { setError } from "../error-handling/errorHandlingSlice";
import { fetchItemById, menuItems } from "../lists/listNavigationSlice";
import { fetchPressReleasesById, fetchTemplates, getLatestRecord, getTemplates } from "./pressReleasesSlice";
import axios from "axios";
import { useEffect } from "react";

export const useFetchPR = async (id, setLatestRecord, setLoading) => {
    const dispatch = useDispatch();
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const templates = useSelector(getTemplates);
    const _menuItems = useSelector(menuItems);

    const getPressRelease = async (id) => {
        try {
            let pressReleaseSelected = _menuItems?.children.find(c => c.id === parseInt(id));
            let responseItemById = null;
            if (!pressReleaseSelected || pressReleaseSelected.is_folder) {
                responseItemById = await dispatch(fetchItemById({ id, typeOfList: 'press-releases', source })).unwrap();
                pressReleaseSelected = responseItemById?.children.find(c => c.id === parseInt(id)); 
            }
            if (pressReleaseSelected?.is_folder === 0) {
                setLoading(true);
                if (id && !templates) {
                    await dispatch(fetchTemplates({ source })).unwrap();
                }
                const record = await getLatestRecord({id, source});
                if (record && Object.keys(record).length > 0) {
                    setLatestRecord(record);
                }
                const res = await dispatch(fetchPressReleasesById({id , source})).unwrap();
                if (res) {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
            
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                dispatch(setError(error?.message));
            }
        }
    }

    useEffect(() => {
        getPressRelease(id ? id : 0);
        return () => {
            source.cancel('axios request canceled');
        }
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps
}