import { createContext, useReducer } from "react";
import ModalReducer from "store/reducer/ModalReducer";

const initialState = {
  dispatch: () => {},
  modals: [],
};

export const ModalContext = createContext({
  ...initialState,
});

export const ModalProvider = ({ children }) => {
  const [stateForm, dispatchStateForm] = useReducer(ModalReducer, initialState);

  return (
    <ModalContext.Provider
      value={{
        ...stateForm,
        dispatch: dispatchStateForm,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
export default ModalProvider;
