import { useState } from "react";
import ReactHtmlParser from 'html-react-parser';

export function PreviewPRSection({ contentPR, emailSubjects }) {
    const [previewDisplay, setPreviewDisplay] = useState('smartphone');

    return (
        <div className="preview ms-4 me-3">
            <i className={`bi bi-phone ${previewDisplay === 'smartphone' ? 'active' : ''}`} onClick={() => setPreviewDisplay('smartphone')}></i>
            <i className={`bi bi-tablet ${previewDisplay === 'tablet' ? 'active' : ''}`} onClick={() => setPreviewDisplay('tablet')}></i>
            <div className={previewDisplay}>
                <div className="content">
                    <p className="email-subjects">{emailSubjects?.length > 0 ? emailSubjects[0] : ''}</p>
                    <div className="email-body">
                        {ReactHtmlParser(contentPR?.email_body ? contentPR.email_body : '')}
                    </div>
                    <div className="email-body">
                        {ReactHtmlParser(contentPR?.signature ? contentPR.signature : '')}
                    </div>
                </div>
            </div>
        </div>
    );
}