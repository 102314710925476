import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "../loader/Loading";
import { fetchUsers } from "./campaignsManagerAPI";
import ReactPaginate from "react-paginate";
import { useNavigate, useLocation } from "react-router";
import Modal from "../../components/Modal";
import { Tooltip } from "../../components/Tooltip";
import { formatDateMedium } from "helpers/help";
import { useDispatch } from "react-redux";
import { setError } from "../error-handling/errorHandlingSlice";

export function UsersSectionManager({
  dragLeave,
  dragStart,
  drop,
  dragEnter,
  card,
  removeAssignedUser,
  selectedCardId,
}) {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [filterStatus, setFilterStatus] = useState("uncompleted");
  const per_page = 120;
  let navigate = useNavigate();
  let location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  useEffect(() => {
    document.title = "Campaigns Manager JournoResearch";
    return () => {
      source.cancel("axios request canceled");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let page = urlSearchParams.get("rightPage");
    let searchTerm = urlSearchParams.get("rightSearchTerm");
    let filterStatusParams = urlSearchParams.get("rightStatus");
    let showHidden = urlSearchParams.get("showHidden");
    setSearchTerm(searchTerm);
    if (filterStatusParams) {
      setFilterStatus(filterStatusParams);
    }
    getUsers(
      page ? page - 1 : 0,
      searchTerm,
      filterStatusParams ? filterStatusParams : filterStatus,
      showHidden,
    );
  }, [
    urlSearchParams.get("rightPage"),
    urlSearchParams.get("rightSearchTerm"),
    urlSearchParams.get("rightStatus"),
    urlSearchParams.get("showHidden"),
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const _users = [...users];
    const user = _users.find((u) => u.user_id === card?.to?.assigned_user);
    if (
      card &&
      card.from &&
      card.to &&
      card.from.id &&
      card.from.assigned_user
    ) {
      let indexOfUser = _users.findIndex(
        (u) => u.user_id === card.from.assigned_user,
      );
      let indexOfCampaign = _users[indexOfUser].campaigns.findIndex(
        (c) => c.id === card.from.id,
      );
      if (indexOfUser > -1 && indexOfCampaign > -1) {
        _users[indexOfUser].campaigns.splice(indexOfCampaign, 1);
      }
    }
    if (user) {
      user.campaigns.push(card.to);
    }
    setUsers(_users);
  }, [card]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUsers = async (
    pageOffset = 0,
    searchTerm = "",
    filterStatus = null,
    showHidden = 0,
  ) => {
    try {
      setLoading(true);
      const response = await fetchUsers(
        source,
        per_page,
        pageOffset + 1,
        searchTerm,
        filterStatus,
        showHidden,
      );
      if (response) {
        setPageOffset(parseInt(pageOffset));
        setPageCount(Math.ceil(response.total / per_page));
        setUsers(response.data);
        setLoading(false);
      }
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
    urlSearchParams.delete("rightPage");
    urlSearchParams.append("rightPage", event.selected + 1);
    navigate(`./?${urlSearchParams}`);
  };

  const resetPage = () => {
    urlSearchParams.delete("rightPage");
    urlSearchParams.append("rightPage", 1);
  };

  const search = async () => {
    resetPage();
    urlSearchParams.delete("rightSearchTerm");
    urlSearchParams.append("rightSearchTerm", searchTerm);
    navigate(`./?${urlSearchParams}`);
  };

  const unassignedUserModal = {
    title: "Delete assigned user",
    target: "delete_assigned_user",
    nameSaveButton: "DELETE",
    danger: true,
    add: () => {
      removeAssignedUser(selectedCard.id, selectedCard.updated_at);
    },
  };

  const filterByStatus = (status) => {
    setFilterStatus(status);
    resetPage();
    urlSearchParams.delete("rightStatus");
    urlSearchParams.append("rightStatus", status);
    navigate(`./?${urlSearchParams}`);
  };

  const onClickEditCard = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    urlSearchParams.delete("statuses");
    urlSearchParams.delete("id");
    urlSearchParams.append("id", id);
    navigate(`./?${urlSearchParams}`);
  };

  return (
    <>
      <Modal props={unassignedUserModal}>
        Are you sure you want to delete assigned user {selectedCard?.user_name}?
      </Modal>
      {isLoading ? (
        <Loading fullScreen="true" />
      ) : (
        <>
          <div className="d-flex">
            <h4>Users</h4>
            <div className="btn-group me-auto ms-3 mb-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onKeyPress={(e) => e.key === "Enter" && search()}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={!searchTerm ? "" : searchTerm}
              />
              <button
                className="btn btn-primary"
                type="button"
                onClick={search}
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="d-flex ms-auto justify-content-end wrapper-filters-cm">
            <div
              className={`mb-2 ms-1 cursor-pointer ${filterStatus === "all" ? "active" : ""}`}
              onClick={() => filterByStatus("all")}
            >
              All
            </div>
            <div
              className={`mb-2 ms-1 cursor-pointer ${filterStatus === "uncompleted" ? "active" : ""}`}
              onClick={() => filterByStatus("uncompleted")}
            >
              Uncompleted
            </div>
            <div
              className={`mb-2 ms-1 cursor-pointer ${filterStatus === "completed" ? "active" : ""}`}
              onClick={() => filterByStatus("completed")}
            >
              Completed
            </div>
          </div>
          <div className="wrapper-campaigns-items">
            {!users ? "Not found" : ""}
            {users?.map((u, index) => (
              <div
                key={index}
                className="p-2 m-1"
                onDragEnter={(e) =>
                  dragEnter(e, { user_id: u.user_id, user_name: u.user_name })
                }
                onDragLeave={(e) => dragLeave(e)}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <h5 className="pointer-events-none ps-1">{u.user_name}</h5>
                <div className="d-flex flex-wrap p-1 wrapper-user-cards">
                  {u.campaigns?.map((camp, idx) => (
                    <div
                      className={`card card-users
                        ${selectedCardId?.toString() === camp.id?.toString() ? "outline-active-card" : ""}
                        ${camp.has_links && !camp.min_links_achieved && !camp.completed ? "has-links" : ""} 
                        ${camp.min_links_achieved && !camp.completed ? "min-links-achieved" : ""} 
                        ${camp.completed ? "card-completed" : ""}`}
                      key={idx}
                      draggable
                      onDragStart={(e) => dragStart(e, camp)}
                      onDragEndCapture={drop}
                    >
                      <div className="d-flex card-action-btn">
                        {camp.status_color ? (
                          <Tooltip text={camp.status_name}>
                            <div
                              className="status-dot"
                              style={{ backgroundColor: camp.status_color }}
                            ></div>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                        {camp.submitted_date ? (
                          <i
                            className="bi bi-bookmark"
                            title={`Submitted Date: ${formatDateMedium(camp.submitted_date)}`}
                          ></i>
                        ) : (
                          ""
                        )}
                        <div style={{ display: "block", marginLeft: "auto" }}>
                          <i
                            className="bi bi-x text-danger cursor-pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_assigned_user"
                            onClick={() => setSelectedCard(camp)}
                          ></i>
                          <i
                            className="bi bi-pencil text-primary cursor-pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_card"
                            onClick={(e) => onClickEditCard(e, camp.id)}
                          ></i>
                        </div>
                      </div>
                      <div className="ellipsis">{camp.site}</div>
                      <div className="wrapper-invoice">
                        <div className="unset-element flex">
                          <a
                            href={camp.xero_link}
                            className="unset-element me-1"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {camp.invoice}
                          </a>
                          <i> - {camp.start_date_formatted}</i>
                        </div>
                        <Tooltip text={camp.notes ? camp.notes : ""}>
                          <div className="unset-element ellipsis box-notes">
                            {camp.notes}
                          </div>
                        </Tooltip>
                      </div>
                      <div
                        className={`d-flex mt-auto flex-wrap justify-content-between ${!camp.assigned_user ? "align-self-end" : ""}`}
                      >
                        <div className="card-remaining">{camp.remaining}</div>
                        <div className="links-details">
                          <Tooltip text={"Links"}>
                            <span>{camp.links}</span>
                          </Tooltip>
                          /
                          <Tooltip text={"HT links"}>
                            <span>{camp.ht_links}</span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ))}
                  {u.campaigns.length === 0 ? (
                    <div className="drag-zone card card-clients opacity-50">
                      Drag & drop campaigns here
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="d-none d-sm-flex nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
          <div className="d-flex d-sm-none nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
        </>
      )}
    </>
  );
}
