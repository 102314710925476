import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import {
  createDataWarehouse,
  getSelectedCategories,
  removeWarehouse,
  setSelectedCategories,
  updateDataWarehouse,
  getSelectedTypes,
  setSelectedTypes,
} from "./dataWarehouseSlice";
import SelectCategories from "./SelectCategories";
import SelectTypes from "./SelectTypes";
import { EditorTinyMCE } from "./EditorTinyMCE";
import { useState } from "react";
import { useRef } from "react";
import { SourceCol } from "./SourceCol";
import { checkPermission } from "helpers/help";

export default function AddWarehouse({
  resetModal,
  isEditing,
  selectedDataWarehouse,
  onOpeningModal,
}) {
  const dispatch = useDispatch();
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [name, setName] = useState("");
  const [sourceWh, setSourceWh] = useState([]);
  // const [lastReleaseDate, setLastReleaseDate] = useState('');
  const selectedCategories = useSelector(getSelectedCategories);
  const selectedTypes = useSelector(getSelectedTypes);
  const [explanation, setExplanation] = useState("");
  const modalBtnRef = useRef();

  useEffect(() => {
    if (selectedDataWarehouse?.id) {
      setName(selectedDataWarehouse.name);
      setSourceWh(selectedDataWarehouse.source);
      // setLastReleaseDate(formatDateShort(selectedDataWarehouse.last_release_date));
      setExplanation(selectedDataWarehouse.explanation);
      dispatch(
        setSelectedCategories(
          selectedDataWarehouse.categories.map((cat) => ({
            label: cat.name,
            value: cat.id,
          })),
        ),
      );
      if (selectedDataWarehouse.types.length > 0) {
        dispatch(
          setSelectedTypes({
            label: selectedDataWarehouse.types[0].name,
            value: selectedDataWarehouse.types[0].id,
          }),
        );
      }
    }
  }, [selectedDataWarehouse?.id, onOpeningModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setName("");
    setSourceWh([]);
    // setLastReleaseDate('');
    dispatch(setSelectedCategories([]));
    dispatch(setSelectedTypes(null));
    setExplanation("");
    document.querySelectorAll(".text-danger.required").forEach((el) => {
      el.style.display = "none";
    });
  }, [resetModal]); // eslint-disable-line react-hooks/exhaustive-deps

  const modalButtons = () => {
    return checkPermission("role_pr", "fe-edit-data-warehouse")
      ? isEditing
        ? "Edit"
        : "Add"
      : "unset";
  };

  const modalDataWarehouse = {
    title: isEditing ? "Edit data source" : "Create new data source",
    target: "new_data_warehouse",
    nameSaveButton: modalButtons(),
    size: "lg",
    canSave:
      name?.length > 0 &&
      sourceWh?.length > 0 &&
      selectedCategories?.length > 0 &&
      selectedTypes &&
      checkPermission("role_pr", "fe-edit-data-warehouse"),
    add: () => {
      let data = {
        name: name,
        source: sourceWh,
        categories: selectedCategories.map((s) => s.value).join(),
        types: selectedTypes ? selectedTypes.value : null,
        // 'last_release_date': lastReleaseDate,
      };
      Object.keys(data).forEach((key) => {
        if (
          data &&
          (data[key] === "" || data[key] === null || data[key]?.length === 0)
        ) {
          document.querySelector(`.${key}`).style.display = "block";
        } else {
          document.querySelector(`.${key}`).style.display = "none";
        }
      });
      if (
        name?.length > 0 &&
        sourceWh?.length > 0 &&
        selectedCategories?.length > 0 &&
        selectedTypes &&
        checkPermission("role_pr", "fe-edit-data-warehouse")
      ) {
        data["explanation"] = explanation;
        if (isEditing) {
          dispatch(
            updateDataWarehouse({ id: selectedDataWarehouse.id, source, data }),
          );
        } else {
          dispatch(createDataWarehouse({ source, data }));
        }
      }
    },
  };

  const deleteWarehouse = (id) => {
    if (
      window.confirm("Are you sure you want to delete this data warehouse?")
    ) {
      dispatch(removeWarehouse({ id, source }));
      modalBtnRef.current.click();
    }
  };

  return (
    <>
      <button
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#new_data_warehouse"
        ref={modalBtnRef}
      ></button>
      <Modal
        props={modalDataWarehouse}
        footer={
          selectedDataWarehouse?.id &&
          checkPermission("role_pr", "fe-edit-data-warehouse") ? (
            <span
              className="me-auto text-danger cursor-pointer"
              onClick={() => deleteWarehouse(selectedDataWarehouse.id)}
            >
              Delete
            </span>
          ) : (
            <></>
          )
        }
      >
        <div className="mt-1">
          <label htmlFor="data-name">
            Data Name<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="data-name"
            className="form-control"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <span
            className="text-danger name required"
            style={{ display: "none" }}
          >
            Please fill in this field
          </span>
        </div>
        <div className="mt-2">
          <label>
            Type<span className="text-danger">*</span>
          </label>
          <SelectTypes selectedTypes={selectedTypes} />
          <span
            className="text-danger types required"
            style={{ display: "none" }}
          >
            Please fill in this field
          </span>
        </div>
        <div className="mt-2">
          <label>
            Data Source<span className="text-danger">*</span>
          </label>
          <SourceCol setSourceWh={setSourceWh} sourceWh={sourceWh} />
          <span
            className="text-danger source required"
            style={{ display: "none" }}
          >
            Please fill in this field
          </span>
        </div>
        <div className="mt-2">
          <label>
            Category<span className="text-danger">*</span>
          </label>
          <SelectCategories selectedCategories={selectedCategories} />
          <span
            className="text-danger categories required"
            style={{ display: "none" }}
          >
            Please fill in this field
          </span>
        </div>
        {/* <div className='mt-2'>
            <label>Last release date<span className='text-danger'>*</span></label>
            <input type='date' className="form-control" onChange={(e) => setLastReleaseDate(e.target.value)} value={lastReleaseDate}/>
            <span className='text-danger last_release_date required' style={{display: 'none'}}>Please fill in this field</span>
        </div> */}
        <div className="mt-3">
          <label>Date source explanation</label>
          <EditorTinyMCE
            selectedItem={explanation}
            setSelectedItem={setExplanation}
          />
        </div>
      </Modal>
    </>
  );
}
