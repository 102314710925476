import { memo, useEffect, useRef, useState } from "react";
import { Loading } from "../loader/Loading";
import axios from "axios";
import apiClient from "../../services/api";
import { useDispatch } from "react-redux";
import { setError } from "../error-handling/errorHandlingSlice";
import { useDebouncedCallback } from "use-debounce";
import ReactPaginate from "react-paginate";
import { formatDate } from "helpers/help";

const TopOpenCampaigns = () => {
  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const dispatch = useDispatch();
  const dateFrom = useRef(null);
  const dateTo = useRef(null);
  const noOfEmails = useRef(null);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const per_page = 100;

  useEffect(() => {
    fetchTopOpenCampaigns({ page: 1 });
    return () => {
      source.cancel("axios request canceled");
      setDataTable([]);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchTopOpenCampaigns = async ({
    page,
    dateFrom,
    dateTo,
    noOfEmails,
  }) => {
    try {
      setLoading(true);
      let params = new URLSearchParams({ per_page, page });
      if (dateFrom) {
        params.append("dateFrom", dateFrom);
      }
      if (dateTo) {
        params.append("dateTo", dateTo);
      }
      if (noOfEmails) {
        params.append("noOfEmails", noOfEmails);
      }
      const res = await apiClient.get(`/api/most-opened-campaigns?${params}`, {
        cancelToken: source.token,
      });
      setDataTable(res.data);
      setPageCount(Math.ceil(res.data?.campaigns?.total / per_page));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setError(error?.message));
    }
  };

  const onChangeDateFrom = useDebouncedCallback((e) => {
    fetchTopOpenCampaigns({
      dateFrom: e.target.value,
      dateTo: dateTo.current.value,
      noOfEmails: noOfEmails.current.value,
      page: 1,
    });
  }, 600);

  const onChangeDateTo = useDebouncedCallback((e) => {
    fetchTopOpenCampaigns({
      dateFrom: dateFrom.current.value,
      dateTo: e.target.value,
      noOfEmails: noOfEmails.current.value,
      page: 1,
    });
  }, 600);

  const onChangeNoOfEmails = useDebouncedCallback((e) => {
    fetchTopOpenCampaigns({
      dateFrom: dateFrom.current.value,
      dateTo: dateTo.current.value,
      noOfEmails: e.target.value,
      page: 1,
    });
  }, 600);

  const handlePageChange = (data) => {
    setPageOffset(data.selected);
    fetchTopOpenCampaigns({
      page: data.selected + 1,
      dateFrom: dateFrom.current.value,
      dateTo: dateTo.current.value,
      noOfEmails: noOfEmails.current.value,
    });
  };

  const formatNameSplit = (name) => {
    let split = name.split(">");
    return split.map((item, index) => {
      if (index === 0) {
        return (
          <div key={index} style={{ color: "black", display: "inline-flex" }}>
            {item} >{" "}
          </div>
        );
      }
      if (index !== split.length - 1) {
        return (
          <div key={index} style={{ color: "grey", display: "inline-flex" }}>
            {item} >{" "}
          </div>
        );
      }
      return (
        <div key={index} style={{ color: "blue" }}>
          {item}
        </div>
      );
    });
  };

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col">
          <label className="me-1">From</label>
          <input
            type="date"
            ref={dateFrom}
            className="form-control"
            defaultValue={dataTable?.dateFrom}
            onChange={(e) => onChangeDateFrom(e)}
          ></input>
        </div>
        <div className="col ms-2">
          <label className="me-1">To</label>
          <input
            type="date"
            ref={dateTo}
            className="form-control"
            defaultValue={dataTable?.dateTo}
            onChange={(e) => onChangeDateTo(e)}
          ></input>
        </div>
        <div className="col ms-2">
          <label className="me-1 w-12">Min no. of emails</label>
          <input
            type="number"
            ref={noOfEmails}
            className="form-control"
            defaultValue={dataTable?.noOfEmails}
            onChange={(e) => onChangeNoOfEmails(e)}
          ></input>
        </div>
      </div>
      <div className="table-responsive">
        {isLoading ? (
          <Loading fullScreen="true" />
        ) : (
          <>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">Campaign Url</th>
                  <th scope="col">Press Release Url</th>
                  <th scope="col" className="text-center">
                    Start date
                  </th>
                  <th scope="col" className="text-center">
                    Open percentage
                  </th>
                  <th scope="col" className="text-center">
                    Open count
                  </th>
                  <th scope="col" className="text-center">
                    No. of emails
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataTable?.campaigns?.data?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        href={data.campaign_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "silver" }}
                      >
                        <div>{formatNameSplit(data.campaign_name)}</div>
                      </a>
                    </td>
                    <td>
                      <a
                        href={data.press_release_url}
                        style={{ color: "silver" }}
                      >
                        <div>{formatNameSplit(data.press_release_name)}</div>
                      </a>
                    </td>
                    <td className="text-center">
                      {formatDate(data.start_date)}
                    </td>
                    <td className="text-center">{data.opened_percentage}%</td>
                    <td className="text-center">{data.count_opened}</td>
                    <td className="text-center">{data.no_of_emails}</td>
                  </tr>
                ))}
                {dataTable?.campaigns?.data?.length === 0 ||
                !dataTable?.campaigns?.data ? (
                  <tr>
                    <td colSpan="6" className="text-left">
                      No records found
                    </td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
      {!isLoading && pageCount > 0 ? (
        <>
          <div className="d-none d-sm-flex nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
          <div className="d-flex d-sm-none nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default memo(TopOpenCampaigns);
