import React from "react";
import { Line } from "react-chartjs-2";

const CampaignBacklogLineChart = ({ data, setLineChartData, options }) => {
  return (
    <div
      className="wrapper-sends-stats"
      style={{
        width: "1000px",
        display: "flex",
        justifyContent: "center",
        flex: "1 1",
        marginBottom: "2rem",
      }}
    >
      <Line
        type="line"
        data={{
          datasets: [setLineChartData(data, "Campaigns backlog", "blue")],
        }}
        options={options}
        style={{ width: "100%", height: "370px" }}
        height={370}
      />
    </div>
  );
};

export default CampaignBacklogLineChart;
