import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loading, menuItems, resetItems } from "../lists/listNavigationSlice";
import { useParams } from "react-router";
import { Loading } from "../loader/Loading";
import Nav from "../../components/Nav";
import {
  updatePressRelease,
  pressReleaseRecords,
  getRecordById,
  getPressRelease,
  resetPressRelease,
  setPressRelease,
} from "./pressReleasesSlice";
import axios from "axios";
import "./pressReleases.scss";
import AutoSave from "../../components/AutoSave";
import { _getUser } from "../auth/loginSlice";
import { setError } from "../error-handling/errorHandlingSlice";
import { PreviewPRSection } from "./PreviewPRSection";
import EmailSubjectsPR from "./EmailSubjectsPR";
import EditorEmailBody from "./EditorEmailBody";
import EditorSignature from "./EditorSignature";
import { Templates } from "./Templates";
import { useFetchPR } from "./useFetchPR";
import RelentlessModeSwitch from "./RelentlessModeSwitch";
import { SendPreviewEmail } from "./SendPreviewEmail";
import { formatDateMedium } from "helpers/help";

export function PressReleases() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const pressRelease = useSelector(getPressRelease);
  const { id } = useParams();
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [contentPR, setContentPR] = useState({
    email_body: null,
    signature: "",
  });
  const user = useSelector(_getUser);
  const [latestRecord, setLatestRecord] = useState(null);
  const [records, setRecords] = useState(null);
  const [isRestored, setIsRestored] = useState(false);
  const loadingMenuItems = useSelector(loading);
  const tinymcepath = user?.use_local_tinymce
    ? process.env.REACT_APP_ENDPOINT + "/tinymce/tinymce.min.js"
    : null;
  const _menuItems = useSelector(menuItems);

  useFetchPR(id, setLatestRecord, setLoading);

  useEffect(() => {
    if (pressRelease?.id) {
      setContentPR({
        email_body: pressRelease.email_body,
        signature: pressRelease.signature,
      });
    }
  }, [pressRelease?.id, pressRelease?.email_body, pressRelease?.signature]);

  useEffect(() => {
    document.title = "Press Releases - JournoResearch";
    return () => {
      dispatch(resetItems());
    };
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      source.cancel("axios request canceled");
      dispatch(resetPressRelease());
      setLatestRecord(null);
      setIsRestored(false);
    };
  }, [dispatch, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const emailTemplate = () => {
    const existEmptyEmailSubject =
      pressRelease.email_subjects?.filter((e) => e).length === 0;
    if (existEmptyEmailSubject || !contentPR.email_body || !pressRelease)
      return;
    return {
      email_body: contentPR.email_body,
      name: pressRelease.name,
      parent_id: pressRelease.parent_id,
      user_id: user.id,
      is_folder: 0,
      relentless_mode: pressRelease.relentless_mode,
      email_subjects: pressRelease.email_subjects,
      signature: contentPR.signature,
    };
  };

  const savePressRelease = async () => {
    const template = emailTemplate();
    if (template) {
      setLoading(true);
      try {
        await dispatch(
          updatePressRelease({ id: pressRelease.id, data: template })
        ).unwrap();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setError(error?.message));
      }
    }
  };

  const restoreData = async (recordId) => {
    try {
      setLoading(true);
      const recordPr = await getRecordById(recordId);
      setIsRestored(true);
      setLatestRecord(recordPr);
      dispatch(
        setPressRelease({
          ...pressRelease,
          relentless_mode: recordPr.relentless_mode,
          email_subjects: recordPr.email_subjects,
          email_body: recordPr.email_body,
          signature: recordPr.signature,
        })
      );
      setContentPR({
        email_body: recordPr.email_body,
        signature: recordPr.signature,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setError(error?.message));
    }
  };

  const onClickRestore = async () => {
    try {
      setRecords(null);
      const res = await pressReleaseRecords(id);
      setRecords(res);
    } catch (error) {
      dispatch(setError(error?.message));
    }
  };

  if (isLoading || loadingMenuItems) {
    return <Loading fullScreen="true" />;
  }

  if (_menuItems?.id && _menuItems?.is_folder && !pressRelease?.id) {
    return (
      <div className="d-flex justify-content-end mt-4">
        <div className="d-flex flex-column">
          <h3>Press Release Page</h3>
          <h6>
            Folder Name: <span>{_menuItems?.name}</span>
          </h6>
          <h6>
            Creation Time:{" "}
            <span>{formatDateMedium(_menuItems?.created_at)}</span>
          </h6>
        </div>
      </div>
    );
  }

  return (
    <>
      {pressRelease?.updated_at ? (
        <AutoSave
          prUpdatedAt={pressRelease.updated_at}
          isRestored={isRestored}
          restoreData={restoreData}
          emailBody={contentPR.email_body}
          emailSubjects={pressRelease.email_subjects}
          relentlessMode={pressRelease.relentless_mode}
          signature={contentPR.signature}
          id={id}
          latestRecord={latestRecord}
          records={records}
        />
      ) : (
        ""
      )}
      {id && pressRelease ? (
        <>
          <Templates />
          <Nav selectedListItem={pressRelease}>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <RelentlessModeSwitch pressRelease={pressRelease} />
                <button
                  className={`ms-2 btn btn-primary ${latestRecord ? "" : "d-none"}`}
                  data-bs-toggle="modal"
                  data-bs-target="#all_restore"
                  onClick={() => onClickRestore()}
                >
                  Restore
                </button>
                <button
                  className={`ms-2 btn btn-primary  ${emailTemplate() ? "" : "disabled"}`}
                  onClick={() => savePressRelease()}
                >
                  Save
                </button>
                <SendPreviewEmail
                  emailTemplate={emailTemplate}
                  savePressRelease={savePressRelease}
                  user={user}
                  pressReleaseId={pressRelease.id}
                  setLoading={setLoading}
                />
              </div>
              {pressRelease?.previewed_at ? (
                <div className="d-flex justify-content-end mt-2">
                  This PR has been previewed at{" "}
                  {formatDateMedium(pressRelease?.previewed_at)}
                </div>
              ) : (
                ""
              )}
            </div>
          </Nav>
          <EmailSubjectsPR pressRelease={pressRelease} />
          <div className="pb-5 wrapper-pr d-flex justify-content-between">
            <div className="wrapper-editor">
              <EditorEmailBody
                tinymcepath={tinymcepath}
                emailBody={pressRelease.email_body}
                setContentPR={setContentPR}
                id={id}
              />
              <EditorSignature
                tinymcepath={tinymcepath}
                pressRelease={pressRelease}
                setContentPR={setContentPR}
                id={id}
                contentPR={contentPR}
              />
            </div>
            <PreviewPRSection
              contentPR={contentPR}
              emailSubjects={pressRelease.email_subjects}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
