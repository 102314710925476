import { useModalContext } from "hooks/context/useModalContext";
import { MODAL_ACTIONS } from "constants/store/ModalActions";
import { useEffect } from "react";
export const useGenericModal = ({ id, data = {} }) => {
  const { modals, dispatch } = useModalContext();
  const currentModal = modals.find((modal) => modal.id === id);

  console.log("modals", modals);

  useEffect(() => {
    if (!currentModal) registerModal();
  }, []);

  const setData = (data) => {
    dispatch({ type: MODAL_ACTIONS.SET_DATA, payload: { id, data: data } });
  };

  const setProps = (props) => {
    dispatch({ type: MODAL_ACTIONS.SET_PROPS, payload: { id, props: props } });
  };

  const getData = () => {
    return currentModal?.data;
  };

  const getProps = () => currentModal?.props;

  const registerModal = () => {
    dispatch({
      type: MODAL_ACTIONS.REGISTER,
      payload: {
        id,
        open: false,
        data,
      },
    });
  };

  const openModal = () => {
    dispatch({
      type: MODAL_ACTIONS.OPEN,
      payload: { id },
    });
  };

  const closeModal = () => {
    dispatch({
      type: MODAL_ACTIONS.CLOSE,
      payload: { id },
    });
  };

  return {
    openModals: modals.filter((modal) => modal.open).length,
    currentModal,
    open: currentModal?.open,
    props: currentModal?.props,
    openModal,
    closeModal,
    setData,
    getData,
    setProps,
    getProps,
  };
};
