import { memo } from "react";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { setPressRelease, setSelectedTemplate } from "./pressReleasesSlice";

const EmailSubjectsPR = ({pressRelease}) => {
    const dispatch = useDispatch();

    const updateEmailSubjects = (value, relentlessMode, index = 0) => {
        const nrOfInputs = Array.isArray(value) ? value.length : 1;
        let currentValues = [...pressRelease.email_subjects];
        if ( Array.isArray(value) && value.length > 0) {
            currentValues = [...value, ...Array(nrOfInputs - value.length).fill('')]
        }  
        if (value !== null && value !== undefined && !Array.isArray(value)) {
            currentValues[index] = value;
        }
        if (!relentlessMode) {
            currentValues = Array.isArray(value) ? [value[0]] : [value];
        }
        dispatch(setPressRelease({...pressRelease, email_subjects: currentValues}));
    }

    const debouncedEmailSubjects = useDebouncedCallback((value, relentlessMode, index) => {
        updateEmailSubjects(value, relentlessMode, index);
    }, 500);

    const setAllInputValues = (value) => {
        const values = pressRelease.email_subjects.map(() => (value));
        dispatch(setPressRelease({...pressRelease, email_subjects: values}));
        document.querySelectorAll('input[name^="subject-"]').forEach((input) => {
            input.value = value;
        });
    }    

    return <>      
        {pressRelease?.email_subjects?.map((email, i) => 
            <div className="mb-2" style={{maxWidth:"700px"}} key={i}>
                <label><b>Email Subject</b>{i === 0 ? <i className="ms-2 bi bi-info-circle cursor-pointer" data-bs-toggle="modal" data-bs-target="#template" onClick={() => dispatch(setSelectedTemplate('press-release-email-subject'))}></i>: ''}</label>
                <div className="d-flex align-items-center">
                    <input type="text" className="form-control" name={`subject-${i}`} onChange={(e) => debouncedEmailSubjects(e.target.value, pressRelease.relentless_mode, i)} defaultValue={email || ''}></input>
                    {i === 0 && pressRelease.relentless_mode ? 
                        <span className="cursor-pointer" onClick={() => setAllInputValues(pressRelease.email_subjects[0])}><i className="ms-3 bi bi-save2"></i></span>
                    : <></>}
                </div>
            </div>
        )}
    </>
};

export default memo(EmailSubjectsPR);