import React from "react";

const Preloader = ({
  children,
  minHeight = "8rem",
  fullScreen = false,
  isLoading = false,
}) => {
  if (isLoading === true) {
    return (
      <div
        className={`spinner-loading d-flex justify-content-center align-items-center h-100 w-100 bg-white ${fullScreen ? "position-fixed w-100  z-3 " : ""} `}
        style={{ minHeight: minHeight }}
      >
        <div className="spinner-border text-black" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return children;
};

export default Preloader;
