import {
  defaultAnimateLayoutChanges,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { Container } from "components/ui-elements/drag-and-drop/components/Container/Container";
import { CSS } from "@dnd-kit/utilities";
import { useSortableDndActions } from "hooks/drag-and-drop/useSortableDndActions";

export const animateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export function SortableDndContainer({
  children,
  columns = 1,
  id,
  items,
  style,
  strategy,
  ...props
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: "container",
      children: items,
    },
    animateLayoutChanges,
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== "container") ||
      items.includes(over.id)
    : false;

  const { getContainerById } = useSortableDndActions();

  const container = getContainerById(id);

  const label = container?.label;
  const disabled = container?.disabled;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        fontWeight: "bold",
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      label={label}
      {...props}
    >
      {disabled ? (
        children
      ) : (
        <SortableContext strategy={strategy} items={items}>
          {children}
        </SortableContext>
      )}
    </Container>
  );
}
