import {Doughnut} from 'react-chartjs-2';
import 'chart.js/auto';

export function DoughnutChart(props) {
    const plugins = [{
        beforeInit: function(chart) {
            var data = chart.data.datasets[0].data;
            var isAllZero = data.reduce((a, b) => a + b) > 0 ? false : true;
            if (!isAllZero) return;
            // when all data values are zero...
            chart.data.datasets[0].data = data.map((e, i) => i > 0 ? 0 : 1); //add one segment
            chart.data.datasets[0].backgroundColor = '#0000001a'; //change bg color
            chart.data.datasets[0].borderWidth = 0; //no border
            // chart.options.tooltips = false; //disable tooltips
            // chart.options.hover = false; //disable hover
            chart.options.plugins.tooltip.enabled = false; //no animation
            console.log(chart.options.plugins.tooltip);
            // chart.options.legend.onClick = null; //disable legend click
        },
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 150).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            var text = chart.config.data.datasets[0]['text'],
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2 - 5;
            ctx.fillText(text, textX, textY);
            ctx.save();
        },
    }]
    const options1 = {
        responsive: true,
        layout: {
            padding: 40
        },
        plugins: 
        { 
            legend: { display: false }, 
            cutout: '80%',
        },
    };
    return (
        <div className="doughnut">
            <Doughnut data={props.data} options={options1} height={120} plugins={plugins}/>
        </div>
    )
}