import React from "react";
import { formatDateMedium } from "helpers/help";
import { Link } from "react-router-dom";
export function LatestActivityPressReleases({ data }) {
  return (
    <>
      {data?.length > 0 ? (
        <div className="table-responsive table-max-height">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" className="name-col">
                  Name
                </th>
                <th scope="col">Press Release</th>
                <th scope="col">Update date</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data, index) => (
                <tr key={index}>
                  <td className="name-col">{data.user_name}</td>
                  <td>
                    <div className="wrapper-path-td">
                      <Link to={`/press-releases/${data.id}`}>
                        {data.path}
                        <i>{data.path ? ` > ${data.name}` : data.name}</i>
                      </Link>
                    </div>
                  </td>
                  <td>{formatDateMedium(data.update_date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
