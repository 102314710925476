import { defaultDropAnimationSideEffects } from "@dnd-kit/core";

export const dropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export function groupByColumnWithBase(items, containers, getter = "column") {
  if (!items || !containers || items.includes(undefined)) return {};
  const accBase = containers.reduce((acc, column) => {
    acc[column] = [];
    return acc;
  }, {});

  const grouped = items.reduce((acc, item) => {
    if (item[getter] in acc) {
      acc[item[getter]].push(item);
    }
    return acc;
  }, accBase);
  Object.keys(grouped).forEach((column) => {
    grouped[column].sort((a, b) => a.index - b.index);
    grouped[column] = grouped[column].map((item) => item.id);
  });

  return grouped;
}
