import { Editor } from "@tinymce/tinymce-react";
import { memo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useInitTinyMCE } from "./useInitTinyMCE";
import { Loading } from "../loader/Loading";
import { useDispatch } from "react-redux";
import { setSelectedTemplate, updatePressRelease } from "./pressReleasesSlice";

const EditorSignature = ({ tinymcepath, pressRelease, setContentPR, id, contentPR}) => {
    const dispatch = useDispatch();
    const signatureRef = useRef(null);
    const initEditor = useInitTinyMCE({ id: id, ref: signatureRef, height: 500 });
    const [loading, setLoading] = useState(false);

    const debouncedSignature = useDebouncedCallback((value) => {
        if (signatureRef.current) {
            let content = signatureRef.current.getContent();
            setContentPR(prev => ({ ...prev, signature: content }));
        }
    }, 600);

    const generateSignature = async () => {
        setLoading(true);
        await dispatch(updatePressRelease({ id: id, data: { ...pressRelease, ...contentPR , signature: '' } })).unwrap();
        setLoading(false);
    }

    if (loading) {
        return <div className="editor mt-3"><Loading fullScreen="true" /></div>
    }

    return (<div className="editor mt-3">
        <label><b>Signature</b></label>
        <i className="ms-2 bi bi-info-circle cursor-pointer" data-bs-toggle="modal" data-bs-target="#template" onClick={() => dispatch(setSelectedTemplate('press-release-email-signature'))}></i>
        <button onClick={generateSignature} className="mb-2 ms-2 btn btn-outline-primary btn-sm">Regenerate</button>
        <Editor tinymceScriptSrc={tinymcepath}
            apiKey='cmnnd5qnqu6rfr85uibg46q9tzjq6gg9qa9hzgx4f6wo45go'
            onInit={(evt, editor) => signatureRef.current = editor}
            onKeyDown={debouncedSignature}
            initialValue={pressRelease.signature}
            disabled={true}
            init={initEditor}
        />
    </div>)
}

export default memo(EditorSignature);