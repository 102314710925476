export const CAMPAIGN_COLUMNS = [
  {
    id: "myCampaigns",
    label: "My Campaigns",
    disabled: false,
    rules: {
      canAccept: ["ideationStage"],
    },
  },
  {
    id: "ideationStage",
    label: "Ideation stage",
    disabled: false,
    rules: {
      canDrop: ["ideaStatus"],
    },
  },
  {
    id: "ideaStatus",
    label: "Idea status",
    disabled: false,
    rules: {
      canDrop: ["dataStage", "pressReleaseStage"],
    },
  },
  {
    id: "dataStage",
    label: "Data stage",
    disabled: false,
    rules: {
      canDrop: ["ideaStatus"],
    },
  },
  {
    id: "dataStatus",
    label: "Data status",
    disabled: false,
    rules: {
      canDrop: ["pressReleaseStage"],
    },
  },
  {
    id: "pressReleaseStage",
    disabled: false,
    label: "Press release stage",
    rules: {
      canDrop: ["pressReleaseStatus"],
    },
  },
  {
    id: "pressReleaseStatus",
    label: "Press release status",
    disabled: false,
    rules: {
      canDrop: ["done"],
    },
  },
];
