import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loading } from "../loader/Loading";
import {
  campaignsEmails,
  pushCampaignsEmails,
  deleteCampaignsEmails,
  unsavedCampaignsEmails,
  resetCampaignsEmails,
  fetchCampaignsEmails,
  initPushCampaignsEmails,
} from "./campaignsSlice";
import axios from "axios";
import Wrapper from "../../components/Wrapper";
import "./tableCampaign.scss";
import { sleep } from "helpers/help";
import { setError } from "../error-handling/errorHandlingSlice";

export function TableCampaign({ listsOptions, isLoadingList, id }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const localCampaignsEmails = useSelector(campaignsEmails);
  const localUnsavedCampaignsEmails = useSelector(unsavedCampaignsEmails);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [data, setData] = useState([]);
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [listName, setListName] = useState([]);

  useEffect(() => {
    getCampaign();
    return () => {
      dispatch(resetCampaignsEmails());
      source.cancel("axios request canceled");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCampaign = async () => {
    try {
      setLoading(true);
      const cmpEmails = await dispatch(
        fetchCampaignsEmails({ id, source }),
      ).unwrap();
      if (cmpEmails?.emails) {
        Object.keys(cmpEmails.emails).forEach((v) => {
          if (Array.isArray(cmpEmails.emails[v])) {
            dispatch(initPushCampaignsEmails(cmpEmails.emails[v]));
          } else {
            let ids = [];
            Object.keys(cmpEmails.emails[v]).forEach((fromList) => {
              cmpEmails.emails[v][fromList].forEach((l) => {
                ids.push({ id: l.object_id, name: fromList });
              });
              dispatch(initPushCampaignsEmails(cmpEmails.emails[v][fromList]));
            });
            setListName(ids);
          }
        });
      }
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        setLoading(false);
        dispatch(setError(error?.message));
      }
    }
  };

  useEffect(() => {
    if (localUnsavedCampaignsEmails?.length > 0) {
      let acc = [];
      let newManualCampaignEmail = { Manual: [] };
      let newFromJournalist = { "From Journalists": [] };
      let newFromList = { "From Lists": {} };
      let dataEmails = {};
      localUnsavedCampaignsEmails.forEach((l) => {
        if (parseInt(l.type) === 3) {
          newManualCampaignEmail["Manual"].unshift(l);
          let newData = { ...dataEmails, ...newManualCampaignEmail };
          dataEmails = { Manual: [...newData["Manual"]], ...newData };
        }
        if (parseInt(l.type) === 1) {
          acc.push(l);
          if (listsOptions) {
            let list = listsOptions.find((o) => o.value === l.object_id);
            if (list?.name) {
              newFromList["From Lists"][list.name] = acc.filter(
                (a) => a.object_id === list.value,
              );
            }
          }
          if (listName?.length > 0 && !listsOptions) {
            let list = listName.find((o) => o.id === l.object_id);
            if (list?.name) {
              newFromList["From Lists"][list.name] = acc.filter(
                (a) => a.object_id === list.id,
              );
            }
          }
          dataEmails = { ...dataEmails, ...newFromList };
        }
        if (parseInt(l.type) === 2) {
          newFromJournalist["From Journalists"].unshift(l);
          dataEmails = { ...dataEmails, ...newFromJournalist };
        }
      });
      setData({ ...dataEmails });
    } else {
      setData([]);
    }
  }, [localUnsavedCampaignsEmails, listName]); // eslint-disable-line react-hooks/exhaustive-deps

  const createCampaignEmail = () => {
    if (newLastName && newFirstName && newEmail) {
      const foundEmail = localUnsavedCampaignsEmails.find(
        (e) => e.email.toLowerCase().trim() === newEmail.toLowerCase().trim(),
      );
      if (!foundEmail) {
        dispatch(
          pushCampaignsEmails([
            {
              first_name: newFirstName,
              last_name: newLastName,
              email: newEmail,
              deleted: 0,
              notSaved: 1,
              type: 3,
              object_id: null,
              statuses: [],
            },
          ]),
        );
      }
    }
  };

  const removeCampaignEmail = (email) => {
    dispatch(deleteCampaignsEmails(email));
  };

  const removeEmails = (campaign, name) => {
    if (window.confirm(`Are you sure you want to delete ${name}?`)) {
      campaign.forEach((c) => {
        removeCampaignEmail(c.email);
      });
    }
  };

  const table = (data) => (
    <table className="table table-campaign">
      <tbody>
        {data?.map((user, index) =>
          !user.deleted ? (
            <tr key={index} className="show">
              <td className="first-name-col">
                <div>{user.first_name}</div>
              </td>
              <td className="last-name-col">
                <div>{user.last_name}</div>
              </td>
              <td className="user-email-col">
                <div>{user.email}</div>
              </td>
              <td className="remove-user-col">
                <i
                  className="bi bi-person-x-fill text-danger ps-3"
                  onClick={() => removeCampaignEmail(user.email)}
                />
              </td>
            </tr>
          ) : (
            <tr key={index}></tr>
          ),
        )}
      </tbody>
    </table>
  );

  const toggle = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const currentTarget = event.currentTarget;
    currentTarget.classList.add("loading-item");
    await sleep(1);
    const itemParent =
      currentTarget.parentElement?.parentElement?.parentElement;
    if (itemParent.classList.contains("show-all")) {
      currentTarget.parentElement.parentElement.parentElement.classList.remove(
        "show-all",
      );
    }
    let parent = currentTarget.parentElement.parentElement;
    let items = parent.querySelector(".items");
    if (items.classList.contains("hide")) {
      items.classList.remove("hide");
      if (itemParent) {
        currentTarget.parentElement.parentElement.parentElement.classList.add(
          "show-all",
        );
      }
    } else {
      items.classList.add("hide");
      items.classList.remove("show-all");
    }
    toggleIcon(currentTarget, items);
    await sleep(1);
    currentTarget.classList.remove("loading-item");
  };

  const toggleAllChildren = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const currentTarget = event.currentTarget.parentElement;
    currentTarget.classList.add("loading-item");
    await sleep(1);
    let parent = currentTarget.parentElement.parentElement;
    let items = parent.querySelectorAll(".items");
    if (items[0].classList.contains("show-all")) {
      items[0].classList.remove("show-all");
      items.forEach((i) => {
        toggleIcon(i.parentElement.children[0].children[0], items[0]);
        i.classList.add("hide");
      });
      items[0].classList.remove("hide");
    } else {
      items[0].classList.add("show-all");
      items.forEach((i) => {
        toggleIcon(i.parentElement.children[0].children[0], items[0]);
        i.classList.remove("hide");
      });
      items[0].classList.remove("hide");
    }
    toggleIcon(currentTarget, items[0]);
    await sleep(1);
    currentTarget.classList.remove("loading-item");
  };

  const toggleIcon = (currentTarget, item) => {
    if (currentTarget && currentTarget.children[0].children[0]) {
      if (!item.classList.contains("hide")) {
        currentTarget.children[0].children[0].classList.remove(
          "bi-chevron-right",
        );
        currentTarget.children[0].children[0].classList.add("bi-chevron-down");
      } else {
        currentTarget.children[0].children[0].classList.remove(
          "bi-chevron-down",
        );
        currentTarget.children[0].children[0].classList.add("bi-chevron-right");
      }
    }
  };

  if (isLoading || isLoadingList) {
    return <Loading fullScreen="true" />;
  }
    return <>
        <table className="table table-campaign">
            <thead>
            { localUnsavedCampaignsEmails?.filter(l => !l.deleted).length > 0 ?<tr>
                <th style={{fontSize: '0.8em', fontWeight: '300'}}>{localUnsavedCampaignsEmails?.filter(l => !l.deleted).length} emails</th>
            </tr> : <></>}
            <tr>
                <th scope="col" className='first-name-col'>First name</th>
                <th scope="col" className='last-name-col'>Last name</th>
                <th scope="col" className='user-email-col'>Email</th>
            </tr>
            <tr key='add'>
                <td className='first-name-col'><input className="form-control" onChange={(e) => setNewFirstName(e.target.value)} placeholder='First Name'></input></td>
                <td className='last-name-col'><input className="form-control" onChange={(e) => setNewLastName(e.target.value)} placeholder='Last Name'></input></td>
                <td className='user-email-col'><input className="form-control" onChange={(e) => setNewEmail(e.target.value)} placeholder='email'></input></td>
                <td><i className={`bi bi-person-x-fill text-primary ps-3 pe-2 ${newEmail && newFirstName && newLastName ? '' : 'disabled'}`} onClick={() => createCampaignEmail()}/></td>
            </tr>
            </thead>
        </table>
        {localCampaignsEmails && data && Object.keys(data)?.length > 0 && Object.keys(data).map((typeOfEmail, idx) => {
            let isArray = data[typeOfEmail] ? Array.isArray(data[typeOfEmail]) : false;
            let flatEmails = data[typeOfEmail] ? Object.values(data[typeOfEmail]).flat() : [];
            return <Wrapper key={idx}>
                <div className="lists">
                    {flatEmails.find(f => !f.deleted) ?
                        <div className="toggle email-group" onClick={(e) => toggle(e)}>
                        <span className='box-toggle' onClick={(e) => toggle(e)}>
                            <i className="bi bi-chevron-down me-2"></i>
                            {typeOfEmail}
                            {typeOfEmail === 'From Lists' ? <i title="Toggle all items" className="ms-3 bi bi-chevron-double-down me-2" onClick={(e) => toggleAllChildren(e)}></i> : ''}
                            <div className="spinner-border text-primary spinner-table" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </span>
                            <i className="bi bi-people-fill text-danger pe-2 cursor-pointer" onClick={(e) => {e.stopPropagation(); removeEmails(flatEmails, typeOfEmail)}}/>
                        </div>
                        : <></>}
                    <div className="items show-all">
                        {isArray ?
                            <>{table(data[typeOfEmail])}</> :
                            <>
                                {typeOfEmail && data[typeOfEmail] && Object.keys(data[typeOfEmail])?.length > 0 && Object.keys(data[typeOfEmail]).map((group, index) => {
                                    const emailGrouped = data[typeOfEmail][group];
                                    const titleGroup = group.split('||');
                                    return emailGrouped.find(e => !e.deleted) ? (<div key={index} className={`list-${index}`}>
                                        <div className="toggle email-subgroup" onClick={(e) => toggle(e)}>
                                            <span className='box-toggle' onClick={(e) => toggle(e)}>
                                                <i className="bi bi-chevron-down me-2"></i>
                                                {titleGroup[0]}
                                                <div className="spinner-border text-primary spinner-table" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                <span className='ms-3' style={{color: 'silver'}}>{titleGroup[1]}</span>
                                            </span>
                                            <i className="bi bi-people-fill text-danger pe-2 cursor-pointer" onClick={(e) => {e.stopPropagation(); removeEmails(emailGrouped, titleGroup[0]);}}/>
                                        </div>
                                        <div className="items"><>{table(emailGrouped)}</></div>
                                    </div>) : <Wrapper key={index} />
                                })}
                            </>
                        }
                    </div>
                </div>
            </Wrapper>
        })}
    </>
}
