import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/api';

export const fetchPressReleasesById = createAsyncThunk(
  'api/fetchPressReleasesById',
  async ({id, source}) => {
    try {
      const response = await apiClient.get(`/api/press-releases/${id}`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const updatePressRelease = createAsyncThunk(
  'api/updatePressRelease',
  async ({ id, data }) => {
    try {
      const response = await apiClient.put(`/api/press-releases/${id}`, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const previewPressRelease = createAsyncThunk(
  'api/previewPressRelease',
  async ({ id, data }) => {
    try {
      const response = await apiClient.post(`/api/press-releases/preview/${id}`, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const fetchTemplates = createAsyncThunk(
  'api/templates',
  async ({source}) => {
    try {
      const response = await apiClient.get(`/api/templates/press-release`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export async function recordPressRelease(data) {
  try {
      const response = await apiClient.post(`/api/press-release-records`, data);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
      }
  }
}

export async function pressReleaseRecords(id) {
  try {
      const response = await apiClient.get(`/api/press-release-records/${id}`);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
      }
  }
}

export async function getLatestRecord({id, source}) {
  try {
      const response = await apiClient.get(`/api/press-release-records/latest/${id}`, { cancelToken: source.token });
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
      }
  }
}

export async function getRecordById(id) {
  try {
      const response = await apiClient.get(`/api/press-release-records/record/${id}`);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
      }
  }
}

const initialState = {
  status: "idle",
  pressRelease: null,
  error: '',
  templates: null,
  selectedTemplate: null,
};

const pressReleasesSlice = createSlice({
  name: "pressReleases",
  initialState,
  reducers: {
    setPressRelease(state, action) {
      state.pressRelease = action.payload;
    },
    setSelectedTemplate(state, action) {
      if (state.templates) {
        state.selectedTemplate = state.templates[action.payload];
      }
    },
    resetPressRelease(state) {
      state.pressRelease = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPressReleasesById.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPressReleasesById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pressRelease = action.payload;
        if (action.payload?.email_subjects === null 
          || action.payload?.email_subjects?.filter(e => e).length === 0) {
          state.pressRelease.email_subjects = [''];
        }
      })
      .addCase(fetchPressReleasesById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updatePressRelease.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pressRelease = action.payload;
        if (action.payload?.email_subjects === null 
          || action.payload?.email_subjects?.filter(e => e).length === 0) {
          state.pressRelease.email_subjects = [''];
        }
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.templates = action.payload;
      })
  },
});
export const { setPressRelease, setSelectedTemplate, resetPressRelease } = pressReleasesSlice.actions;
//export const campaign = (state) => state.pressReleases.campaign;
export const getPressRelease = (state) => state.pressReleases.pressRelease;
export const getTemplates = (state) => state.pressReleases.templates;
export const getSelectedTemplate = (state) => state.pressReleases.selectedTemplate;



export default pressReleasesSlice.reducer;