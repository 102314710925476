import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "../loader/Loading";
import {
  disableClient,
  fetchCampaigns,
  splitCampaign,
} from "./campaignsManagerAPI";
import "./campaigns-manager.scss";
import ReactPaginate from "react-paginate";
import { useNavigate, useLocation } from "react-router";
import Modal from "../../components/Modal";
import { Tooltip } from "../../components/Tooltip";
import { formatDateMedium } from "helpers/help";
import { setError } from "../error-handling/errorHandlingSlice";
import { useDispatch } from "react-redux";

export function CampaignsSectionManager({
  dragStart,
  drop,
  card,
  remount,
  setRemount,
  selectedCardId,
}) {
  const dispatch = useDispatch();
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState("uncompleted");
  const [selectedClient, setSelectedClient] = useState(null);
  const [showHidden, setShowHidden] = useState(false);
  const per_page = 120;
  let navigate = useNavigate();
  let location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  useEffect(() => {
    document.title = "Campaigns Manager JournoResearch";
    return () => {
      source.cancel("axios request canceled");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (card) {
      const _campaigns = [...campaigns];
      const indexOfClient = _campaigns.findIndex(
        (c) => c.client === card?.from?.client,
      );
      if (indexOfClient > -1) {
        const indexOfCampaign = _campaigns[indexOfClient].campaigns[
          card.from.si_id
        ].findIndex((c) => c.id === card.from.id);
        if (indexOfCampaign > -1) {
          _campaigns[indexOfClient].campaigns[card.to.si_id][
            indexOfCampaign
          ].user_name = card.to.user_name;
          _campaigns[indexOfClient].campaigns[card.to.si_id][
            indexOfCampaign
          ].assigned_user = card.to.assigned_user;
        }
      }
      setCampaigns(_campaigns);
    }
  }, [card]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadCampaigns();
  }, [
    urlSearchParams.get("leftPage"),
    urlSearchParams.get("leftSearchTerm"),
    urlSearchParams.get("date"),
    urlSearchParams.get("leftStatus"),
    urlSearchParams.get("showHidden"),
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCampaigns = async () => {
    let page = urlSearchParams.get("leftPage");
    let searchTerm = urlSearchParams.get("leftSearchTerm");
    let filterDate = urlSearchParams.get("date");
    let filterStatusParams = urlSearchParams.get("leftStatus");
    let _showHidden = urlSearchParams.get("showHidden");
    setSearchTerm(searchTerm);
    setFilterDate(filterDate);
    setShowHidden(_showHidden);
    if (filterStatusParams) setFilterStatus(filterStatusParams);
    await getCampaigns(
      page ? page - 1 : 0,
      searchTerm,
      filterDate,
      filterStatusParams ? filterStatusParams : filterStatus,
      _showHidden,
    );
  };

  const getCampaigns = async (
    pageOffset = 0,
    searchTerm = "",
    filterDate = null,
    filterStatus = null,
    showHidden = false,
  ) => {
    try {
      setLoading(true);
      const page = parseInt(pageOffset) + 1;
      const response = await fetchCampaigns(
        source,
        per_page,
        page,
        searchTerm,
        filterDate,
        filterStatus,
        showHidden,
      );
      if (response) {
        setPageOffset(parseInt(pageOffset));
        setPageCount(Math.ceil(response.total / per_page));
        setCampaigns(response.data);
        setLoading(false);
      }
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
    urlSearchParams.delete("leftPage");
    urlSearchParams.append("leftPage", event.selected + 1);
    navigate(`./?${urlSearchParams}`);
  };

  const resetPage = () => {
    urlSearchParams.delete("leftPage");
    urlSearchParams.append("leftPage", 1);
  };

  const search = () => {
    resetPage();
    urlSearchParams.delete("leftSearchTerm");
    urlSearchParams.append("leftSearchTerm", searchTerm);
    navigate(`./?${urlSearchParams}`);
  };

  const filterByDate = () => {
    resetPage();
    urlSearchParams.delete("date");
    urlSearchParams.append("date", filterDate);
    navigate(`./?${urlSearchParams}`);
  };

  const filterByStatus = (status) => {
    setFilterStatus(status);
    resetPage();
    urlSearchParams.delete("leftStatus");
    urlSearchParams.append("leftStatus", status);
    navigate(`./?${urlSearchParams}`);
  };

  const showHiddenCards = (state) => {
    setShowHidden(state);
    resetPage();
    urlSearchParams.delete("showHidden");
    if (state) {
      urlSearchParams.append("showHidden", state);
    }
    navigate(`./?${urlSearchParams}`);
  };

  const disableClientModalProp = {
    title: "Disable Client",
    target: "disable_client",
    nameSaveButton: "DISABLE",
    danger: true,
    add: () => {
      if (selectedClient?.si_user_id) {
        disableClientById(selectedClient.si_user_id);
      }
    },
  };

  const disableClientById = async (id) => {
    try {
      setLoading(true);
      await disableClient(id, source);
      const _campaigns = [...campaigns];
      const indexOfClient = _campaigns.findIndex((c) => c.si_user_id === id);
      if (indexOfClient > -1) {
        _campaigns.splice(indexOfClient, 1);
        setCampaigns(_campaigns);
      }
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  const onSplittingCampaign = async (id) => {
    if (window.confirm("Are you sure you want to split the campaign?")) {
      try {
        setLoading(true);
        await splitCampaign(id, source);
        setRemount(remount + 1);
        setLoading(false);
      } catch (error) {
        if (error?.message !== "axios request canceled") {
          dispatch(setError(error?.message));
          setLoading(false);
        }
      }
    }
  };

  const onClickEditCard = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    urlSearchParams.delete("statuses");
    urlSearchParams.delete("id");
    urlSearchParams.append("id", id);
    navigate(`./?${urlSearchParams}`);
  };

  return (
    <>
      <Modal props={disableClientModalProp}>
        Are you sure you want to disable client {selectedClient?.client}?
      </Modal>
      {isLoading ? (
        <Loading fullScreen="true" />
      ) : (
        <>
          <div className="d-flex">
            <h4>Clients</h4>
            <div className="btn-group me-auto ms-3 mb-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onKeyPress={(e) => e.key === "Enter" && search()}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={!searchTerm ? "" : searchTerm}
              />
              <button
                className="btn btn-primary"
                type="button"
                onClick={search}
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
            <div className="btn-group ms-auto ms-3 mb-2 filter-date">
              <input
                type="date"
                className="form-control ms-auto"
                onKeyPress={(e) => e.key === "Enter" && filterByDate()}
                onChange={(e) => setFilterDate(e.target.value)}
                value={!filterDate ? "" : filterDate}
              />
              <button
                className="btn btn-primary"
                type="button"
                onClick={filterByDate}
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="d-flex ms-auto justify-content-end wrapper-filters-cm">
            <div className="form-check form-switch me-auto">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="show_hidden_card"
                checked={showHidden ? true : false}
                onChange={() => showHiddenCards(!showHidden)}
              />
              <label className="form-check-label" htmlFor="show_hidden_card">
                Show hidden cards
              </label>
            </div>
            <div
              className={`mb-2 ms-1 cursor-pointer ${filterStatus === "all" ? "active" : ""}`}
              onClick={() => filterByStatus("all")}
            >
              All
            </div>
            <div
              className={`mb-2 ms-1 cursor-pointer ${filterStatus === "uncompleted" ? "active" : ""}`}
              onClick={() => filterByStatus("uncompleted")}
            >
              Uncompleted
            </div>
            <div
              className={`mb-2 ms-1 cursor-pointer ${filterStatus === "completed" ? "active" : ""}`}
              onClick={() => filterByStatus("completed")}
            >
              Completed
            </div>
          </div>
          <div className="wrapper-campaigns-items">
            {!campaigns ? "Not found" : ""}
            {campaigns?.map((c, index) => (
              <div key={index} className="p-2 m-1">
                <div className="d-flex align-items-baseline">
                  <h5>{c.client}</h5>
                  <span
                    className="ms-2"
                    style={{ color: "silver" }}
                  >{`${c.company} - (${c.sites})`}</span>
                  <i
                    className="bi bi-x text-danger cursor-pointer ms-2"
                    data-bs-toggle="modal"
                    data-bs-target="#disable_client"
                    onClick={() =>
                      setSelectedClient({
                        client: c.client,
                        si_user_id: c.si_user_id,
                      })
                    }
                  ></i>
                </div>
                <div className="d-flex flex-wrap p-1">
                  {Object.keys(c.campaigns)?.map((_campaign, idx) => {
                    return (
                      <div
                        className={`d-flex ${c.campaigns[_campaign]?.length > 1 ? "wrapper-split-campaigns" : ""}`}
                        key={"camp-" + idx}
                      >
                        {c.campaigns[_campaign]?.map((camp, index) => (
                          <div
                            className={`card card-clients
                        ${selectedCardId?.toString() === camp.id?.toString() ? "outline-active-card" : ""}
                        ${camp.assigned_user ? "assigned-user" : ""} 
                        ${camp.has_links && !camp.min_links_achieved && !camp.completed ? "has-links" : ""} 
                        ${camp.min_links_achieved && !camp.completed ? "min-links-achieved" : ""} 
                        ${camp.completed ? "card-completed" : ""}`}
                            draggable={camp.assigned_user ? "false" : "true"}
                            key={index}
                            onDragStart={(e) => dragStart(e, camp)}
                            onDragEndCapture={drop}
                          >
                            <div className="d-flex justify-content-end card-action-btn">
                              {camp.status_history ? (
                                <Tooltip text={camp.status_history.name}>
                                  <div
                                    className="status-dot"
                                    style={{
                                      backgroundColor:
                                        camp.status_history.color,
                                    }}
                                  ></div>
                                </Tooltip>
                              ) : (
                                <></>
                              )}
                              {camp.submitted_date ? (
                                <i
                                  className="bi bi-bookmark"
                                  title={`Submitted Date: ${formatDateMedium(camp.submitted_date)}`}
                                ></i>
                              ) : (
                                ""
                              )}
                              <div
                                style={{ display: "block", marginLeft: "auto" }}
                              >
                                <i
                                  className="bi bi-vr text-secondary cursor-pointer opacity-50"
                                  onClick={() => onSplittingCampaign(camp.id)}
                                ></i>
                                <i
                                  className="bi bi-pencil text-primary cursor-pointer"
                                  onClick={(e) => onClickEditCard(e, camp.id)}
                                ></i>
                              </div>
                            </div>
                            <div className="ellipsis">{camp.site}</div>
                            <div className="wrapper-invoice">
                              <div className="unset-element flex">
                                <a
                                  href={camp.xero_link}
                                  className="unset-element mr-1"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {camp.invoice}
                                </a>
                                <i> - {camp.start_date_formatted}</i>
                              </div>
                              <Tooltip text={camp.notes ? camp.notes : ""}>
                                <div className="unset-element ellipsis box-notes">
                                  {camp.notes}
                                </div>
                              </Tooltip>
                            </div>
                            <div
                              className={`d-flex mt-auto flex-wrap justify-content-between ${!camp.assigned_user ? "align-self-end" : ""}`}
                            >
                              {camp.user_name ? (
                                <Tooltip
                                  text={camp.user_name ? camp.user_name : ""}
                                >
                                  <div className="assigned-to ellipsis">
                                    <i className="bi bi-person-check me-1"></i>
                                    <b className="ellipsis">{camp.user_name}</b>
                                  </div>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                              <div className="links-details">
                                <Tooltip text={"Links"}>
                                  <span>{camp.links}</span>
                                </Tooltip>
                                /
                                <Tooltip text={"HT links"}>
                                  <span>{camp.ht_links}</span>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className="d-none d-sm-flex nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
          <div className="d-flex d-sm-none nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
        </>
      )}
    </>
  );
}
