
import React, { useState, useEffect } from 'react';
import { importListItems } from '../features/lists/listsSlice';
import Modal from './Modal';
import { useDispatch } from 'react-redux';
import { Loading } from '../features/loader/Loading';
import { useDropzone } from 'react-dropzone';
import { setError } from '../features/error-handling/errorHandlingSlice';

export default function Import(props) {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        }
    });
    const [removeDuplicate, setRemoveDuplicate] = useState(true);

    useEffect(() => {
        setFiles(acceptedFiles);
    }, [acceptedFiles])

    const filesDragZone = files?.map(file => (
        <li key={file?.path}>
            {file?.path} - {file?.size} bytes
        </li>
    ));

    const modalProp = {
        nameSaveButton: 'Import',
        target: 'import',
        title: 'Import list',
        add: async () => {
            if (files?.length > 0 && props?.id) {
                setLoading(true);
                try {
                    const formData = new FormData();
                    files.forEach(file => {
                        formData.append('file[]', file)
                    });
                    await dispatch(importListItems({ id: props.id,  formData, removeDuplicate: removeDuplicate ?  1 : 0 })).unwrap();
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    dispatch(setError(error?.message));
                }
            }
        }
    }

    const resetDragZone = () => {
        setFiles([]);
        if (acceptedFiles?.length > 0) {
            acceptedFiles.length = 0
            acceptedFiles.splice(0, acceptedFiles.length);
        }
    };

    if (isLoading) {
        return <Loading fullScreen="true" />
    }

    return (
        <>
            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#import" onClick={() => resetDragZone()}>{props?.name ? props.name : 'Import'}</button>
            <Modal props={modalProp}>
                <div {...getRootProps({ className: 'drop-zone' })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <aside>
                    <div className="form-check mt-3">
                        <input className="form-check-input" type="checkbox" value={removeDuplicate} onChange={() => setRemoveDuplicate(!removeDuplicate)} checked={removeDuplicate}/>
                        <label className="form-check-label">
                            Remove duplicate from current folder
                        </label>
                    </div>
                    <h6 className="pt-3">Files</h6>
                    {files ? <ul>{filesDragZone}</ul> : ''}
                </aside>
            </Modal>
        </>)
}