import { useGenericModal } from "hooks/modal/useGenericModal";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import "components/ui-elements/modal/modals.scss";
import Preloader from "components/ui-elements/preloader/Preloader";
const GenericModal = ({
  id,
  children,
  className = "",
  contentClassName = "",
  dialogClassName = "",
  loadingMinHeight = "100",
}) => {
  const { closeModal, open, props, openModals } = useGenericModal({
    id,
  });
  const [openModal, setOpenModal] = useState(!!open);
  const size = props?.size || "lg";
  const title = props?.title || "";
  const closeButton = props?.closeButton || true;
  const backdropClick = props?.onBackdropClick || false;
  const onBackdropClick = backdropClick ? props.onBackdropClick : closeModal;
  const centered = props?.centered || true;
  const loading = props?.loading || false;

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  return (
    <Modal
      id={id}
      onHide={closeModal}
      size={size}
      className={`${className} modal-n-${openModals}`}
      show={openModal}
      centered={centered}
      contentClassName={`${contentClassName}`}
      dialogClassName={dialogClassName}
      onBackdropClick={onBackdropClick}
      backdropClassName={`backdrop-n-${openModals}`}
    >
      <Preloader minHeight={loadingMinHeight} isLoading={loading}>
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Preloader>
    </Modal>
  );
};

export default GenericModal;
