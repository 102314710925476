import { Editor } from "@tinymce/tinymce-react";
import { memo, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useInitTinyMCE } from "./useInitTinyMCE";
import { useDispatch } from "react-redux";
import { setSelectedTemplate } from "./pressReleasesSlice";

const EditorEmailBody = ({ tinymcepath, emailBody, setContentPR, id }) => {
    const [warningEmailBodySize, setWarningEmailBodySize] = useState(false);
    const editorRef = useRef(null);
    const initEditor = useInitTinyMCE({id: id, ref: editorRef});
    const dispatch = useDispatch();

    useEffect(() => {
        if (emailBody) {
            setWarningEmailBodySize(checkSizeStringInMb(emailBody));
        }
    }, [emailBody])

    const checkSizeStringInMb = (string) => {
        let size = new Blob([string]).size;
        if (size / (1024 * 1024) >= 1) {
            return true;
        }
        return false
    }

    const debouncedEmailBody = useDebouncedCallback((value) => {
        if (editorRef.current) {
            let content = editorRef.current.getContent();
            setWarningEmailBodySize(checkSizeStringInMb(content)); 
            setContentPR(prev => ({...prev, email_body: content}));
        }
    }, 600);

    return (<div className="editor mt-3">
        <label>
            <b>Email body</b>
            <i className="ms-2 bi bi-info-circle cursor-pointer" data-bs-toggle="modal" data-bs-target="#template" onClick={() => dispatch(setSelectedTemplate('press-release-email-body'))}></i>
            {warningEmailBodySize ? <span className='text-danger ps-2'><i className="bi bi-exclamation-triangle pr-2"></i>The email body exceeds the maximum size and emails may fail</span> : ''}
        </label>
        <Editor tinymceScriptSrc={tinymcepath}
            apiKey='cmnnd5qnqu6rfr85uibg46q9tzjq6gg9qa9hzgx4f6wo45go'
            onInit={(evt, editor) => editorRef.current = editor}
            onChange={debouncedEmailBody}
            onKeyDown={debouncedEmailBody}
            initialValue={emailBody}
            init={initEditor}
        />                          
    </div>)
};

export default memo(EditorEmailBody);