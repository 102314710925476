import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Nav from "../../components/Nav";
import { Loading } from "../loader/Loading";
import { CampaignsSectionManager } from "./CampaignsSectionManager";
import { UsersSectionManager } from "./UsersSectionManager";
import {
  updateCampaign,
  fetchCampaign,
  fetchNotionCampaigns,
} from "./campaignsManagerAPI";
import Modal from "../../components/Modal";
import { CampaignSelect } from "./CampaignSelect";
import { checkPermission } from "helpers/help";
import { useNavigate, useLocation } from "react-router";
import CampaignTimeline from "./CampaignTimeline";
import CampaignLinks from "./CampaignLinks";
import { CampaignStatuses } from "../campaign-statuses/CampaignStatuses";
import { useDispatch, useSelector } from "react-redux";
import { getCmCampaignStatusHistory } from "../campaign-statuses/campaignStatusesSlice";
import { setError } from "../error-handling/errorHandlingSlice";

export function CampaignsManager() {
  const dispatch = useDispatch();
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [isLoading, setLoading] = useState(false);
  const [card, setCard] = useState(null);
  const [remount, setRemount] = useState(0);
  const [campaign, setCampaign] = useState(null);
  const [notionCampaigns, setNotionCampaigns] = useState(null);
  const [isCampaignLoading, setCampaignLoading] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const dragItem = useRef();
  const dragOverItem = useRef();
  let navigate = useNavigate();
  let location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const editCardRef = useRef();
  const statusCardRef = useRef();
  const statusHistory = useSelector(getCmCampaignStatusHistory);

  useEffect(() => {
    document.title = "Campaigns Manager JournoResearch";
    const myModal = document.getElementById("edit_card");
    if (myModal) {
      myModal.addEventListener("hidden.bs.modal", onCloseModal);
    }
    return () => {
      setCard(null);
      source.cancel("axios request canceled");
      if (myModal) {
        myModal.removeEventListener("hidden.bs.modal", onCloseModal);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let id = urlSearchParams.get("id");
    if (id) {
      getCampaign(id);
      if (urlSearchParams.get("statuses")) {
        statusCardRef.current.click();
        return;
      }
      editCardRef.current.click();
    }
    return () => {
      source.cancel("axios request canceled");
    };
  }, [urlSearchParams.get("id")]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (campaign?.id && statusHistory?.length > 0) {
      let name = document.getElementById("edit_card");
      if (name) {
        name.querySelector("#exampleModalLabel").innerHTML =
          `Edit card ${campaign.site} (${campaign.invoice}) <span style="background-color: ${statusHistory[statusHistory.length - 1]?.status?.color}; padding: 1px 7px; font-size: 14px; border-radius: 5px;">${statusHistory[statusHistory.length - 1]?.status?.name ? statusHistory[statusHistory.length - 1].status.name : ""}</span>`;
      }
      if (
        campaign?.over_six_days &&
        statusHistory[statusHistory.length - 1]?.status?.id > 2
      ) {
        campaign.over_six_days = false;
      }
      if (
        campaign?.over_six_days &&
        statusHistory[statusHistory.length - 1]?.status?.id < 3
      ) {
        campaign.over_six_days = true;
      }
    }
  }, [statusHistory?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const dragStart = (e, position) => {
    dragOverItem.current = null;
    dragItem.current = position;
    let selectors = document.querySelectorAll(
      ".d-flex.flex-wrap.wrapper-user-cards",
    );
    selectors.forEach((selector) => {
      selector.classList.add("pointer-events-none");
    });
    e.currentTarget.parentElement.classList.remove("pointer-events-none");
    // console.log(dragItem.current);
    // console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    e.preventDefault();
    e.currentTarget.children[1].classList.add("drag-over");
    dragOverItem.current = position;
    //console.log(e.target.innerHTML, 'enter', dragOverItem.current);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.children[1].classList.remove("drag-over");
    dragOverItem.current = null;
  };

  const drop = (e) => {
    e.preventDefault();
    let selectorsUserCards = document.querySelectorAll(
      ".d-flex.flex-wrap.wrapper-user-cards",
    );
    selectorsUserCards.forEach((selector) => {
      selector.classList.remove("pointer-events-none");
      selector.classList.remove("drag-over");
    });
    if (
      dragOverItem.current &&
      dragItem.current.user_id !== dragOverItem.current.user_id
    ) {
      update(
        dragOverItem.current.user_id,
        dragItem.current.id,
        dragOverItem.current.user_name,
      );
    }
  };

  const update = async (user_id, campaign_id) => {
    try {
      setLoading(true);
      let from = { ...dragItem.current };
      const res = await updateCampaign(
        { assigned_user: user_id, updated_at: from.updated_at },
        campaign_id,
        source,
      );
      if (res) {
        let to = {
          ...res,
          user_name: dragOverItem.current.user_name,
          status_color: res.status_history?.color,
          status_name: res.status_history?.name,
        };
        setCard({ from: from, to: to });
      }
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  const getCampaign = async (id) => {
    try {
      setCampaignLoading(true);
      setSelectedCardId(id);
      const res = await fetchCampaign(id, source);
      if (!res) return;
      const resNotionCampaigns = await fetchNotionCampaigns(res.site, source);
      // console.log(resNotionCampaigns);
      let name = document.getElementById("edit_card");
      if (name) {
        name.querySelector("#exampleModalLabel").innerHTML =
          `Edit card ${res.site} (${res.invoice}) 
                <span style="background-color: ${res?.status_history?.color}; padding: 1px 7px; font-size: 14px; border-radius: 5px;">${res?.status_history?.name ? res.status_history.name : ""}</span>`;
      }
      urlSearchParams.delete("id");
      urlSearchParams.append("id", id);
      setNotionCampaigns(resNotionCampaigns);
      setCampaign(res);
      setCampaignLoading(false);
      navigate(`./?${urlSearchParams}`);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
      }
    }
  };

  const removeAssignedUser = async (campaign_id, updated_at) => {
    try {
      setLoading(true);
      await updateCampaign(
        { assigned_user: "", user_name: "", updated_at: updated_at },
        campaign_id,
        source,
      );
      setCard(null);
      setRemount(remount + 1);
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  const editCardModal = {
    title: "Edit card",
    target: "edit_card",
    size: "xl",
    add: async () => {
      try {
        setLoading(true);
        let data = {
          notion_campaigns: campaign.notion_campaigns,
          completed:
            campaign.completed &&
            checkPermission("role_pr", "fe-access-campaign-manager")
              ? 1
              : 0,
          manual_override: campaign.manual_override ? 1 : 0,
          min_ht_links: campaign.min_ht_links,
          min_links: campaign.min_links,
          notes: campaign.notes,
          updated_at: campaign.updated_at,
          completed_date: campaign.completed
            ? new Date(campaign.completed_date).toUTCString()
            : null,
        };
        if (campaign.manual_override) {
          data.ht_links = parseInt(campaign.ht_links);
          data.links = parseInt(campaign.links);
        }
        await updateCampaign(data, campaign.id, source);
        setRemount(remount + 1);
        setLoading(false);
      } catch (error) {
        if (error?.message !== "axios request canceled") {
          dispatch(setError(error?.message));
          setLoading(false);
        }
      }
    },
  };

  const onCloseModal = () => {
    let searchParams = new URLSearchParams(document.location.search);
    if (!searchParams.get("statuses")) {
      searchParams.delete("id");
      navigate(`./?${searchParams}`);
    }
  };

  const onClickRemoveMarkedForReview = async () => {
    let data = { submit_for_approval: null, updated_at: campaign.updated_at };
    try {
      await updateCampaign(data, campaign.id, source);
      setRemount(remount + 1);
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  const onClickStatuses = () => {
    let searchParams = new URLSearchParams(document.location.search);
    searchParams.delete("statuses");
    searchParams.append("statuses", 1);
    navigate(`./?${searchParams}`);
  };

  const removeMarkedForReview = () => {
    return (
      <div className="me-auto">
        {campaign?.submitted_date ? (
          <button
            className="btn btn-warning me-1"
            onClick={onClickRemoveMarkedForReview}
            data-bs-toggle="modal"
            data-bs-target="#edit_card"
          >
            Remove marked for review
          </button>
        ) : (
          ""
        )}
        <button
          className="btn btn-dark me-auto"
          data-bs-toggle="modal"
          data-bs-dismiss="modal"
          ref={statusCardRef}
          data-bs-target="#status_card"
          onClick={onClickStatuses}
        >
          Statuses
        </button>
      </div>
    );
  };

  return (
    <>
      <button
        type="button"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#edit_card"
        ref={editCardRef}
      ></button>
      {
        <CampaignStatuses
          id={urlSearchParams.get("id")}
          editCardRef={editCardRef}
          remount={remount}
          setRemount={setRemount}
        />
      }
      <Modal
        props={editCardModal}
        footer={removeMarkedForReview()}
        loading={isCampaignLoading}
      >
        {isCampaignLoading ? (
          <Loading fullScreen="true" />
        ) : (
          <div className="d-flex flex-wrap">
            <div
              style={{ flex: "1 1 30%", padding: "5px" }}
              className="wrapper-campaign-select"
            >
              <CampaignSelect
                campaign={campaign}
                notionCampaigns={notionCampaigns}
                setCampaign={setCampaign}
              />
            </div>
            <div
              style={{
                flex: "1 1 70%",
                overflow: "hidden",
                padding: "1.2rem",
                paddingBottom: "3rem",
              }}
              className="wrapper-campaign-links"
            >
              {campaign ? (
                <CampaignTimeline
                  campaign={campaign}
                  setCampaign={setCampaign}
                />
              ) : (
                ""
              )}
              {campaign?.campaign_links ? (
                <CampaignLinks
                  links={campaign.campaign_links}
                  site={campaign?.site}
                />
              ) : (
                "Links not found"
              )}
            </div>
          </div>
        )}
      </Modal>
      <Nav selectedListItem={{ name: "Campaigns Manager" }} />
      <div className="row" onClick={() => setSelectedCardId(0)}>
        {isLoading ? <Loading fullScreen="true" /> : ""}
        <div className="mb-2 col-xxl-6 col-xl-6 col-xs-12">
          <CampaignsSectionManager
            dragStart={dragStart}
            drop={drop}
            card={card}
            key={remount}
            remount={remount}
            setRemount={setRemount}
            selectedCardId={selectedCardId}
          />
        </div>
        <div className="mb-2 col-xxl-6 col-xl-6 col-xs-12">
          <UsersSectionManager
            dragEnter={dragEnter}
            dragStart={dragStart}
            dragLeave={dragLeave}
            drop={drop}
            card={card}
            removeAssignedUser={removeAssignedUser}
            key={remount}
            selectedCardId={selectedCardId}
          />
        </div>
      </div>
    </>
  );
}
