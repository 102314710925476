import { SortableDndWrapper } from "components/ui-elements/drag-and-drop/SortableDndWrapper";
import { CAMPAIGN_COLUMNS } from "constants/campaignWorkflow";
import { useSortableDndActions } from "hooks/drag-and-drop/useSortableDndActions";
import { useRequest } from "hooks/api/useRequest";
import Preloader from "components/ui-elements/preloader/Preloader";

import { GET_CAMPAIGN_WORKFLOW_EXECUTIVES } from "constants/api/campaign/apiCampaignEndPoints";
import renderCampaignWorkflowCard from "components/campaign/workflow/renderCampaignWorkflowCard";
import { useSortableDndContext } from "hooks/context/useSortableDndContext";

import {
  dragOver,
  dragEnd,
  beforeDragEnd,
  beforeDragOver,
} from "services/campaign/workflow/dragActionServices";
import CampaignModal from "components/campaign/modals/CampaignModal";
import { useGenericModal } from "hooks/modal/useGenericModal";
import { CAMPAIGN_MODAL } from "constants/campaign/modals";
import WorkFlowCardActions from "components/campaign/workflow/WorkFlowCardActions";

const CampaignWorkflow = () => {
  const { dispatch, items } = useSortableDndContext();
  const { getItemById, getContainerByItemId, getContainerById } =
    useSortableDndActions();

  const { loading, data } = useRequest({
    url: GET_CAMPAIGN_WORKFLOW_EXECUTIVES,
  });

  const { openModal, setProps } = useGenericModal({
    id: CAMPAIGN_MODAL,
  });

  const handleModalOpen = (item) => {
    setProps({ title: `Modal with item ID ${item}` });
    openModal();
  };

  const onDragOver = dragOver({
    dispatch,
    getContainerById,
    getItemById,
    getContainerByItemId,
  });
  const onDragEnd = dragEnd({
    dispatch,
    getContainerById,
    storeItems: items,
    getContainerByItemId,
  });
  const beforeDragEndAction = beforeDragEnd({
    getContainerById,
    dispatch,
    getItemById,
    getContainerByItemId,
  });
  const beforeDragOverAction = beforeDragOver({
    dispatch,
    getContainerById,
    getItemById,
    getContainerByItemId,
  });

  return (
    <Preloader isLoading={data === null} minHeight={"80vh"}>
      <SortableDndWrapper
        dragOver={onDragOver}
        dragEnd={onDragEnd}
        beforeDragEnd={beforeDragEndAction}
        beforeDragOver={beforeDragOverAction}
        loading={loading}
        serverItems={data}
        actions={(itemID) => (
          <WorkFlowCardActions
            itemId={itemID}
            eyeClickAction={handleModalOpen}
          />
        )}
        serverContainers={CAMPAIGN_COLUMNS}
        renderItemCard={renderCampaignWorkflowCard}
      />
      <CampaignModal />
    </Preloader>
  );
};

export default CampaignWorkflow;
