import { useEffect, useState } from "react";
import apiClient from "services/api";

export const useRequest = ({ url }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    getData().then();
  }, []);

  const refetchData = () => setRefetch((prev) => !prev);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(url);
      setData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    loading,
    data,
    refetchData,
  };
};
