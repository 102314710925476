import React, { useEffect, useState } from "react";
import { fetchMailsInQueue } from "../features/home/eventsAPI";
import { Loading } from "../features/loader/Loading";
import { formatDateMedium } from "helpers/help";
import { setError } from "../features/error-handling/errorHandlingSlice";
import { useDispatch } from "react-redux";
import { useCancelRequest } from "../hooks/api/useRequestCancel";
export function MailsInQueue() {
  const dispatch = useDispatch();
  const [mailsInQueue, setMailsQueue] = useState(0);
  const [lastRun, setLastRun] = useState("");

  const [isLoading, setLoading] = useState(false);

  const { source } = useCancelRequest();

  useEffect(() => {
    getMailsInQueue();
    return () => {
      setMailsQueue(0);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getMailsInQueue = async () => {
    try {
      setLoading(true);
      const obj = await fetchMailsInQueue(source);
      if (obj) {
        setMailsQueue(obj.mails_in_queue);
        setLastRun(formatDateMedium(obj.last_processed));
      }

      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  if (isLoading) {
    return <Loading fullScreen="true" />;
  }

  return (
    <div>
      {`${mailsInQueue} mails in queue`} - Campaigns processed at {lastRun}
    </div>
  );
}
