import { useDispatch } from "react-redux";
import { previewPressRelease } from "./pressReleasesSlice";
import { setError } from "../error-handling/errorHandlingSlice";
import Modal from "../../components/Modal";

export const SendPreviewEmail = ({emailTemplate, savePressRelease, user, pressReleaseId, setLoading}) => {
    const dispatch = useDispatch();
    
    const sendPreviewEmail = async () => {
        try {
            await savePressRelease();
            setLoading(true);
            await dispatch(previewPressRelease({ id: pressReleaseId, data: [user.email] })).unwrap();
            setLoading(false);
        }  catch (error) {
            setLoading(false);
            dispatch(setError(error?.message));
        }
    };

    const modalPropPreviewEmail = {
        title: 'Send preview email',
        target: 'preview_email',
        nameSaveButton: 'Send',
        add: sendPreviewEmail
    }

    return (<>
        <Modal props={modalPropPreviewEmail}>
            <p>The changes to the press release will be saved before the preview is send!</p>
            <p>Are you sure you want to send preview email to <b><i>{user.email}</i></b> ?</p>
        </Modal>    
        <button className={`ms-2 btn btn-primary  ${emailTemplate() ? '' : 'disabled'}`}  data-bs-toggle="modal" data-bs-target="#preview_email">Save & Send Preview</button>            
    </>
    )
}