import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchItemById,
  loading,
  menuItems,
  resetItems,
  updateItems,
} from "./listNavigationSlice";
import {
  deleteBlockedJournalists,
  fetchListItems,
  listItems,
  isImported,
  resetStatusImported,
  createJournalist,
  getUnsetJournalist,
  updateJournalist,
  deleteJournalist,
  resetListItems,
} from "./listsSlice";
import { useParams } from "react-router";
import { Loading } from "../loader/Loading";
import Nav from "../../components/Nav";
import axios from "axios";
import Modal from "../../components/Modal";
import ReactPaginate from "react-paginate";
import { Tooltip } from "../../components/Tooltip";
import Import from "../../components/Import";
import { setError } from "../error-handling/errorHandlingSlice";
import { formatDateMedium } from "helpers/help";

export function Lists() {
  const dispatch = useDispatch();
  const lists = useSelector(listItems);
  const localIsImported = useSelector(isImported);
  const localMenuItems = useSelector(menuItems);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  const [pageTotalCount, setPageTotalCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const per_page = 100;
  const initialJournalistValues = {
    first_name: "",
    last_name: "",
    outlet: "",
    email: "",
    phone: "",
    twitter: "",
    source: "Manual",
  };
  const [journalistValues, setJournalistValues] = useState(
    initialJournalistValues,
  );
  const [selectValue, setSelectValue] = useState("");
  const [errorRequiredField, setErrorRequiredField] = useState(null);
  const blackLists = ["Manual", "Unsubscribed", "Complaint", "Bounce"];
  const [selectedList, setSelectedList] = useState(null);
  const localLoadingMenuItems = useSelector(loading);

  useEffect(() => {
    document.title = "Lists - JournoResearch";
    if (!id) {
      getMenuItems(0, 0);
    }
    return () => {
      dispatch(resetItems());
      setSelectedList(null);
    };
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (localIsImported && id) {
      getListItems(id, 0);
    }
    return () => {
      setPageOffset(0);
      dispatch(resetStatusImported());
    };
  }, [localIsImported]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const menuItemClicked = localMenuItems?.children.find(
      (l) => l.id === parseInt(id),
    );
    if (id && (!menuItemClicked || menuItemClicked?.is_folder === 1)) {
      getMenuItems(id, 0);
    } else if (id && menuItemClicked && menuItemClicked.is_folder === 0) {
      setSelectedList({ ...menuItemClicked, path: localMenuItems?.path });
      getListItems(id, 0);
    }

    return () => {
      source.cancel("axios request canceled");
      setPageOffset(0);
      setSelectedList(null);
      dispatch(resetListItems());
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMenuItems = async (id, pageOffset) => {
    try {
      const responseItemById = await dispatch(
        fetchItemById({ id, typeOfList: "lists", source }),
      ).unwrap();
      let list = responseItemById?.children.find((c) => c.id === parseInt(id));
      if (list?.is_folder === 0) {
        setSelectedList({ ...list, path: responseItemById.path });
        await getListItems(id, pageOffset);
      } else {
        setLoading(false);
      }
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        setLoading(false);
        dispatch(setError(error?.message));
      }
    }
  };

  const getListItems = async (id, pageOffset) => {
    const page = pageOffset + 1;
    setLoading(true);
    try {
      const response = await dispatch(
        fetchListItems({ id, source, per_page, page }),
      ).unwrap();
      if (response) {
        setPageTotalCount(response.total);
        setPageCount(Math.ceil(response.total / per_page));
        dispatch(updateItems({ id: id, count: response.total }));
        setLoading(false);
      }
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        setLoading(false);
        dispatch(setError(error?.message));
      }
    }
  };

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
    getListItems(id, event.selected);
  };

  const addJournalistOnSave = async (values) => {
    setErrorRequiredField({
      email: values.email,
    });
    const canSave = formCanSave(errorRequiredField);
    if (canSave) {
      try {
        setLoading(true);
        await dispatch(createJournalist({ id, values })).unwrap();
        dispatch(updateItems({ id: id, count: pageTotalCount + 1 }));
        const offset = Math.ceil((pageTotalCount + 1) / per_page) - 1;
        setPageOffset(offset);
        await getListItems(id, offset);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setError(error?.message));
      }
    }
  };

  const formCanSave = (errorField) => {
    return errorField?.email?.length > 0;
  };

  const manualCreateJournalist = {
    title: "Create new journalist",
    target: "journalist",
    nameSaveButton: "Add",
    canSave: formCanSave(errorRequiredField),
    add: () => addJournalistOnSave(journalistValues),
  };

  const selectJournalist = {
    title: "Add journalist",
    target: "select_journalist",
    nameSaveButton: "Add",
    canSave: formCanSave(errorRequiredField),
    add: () => addJournalistOnSave(selectValue),
  };

  const deleteJournalistModalProp = {
    title: "Delete journalist",
    target: "delete_journalist",
    nameSaveButton: "DELETE",
    danger: true,
    add: async () => {
      try {
        setLoading(true);
        await dispatch(
          deleteJournalist({
            list_id: id,
            journalist_id: journalistValues.journalist_id,
          }),
        ).unwrap();
        dispatch(updateItems({ id: id, count: lists.length - 1 }));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setError(error?.message));
      }
    },
  };

  const editJournalist = {
    title: "Edit Journalist",
    target: "edit_journalist",
    add: async () => {
      try {
        setLoading(true);
        await dispatch(
          updateJournalist({
            id: journalistValues.journalist_id,
            body: journalistValues,
          }),
        ).unwrap();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error?.message);
      }
    },
  };

  const onChangeJournalistValues = (event) => {
    setErrorRequiredField({
      ...errorRequiredField,
      [event.target.name]: event.target.value,
    });
    setJournalistValues({
      ...journalistValues,
      [event.target.name]: event.target.value,
    });
  };

  const formatOptionLabel = ({ first_name, last_name, outlet }) => (
    <div style={{ display: "flex" }}>
      <div className="wrapper-outlet">
        {first_name}, {last_name}{" "}
        {outlet ? (
          <>
            <div className="ellipsis-select">({outlet}</div>)
          </>
        ) : (
          ""
        )}
      </div>
      <div style={{ marginLeft: "auto" }}>
        <i className="ms-1 bi bi-person-fill"></i>
      </div>
    </div>
  );

  const handleOnChangeSelect = (e) => {
    setErrorRequiredField({
      email: e.email,
    });
    const formatObj = {
      id: e.id,
      first_name: e.first_name,
      last_name: e.last_name,
      outlet: e.outlet,
      email: e.email,
      phone: e.phone,
      twitter: e.twitter,
      source: e.source,
      fromSelect: true,
    };
    setSelectValue(formatObj);
  };

  const onClickManualAddJournalist = () => {
    setErrorRequiredField(null);
    setJournalistValues(initialJournalistValues);
  };

  const onClickEditJournalist = (data) => {
    setJournalistValues({ ...data, source: "Manual" });
  };

  const allBlackListSet = (data) => {
    if (!data) {
      return "";
    }
    let bl = [];
    blackLists.forEach((blacklist) => {
      if (data[blacklist.toLowerCase()]) {
        bl.push(blacklist);
      }
    });
    return bl.join("<br/>");
  };

  const promiseOptions = async (inputValue) => {
    setSelectValue("");
    if (inputValue.length < 3) {
      return;
    }
    inputValue = inputValue.trim();
    inputValue = encodeURIComponent(inputValue);
    //Check if there are any previous pending requests
    if (typeof source != typeof undefined) {
      source.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    source = cancelToken.source();
    try {
      const res = await dispatch(
        getUnsetJournalist({ id, inputValue, source }),
      ).unwrap();
      if (res) {
        return res.map((r) => ({
          ...r,
          label: r.first_name + r.last_name + r.email + r.outlet,
        }));
      }
    } catch (e) {
      if (e?.message !== "Operation canceled due to new request.") {
        dispatch(setError(e?.message));
      }
    }
  };

  const removeBlockedJournalists = async (id) => {
    if (window.confirm("Are you sure you want to delete blocked emails?")) {
      try {
        setLoading(true);
        const res = await dispatch(
          deleteBlockedJournalists({ list_id: id }),
        ).unwrap();
        dispatch(updateItems({ id: id, count: res?.count_items }));
        setPageOffset(0);
        await getListItems(id, 0);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setError(error?.message));
      }
    }
  };

  return (
    <>
      {isLoading || localLoadingMenuItems ? <Loading fullScreen="true" /> : ""}
      {localMenuItems && localMenuItems.is_folder && !selectedList ? (
        <div className="d-flex justify-content-end mt-4">
          <div className="d-flex flex-column">
            <h3>Lists Page</h3>
            <h6>
              Folder Name: <span>{localMenuItems?.name}</span>
            </h6>
            <h6>
              Creation Time:{" "}
              <span>{formatDateMedium(localMenuItems?.created_at)}</span>
            </h6>
          </div>
        </div>
      ) : (
        ""
      )}
      {id && lists && selectedList && !isLoading ? (
        <>
          <Nav selectedListItem={selectedList} typeOfList="lists">
            {selectedList ? (
              <>
                <button
                  className="btn btn-dark me-2"
                  onClick={() => removeBlockedJournalists(id)}
                >
                  Delete blocked journalists
                </button>
                <li className={`nav-item pe-2`}>
                  <Import name="Import List" id={id} />
                </li>
                <button
                  className="btn btn-primary me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#journalist"
                  onClick={() => onClickManualAddJournalist()}
                >
                  New journalist
                </button>
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#select_journalist"
                  onClick={() => setSelectValue("")}
                >
                  Add journalist
                </button>
                <Modal props={manualCreateJournalist}>
                  <div className="row">
                    <div className="col-md-6 col-xs-12 mt-3">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        onChange={onChangeJournalistValues}
                        value={journalistValues.first_name || ""}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12 mt-3">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        onChange={onChangeJournalistValues}
                        value={journalistValues.last_name || ""}
                      />
                    </div>
                  </div>
                  <label className="mt-3">Outlet</label>
                  <input
                    type="text"
                    className="form-control"
                    name="outlet"
                    onChange={onChangeJournalistValues}
                    value={journalistValues.outlet || ""}
                  />
                  <label className="mt-3">Email</label>
                  <input
                    type="text"
                    className={`form-control ${errorRequiredField?.email === "" ? "is-invalid" : ""}`}
                    name="email"
                    onChange={onChangeJournalistValues}
                    value={journalistValues.email}
                  />
                  <div className="row">
                    <div className="col-md-6 col-xs-12 mt-3">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        onChange={onChangeJournalistValues}
                        value={journalistValues.phone || ""}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12 mt-3">
                      <label>Twitter</label>
                      <input
                        type="text"
                        className="form-control"
                        name="twitter"
                        onChange={onChangeJournalistValues}
                        value={journalistValues.twitter || ""}
                      />
                    </div>
                  </div>
                </Modal>
                <Modal props={editJournalist}>
                  <div className="row">
                    <div className="col-md-6 col-xs-12 mt-3">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        onChange={onChangeJournalistValues}
                        value={journalistValues.first_name || ""}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12 mt-3">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        onChange={onChangeJournalistValues}
                        value={journalistValues.last_name || ""}
                      />
                    </div>
                  </div>
                  <label className="mt-3">Outlet</label>
                  <input
                    type="text"
                    className="form-control"
                    name="outlet"
                    onChange={onChangeJournalistValues}
                    value={journalistValues.outlet || ""}
                  />
                  <label className="mt-3">Email</label>
                  <input
                    type="text"
                    className={`form-control ${errorRequiredField?.email === "" ? "is-invalid" : ""}`}
                    name="email"
                    onChange={onChangeJournalistValues}
                    value={journalistValues.email}
                  />
                  <div className="row">
                    <div className="col-md-6 col-xs-12 mt-3">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        onChange={onChangeJournalistValues}
                        value={journalistValues.phone || ""}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12 mt-3">
                      <label>Twitter</label>
                      <input
                        type="text"
                        className="form-control"
                        name="twitter"
                        onChange={onChangeJournalistValues}
                        value={journalistValues.twitter || ""}
                      />
                    </div>
                  </div>
                </Modal>
                <Modal props={selectJournalist}>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={promiseOptions}
                    placeholder={"Type to search"}
                    onChange={handleOnChangeSelect}
                    formatOptionLabel={formatOptionLabel}
                    onFocus={() => setSelectValue("")}
                    value={selectValue}
                  />
                </Modal>
                <Modal props={deleteJournalistModalProp}>
                  Are you sure you want to delete?
                </Modal>
              </>
            ) : (
              ""
            )}
          </Nav>
          {lists && lists.length > 0 ? (
            <div className="row">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col" className="outlet-col">
                        Outlet
                      </th>
                      <th scope="col">Source</th>
                      <th className="text-center" scope="col">
                        BlackListed
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {lists.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {data.first_name}
                          {data.last_name ? ", " + data.last_name : ""}
                        </td>
                        <td>{data.email}</td>
                        <td className="outlet-col">
                          {data?.outlet?.length > 24 ? (
                            <Tooltip text={data.outlet}>
                              <div>{data.outlet}</div>
                            </Tooltip>
                          ) : (
                            <div>{data.outlet}</div>
                          )}
                        </td>
                        <td>{data.source}</td>
                        <td className="text-center">
                          {data?.blocked_email ? (
                            <Tooltip text={allBlackListSet(data)}>
                              <i className="bi bi-circle-fill"></i>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="icons-add-delete">
                          <i
                            className="bi bi-pencil-fill text-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_journalist"
                            onClick={() => onClickEditJournalist(data)}
                          ></i>
                          <i
                            className="bi bi-person-x-fill text-danger ps-3"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_journalist"
                            onClick={() => setJournalistValues(data)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {id && lists?.length > 0 && !isLoading ? (
        <>
          <div className="d-none d-sm-flex nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
          <div className="d-flex d-sm-none nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
