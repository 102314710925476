export const multipleContainers = ({ arrayItems, rawContainers, items }) => {
  const containers = rawContainers.map((container) =>
    container?.id ? container.id : container,
  );

  const getContainerItems = (containerId, items = [], getter = "column") => {
    return arrayItems.filter((item) => item[getter] === containerId);
  };

  const getItemByID = (id) => {
    return arrayItems.find((item) => item.id === id);
  };

  const getContainer = (id) => {
    return rawContainers.find((container) => container.id === id);
  };

  const findContainer = (id) => {
    if (id in items) return id;
    return Object.keys(items).find((key) => items[key].includes(id));
  };

  const getIndex = (id) => {
    const container = findContainer(id);
    if (!container) {
      return -1;
    }
    return items[container].indexOf(id);
  };

  return {
    containers,
    getContainerItems,
    getContainer,
    findContainer,
    getIndex,
    getItemByID,
  };
};
