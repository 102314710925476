export const SORTABLE_DND_ACTIONS = {
  CHANGE_INDEX: "CHANGE_INDEX",
  INITIALISE: "INITIALISE",
  CHANGE_POSITION: "CHANGE_POSITION",
  RE_FETCH: "RE_FETCH",
  SET_LOADING: "SET_LOADING",
  SET_ITEMS: "SET_ITEMS",
  UPDATE_ITEM_PROP: "UPDATE_ITEM_PROP",
  UPDATE_CONTAINER_PROP: "UPDATE_CONTAINER_PROP",
  DISABLE_CONTAINERS: "DISABLE_CONTAINERS",
  ENABLE_ALL_CONTAINERS: "ENABLE_ALL_CONTAINERS",
};
