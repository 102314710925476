import { createContext, useReducer } from "react";
import SortableDndReducer from "store/reducer/SortableDndReducer";

const initialState = {
  dispatch: () => {},
  items: [],
  groupedItems: {},
  loading: false,
  containers: [],
  containerIds: [],
  dataFetch: "43565vv@$",
  initialised: false,
};

export const SortableDndContext = createContext({
  ...initialState,
});

export const SortableDndProvider = ({ children }) => {
  const [stateForm, dispatchStateForm] = useReducer(
    SortableDndReducer,
    initialState,
  );

  return (
    <SortableDndContext.Provider
      value={{
        ...stateForm,
        dispatch: dispatchStateForm,
      }}
    >
      {children}
    </SortableDndContext.Provider>
  );
};
export default SortableDndProvider;
