import { arrayMove } from "@dnd-kit/sortable";

export const useSortableDragAndDropMultiContainerActions = ({
  setItems,
  clonedItems,
  setClonedItems,
  setActiveId,
  items,
  findContainer,
  recentlyMovedToNewContainer,
  beforeDragStart,
  dragEnd,
  dragOver,
  beforeDragEnd,
  beforeDragOver,
}) => {
  const onDragStart = ({ active }) => {
    if (beforeDragStart) {
      beforeDragStart(active.id).then((canDrag) => {
        if (!canDrag) return;
        setActiveId(active.id);
        setClonedItems(items);
      });
    } else {
      setActiveId(active.id);
      setClonedItems(items);
    }
  };

  const revertItems = () => {
    if (clonedItems) {
      setItems(clonedItems);
    }
    setActiveId(null);
    setClonedItems(null);
  };

  const onDragOver = async ({ active, over }) => {
    const overId = over?.id;

    const overContainer = findContainer(overId);
    const activeContainer = findContainer(active.id);

    if (!overContainer || !activeContainer) {
      return;
    }

    if (activeContainer !== overContainer) {
      setItems((items) => {
        const activeItems = items[activeContainer];
        const overItems = items[overContainer];
        const overIndex = overItems.indexOf(overId);
        const activeIndex = activeItems.indexOf(active.id);
        const clone = JSON.parse(JSON.stringify(items));

        let newIndex;

        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        recentlyMovedToNewContainer.current = true;

        const newItems = {
          ...items,
          [activeContainer]: items[activeContainer].filter(
            (item) => item !== active.id,
          ),
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(
              newIndex,
              items[overContainer].length,
            ),
          ],
        };

        if (beforeDragOver) {
          const canDrag = beforeDragOver({
            activeContainer,
            overContainer,
            activeIndex,
            overIndex,
            newIndex,
            activeId: active.id,
            overId,
            items,
            newItems,
            revertItems,
          });

          if (!canDrag) return clone;
        }

        if (dragOver)
          dragOver({
            activeContainer,
            overContainer,
            activeIndex,
            overIndex,
            newIndex,
            activeId: active.id,
            overId,
            items,
            newItems,
            revertItems,
          });

        return newItems;
      });
    }
  };

  const onDragEnd = async ({ active, over }) => {
    const activeContainer = findContainer(active.id);

    if (!activeContainer) {
      setActiveId(null);
      return;
    }

    const overId = over?.id;

    if (overId == null) {
      setActiveId(null);
      return;
    }

    const overContainer = findContainer(overId);

    let newItems = items;

    if (overContainer) {
      const activeIndex = items[activeContainer].indexOf(active.id);
      const overIndex = items[overContainer].indexOf(overId);

      if (activeIndex !== overIndex) {
        if (beforeDragEnd) {
          const canDrag = beforeDragEnd({
            activeContainer,
            overContainer,
            activeIndex,
            overIndex,
            activeId: active.id,
            overId,
            items,
            clonedItems,
            revertItems,
          });

          if (!canDrag) setItems(clonedItems);
          if (canDrag) {
            setItems((items) => {
              newItems = {
                ...items,
                [overContainer]: arrayMove(
                  items[overContainer],
                  activeIndex,
                  overIndex,
                ),
              };
              return newItems;
            });
          }
        } else {
          setItems((items) => {
            newItems = {
              ...items,
              [overContainer]: arrayMove(
                items[overContainer],
                activeIndex,
                overIndex,
              ),
            };
            return newItems;
          });
        }
      }
    }
    if (dragEnd)
      dragEnd({
        activeContainer,
        overContainer,
        activeId: active.id,
        overId,
        items,
        revertItems,
        newItems,
      });

    setActiveId(null);
  };

  return {
    onDragStart,
    onDragOver,
    onDragEnd,
  };
};
