import { forwardRef } from "react";
import classNames from "classnames";

import styles from "components/ui-elements/drag-and-drop/components/Container/Container.module.scss";

export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        {...props}
        ref={ref}
        style={{
          ...style,
          "--columns": 4,
        }}
        className={classNames(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder && styles.placeholder,
          scrollable && styles.scrollable,
          shadow && styles.shadow,
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {label ? (
          <div className={classNames(styles.Header, "py-3")}>{label}</div>
        ) : null}
        <ul
          style={{
            height: "100%",
          }}
        >
          {children}
        </ul>
      </div>
    );
  },
);
