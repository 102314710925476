export const prepareNewStoreData = (object, arrayData) => {
  let updatedArray = [];
  arrayData.forEach((item) => {
    for (let key in object) {
      if (object[key].includes(item?.id)) {
        item.column = key;
        item.index = object[key].indexOf(item?.id);
        break;
      }
    }
    updatedArray.push(item);
  });
  return updatedArray;
};
