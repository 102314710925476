import { useDispatch } from "react-redux";
import { setPressRelease } from "./pressReleasesSlice";
import { memo } from "react";

const RelentlessModeSwitch = ({pressRelease}) => {
    const dispatch = useDispatch();
    const NR_OF_EMAILS = 3;

    const onChangeRelentlessMode = (relentlessMode) => {
        if (relentlessMode && pressRelease.email_subjects?.length <= NR_OF_EMAILS) {
            dispatch(setPressRelease({...pressRelease, relentless_mode: relentlessMode, email_subjects: [...pressRelease.email_subjects, ...Array(NR_OF_EMAILS - pressRelease.email_subjects.length).fill('')]}));
        } else {
            dispatch(setPressRelease({...pressRelease, relentless_mode: relentlessMode, email_subjects: [pressRelease.email_subjects ? pressRelease.email_subjects[0] : '']}));
        }
    }

    return (
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="relentless_mode" checked={pressRelease.relentless_mode} onChange={(e) => onChangeRelentlessMode(!pressRelease.relentless_mode)} value={pressRelease.relentless_mode || false}/>
            <label className="form-check-label" htmlFor="relentless_mode">Relentless mode</label>
        </div>
    )
}

export default memo(RelentlessModeSwitch);