import React, { useState, useEffect } from 'react';
import axios from 'axios';

export function SourceCol({setSourceWh, sourceWh}) {
    const [name, setName] = useState('');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        return (() => {
            source.cancel('axios request canceled');
            setSourceWh([]);
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const createNewEntry = () => {
        if (name) {
            const foundName = sourceWh.find(e => (e.name.toLowerCase().trim() === name.toLowerCase().trim()));
            if (!foundName) {
                setSourceWh([{name: name}, ...sourceWh]);
            }
        }
        setName('');
    };

    const deleteItem = (idx) => {
        let d = sourceWh.filter((v, index) => idx !== index);
        setSourceWh(d);
    }

    return (<>
        <div className="table-modal-data-warehouse mt-1" style={{width: '100%'}}>
            <div className='mt-1'>Url</div>
            <table className="table table-striped">
                <tbody>
                    <tr key='add'>
                        <td><input className="form-control" id="name" onChange={(e) => setName(e.target.value)} value={name}></input></td>
                        <td className="text-center add-source-item"><i className={`bi bi-plus-lg text-primary ps-1 pe-1 ${name ? '' : 'disabled'}`} onClick={() => createNewEntry()}/></td>
                    </tr>
                    {sourceWh && sourceWh.map((sourceWh, index) =>
                        <tr key={index}>
                            <td><a href={sourceWh.name} target='_blank' rel="noreferrer"><div>{sourceWh.name}</div></a></td>
                            <td className="text-center"><div><i className="bi bi-dash text-danger ps-1 pe-1" onClick={() => deleteItem(index)}/></div></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </>);
}
