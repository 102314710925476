import React, { useEffect, useState } from "react";
import apiClient from "../services/api";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { Loading } from "../features/loader/Loading";
import CampaignBacklogLineChart from "./CampaignBacklogLineChart";
import CampaignDiffBarChart from "./CampaignDiffBarChart";

export default function TeamSuccessfulCampaigns() {
  const [loading, setLoading] = useState(false);
  const [chartsData, setChartsData] = useState({
    successfulCampaigns: [],
    revenue: [],
    breakEven: [],
    invoiceRevenue: [],
    campaignsBacklog: [],
    campaignsDiff: [],
    successfulCampaignsPerExecutive: [],
    successfulCampaignsPerTotalTeam: [],
  });
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  useEffect(() => {
    getTeamSuccessfulCampaign();
    return () => {
      source.cancel("axios request canceled");
      setLoading(false);
      setChartsData({
        successfulCampaigns: [],
        revenue: [],
        breakEven: [],
        invoiceRevenue: [],
        campaignsBacklog: [],
        campaignsDiff: [],
        successfulCampaignsPerExecutive: [],
        successfulCampaignsPerTotalTeam: [],
        teamSize: [],
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTeamSuccessfulCampaign = async () => {
    try {
      setLoading(true);
      const res = await apiClient.get(
        "/api/campaigns-manager/team-successful-campaigns",
        { cancelToken: source.token }
      );
      setChartsData({
        successfulCampaigns: res.data.successfulCampaigns.successfulCampaigns,
        revenue: res.data.revenue.data,
        breakEven: res.data.revenue.break_even,
        invoiceRevenue: res.data.revenue.invoice_revenue,
        campaignsBacklog: res.data.campaigns_backlog,
        campaignsDiff: res.data.campaigns_diff,
        successfulCampaignsPerExecutive:
          res.data.successfulCampaigns.successfulCampaignsPerExecutive,
        successfulCampaignsPerTotalTeam:
          res.data.successfulCampaigns.successfulCampaignsPerTotalTeam,
        teamSize: res.data.teamSize,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const options = {
    interaction: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
      },
      x: {
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (val) {
            return formatTime(this.getLabelForValue(val));
          },
        },
      },
    },
  };

  const optionsTwoAxis = {
    interaction: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      x: {
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (val) {
            return formatTime(this.getLabelForValue(val));
          },
        },
      },
    },
  };

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formatTime = (time, resolution) => {
    const data = new Date(time);
    return month[data.getMonth()] + " " + data.getFullYear();
  };

  const setLineChartData = (
    chartData,
    label,
    color,
    borderWidth = 2,
    pointRadius = 1,
    borderDash = [],
    hoverPointRadius = 3,
    yAxisID = "y"
  ) => {
    return {
      label: label,
      borderColor: color,
      borderWidth: borderWidth,
      pointRadius: pointRadius,
      hoverPointRadius: hoverPointRadius,
      borderDash: borderDash,
      fill: false,
      data: chartData?.map((s) => ({ x: formatDate(s.x), y: s.y })),
      yAxisID: yAxisID,
    };
  };

  const formatDate = (date) => {
    const dateFormat = new Date(date);
    return new Intl.DateTimeFormat("en-GB", {
      dateStyle: "medium",
      timeStyle: "medium",
    }).format(dateFormat);
  };

  return loading ? (
    <Loading fullScreen="true" />
  ) : (
    <>
      <div
        className="wrapper-sends-stats"
        style={{
          width: "1000px",
          display: "flex",
          justifyContent: "center",
          flex: "1 1",
          marginBottom: "2rem",
        }}
      >
        <Line
          type="line"
          data={{
            datasets: [
              setLineChartData(
                chartsData.successfulCampaigns,
                "Successful Campaigns",
                "blue"
              ),
            ],
          }}
          options={options}
          style={{ width: "100%", height: "370px" }}
          height={370}
        />
      </div>
      <div
        className="wrapper-sends-stats"
        style={{
          width: "1000px",
          display: "flex",
          justifyContent: "center",
          flex: "1 1",
          marginBottom: "2rem",
        }}
      >
        <Line
          type="line"
          data={{
            datasets: [
              setLineChartData(
                chartsData.successfulCampaignsPerExecutive,
                "Campaigns per executive team member",
                "orange",
                2,
                0,
                [],
                0,
                "y1"
              ),
              setLineChartData(
                chartsData.successfulCampaignsPerTotalTeam,
                "Campaigns per full team member",
                "silver",
                2,
                0,
                [],
                0,
                "y1"
              ),
              setLineChartData(
                chartsData.teamSize?.executiveTeamSize,
                "SI team size",
                "purple",
                2,
                0,
                [5, 5],
                0,
                "y"
              ),
              setLineChartData(
                chartsData.teamSize?.fullTeamSize,
                "Full team size",
                "magenta",
                2,
                0,
                [5, 5],
                0,
                "y"
              ),
            ],
          }}
          options={optionsTwoAxis}
          style={{ width: "100%", height: "370px" }}
          height={370}
        />
      </div>
      <div
        className="wrapper-sends-stats"
        style={{
          width: "1000px",
          display: "flex",
          justifyContent: "center",
          flex: "1 1",
          marginBottom: "2rem",
        }}
      >
        <Line
          type="line"
          data={{
            datasets: [
              setLineChartData(chartsData.revenue, "Campaigns revenue", "blue"),
              setLineChartData(
                chartsData.breakEven,
                "Company expenses",
                "orange",
                2,
                0,
                [5, 5],
                0
              ),
              setLineChartData(
                chartsData.invoiceRevenue,
                "Invoice revenue",
                "silver",
                2,
                0,
                [5, 5],
                0
              ),
            ],
          }}
          options={options}
          style={{ width: "100%", height: "370px" }}
          height={370}
        />
      </div>

      <CampaignBacklogLineChart
        data={chartsData.campaignsBacklog}
        setLineChartData={setLineChartData}
        options={options}
      />

      <CampaignDiffBarChart data={chartsData.campaignsDiff} />
    </>
  );
}
