import { getAllImages, storeFiles } from "./pressReleasesAPI";

export const useInitTinyMCE = ({ id, ref, height }) => {
  const getImagesOnDialog = async (ref, callback) => {
    let img = [];
    const dialog = ref.current.windowManager.open(dialogConfig(callback, []));
    dialog.block("Loading");
    img = await getAllImages(id);
    dialog.unblock();
    dialog.close();
    ref.current.windowManager.open(dialogConfig(callback, img));
  };

  const dialogConfig = (callback, images) => ({
    title: "Image Manager",
    body: {
      type: "panel", // The root body type - a Panel or TabPanel
      items: images
        ? images.map((i, idx) => ({
            type: "htmlpanel",
            html: `<div class="gallery-div">
                            <label class="label-image">
                                <input style="display: none;" type="radio" name="image"  value="${i.path}" data-name="${i.name}"/>
                                <span><img class="image-media" src="${i.path}" width="40" height="40"/>${i.name}</span>
                            </label>
                        </div>`,
          }))
        : [],
    },
    buttons: [
      {
        type: "cancel",
        name: "closeButton",
        text: "Cancel",
      },
      {
        type: "submit",
        name: "submitButton",
        text: "Import",
        primary: true,
      },
    ],
    onSubmit: function (api) {
      let selectedImage = document.querySelectorAll(
        "input[name=image]:checked"
      )[0]?.value;
      let selectedImageTitle = document
        .querySelectorAll("input[name=image]:checked")[0]
        ?.getAttribute("data-name");
      if (selectedImage && selectedImageTitle) {
        callback(selectedImage, { title: selectedImageTitle });
      }
      api.close();
    },
  });

  return {
    elementpath: false,
    branding: false,
    height: height ? height : 700,
    plugins:
      "print preview powerpaste casechange importcss searchreplace autolink save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap emoticons advtable export",
    mobile: {
      plugins:
        "print preview powerpaste casechange importcss searchreplace autolink save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap emoticons advtable",
    },
    menubar: "file edit view insert format tools table tc help",
    toolbar:
      "undo redo | bold italic underline strikethrough | mybutton | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image pageembed template link anchor codesample | a11ycheck ltr rtl",
    image_advtab: true,
    importcss_append: true,
    template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
    template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
    image_caption: true,
    quickbars_selection_toolbar:
      "bold italic | quicklink h2 h3 blockquote quicktable",
    noneditable_noneditable_class: "mceNonEditable",
    toolbar_mode: "sliding",
    spellchecker_ignore_list: ["Ephox", "Moxiecode"],
    contextmenu: "link image imagetools table configurepermanentpen",
    a11y_advanced_options: true,
    // mentions_selector: '.mymention',
    // mentions_item_type: 'profile',
    powerpaste_word_import: "prompt",
    powerpaste_html_import: "prompt",
    images_upload_handler: async function (blobInfo, success, failure) {
      try {
        const formData = new FormData();
        formData.append("file[]", blobInfo.blob(), blobInfo.filename());
        const res = await storeFiles(formData, id);
        success(res["path"]);
      } catch (e) {
        failure(e?.message);
      }
    },
    image_title: true,
    file_picker_types: "image",
    //default_link_target: '_blank',
    extended_valid_elements: "a[href|target=_blank|rel:follow]",
    setup: function (editor) {
      editor.ui.registry.addMenuButton("mybutton", {
        text: "Placeholder text",
        fetch: function (callback) {
          var items = [
            {
              type: "menuitem",
              text: "First Name",
              icon: "user",
              onAction: function () {
                editor.insertContent("&nbsp;||FNAME||");
              },
            },
            {
              type: "menuitem",
              text: "Last Name",
              icon: "user",
              onAction: function () {
                editor.insertContent("&nbsp;||LNAME||");
              },
            },
          ];
          callback(items);
        },
      });
    },
    file_picker_callback: async function (callback, value, meta) {
      if (ref.current) {
        getImagesOnDialog(ref, callback);
      }
    },
  };
};
