import { useEffect } from "react";
import Home from "features/home/Home";
import Login from "features/auth/Login";
import "App.scss";
import { useDispatch, useSelector } from "react-redux";
import { _getUser, getUser } from "features/auth/loginSlice";
import Layout from "components/Layout";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Lists } from "features/lists/Lists";
import { PressReleases } from "features/press-releases/PressReleases";
import { Campaigns } from "features/campaigns/Campaigns";
import NotFound from "components/NotFound";
import { BlockedEmails } from "features/blocked-emails/BlockedEmails";
import { PrReview } from "features/pr-review/PrReview";
import { WorkFlow } from "features/work-flow/WorkFlow";
import { CampaignsManager } from "features/campaigns-manager/CampaignsManager";
import SidebarLeft from "components/SidebarLeft";
import Notifications from "features/notifications/Notifications";
import CampaignIdeas from "features/campaign-ideas/CampaignIdeas";
import { MailsStatistics } from "features/mails-statistics/MailsStatistics";
import { checkPermission } from "helpers/help";
import ItemType from "features/approval-items/ItemType";
import DataWarehouse from "features/data-warehouse/DataWarehouse";
import * as Sentry from "@sentry/react";
import { Loading } from "features/loader/Loading";
import apiClient from "services/api";
import CampaignWorkflow from "pages/CampaignWorkflow";
import SortableDndProvider from "store/context/SortableDndContext";
import ModalProvider from "store/context/ModalContext";

function RequireAuth({ children }) {
  return children;
}

function Logout() {
  useEffect(() => {
    logoutUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const logoutUser = async () => {
    await apiClient.post("/logout");
    window.location.href = "/login";
  };

  return <Loading center="true" />;
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const _user = useSelector(_getUser);
  return (
    <ModalProvider>
      <BrowserRouter>
        <SentryRoutes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route exact path="/not-found" element={<NotFound />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                {_user ? <Layout /> : <Loading center="true" />}
              </RequireAuth>
            }
          >
            <Route exact path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route
              path="/lists"
              element={
                <>
                  <SidebarLeft typeOfList="lists" />
                  <Lists />
                </>
              }
            ></Route>
            <Route
              path="/lists/:id"
              element={
                <>
                  <SidebarLeft typeOfList="lists" />
                  <Lists />
                </>
              }
            ></Route>
            <Route
              path="/press-releases"
              element={
                <>
                  <SidebarLeft typeOfList="press-releases" />
                  <PressReleases />
                </>
              }
            ></Route>
            <Route
              path="/press-releases/:id"
              element={
                <>
                  <SidebarLeft typeOfList="press-releases" />
                  <PressReleases />
                </>
              }
            ></Route>
            <Route
              path="/campaigns"
              element={
                <>
                  <SidebarLeft typeOfList="campaigns" />
                  <Campaigns />
                </>
              }
            ></Route>
            <Route
              path="/campaigns/:id"
              element={
                <>
                  <SidebarLeft typeOfList="campaigns" />
                  <Campaigns />
                </>
              }
            ></Route>
            <Route
              path="/campaigns/work-flow"
              element={
                <SortableDndProvider>
                  <CampaignWorkflow />
                </SortableDndProvider>
              }
            ></Route>
            <Route path="/blocked-emails" element={<BlockedEmails />}></Route>
            <Route path="/pr-review" element={<PrReview />}></Route>
            <Route path="/work-flow" element={<WorkFlow />}></Route>
            <Route path="/work-flow/:id/:type" element={<WorkFlow />}></Route>
            {checkPermission("role_pr", "fe-access-campaign-manager", _user) ? (
              <>
                <Route
                  path="/campaigns-manager"
                  element={<CampaignsManager />}
                ></Route>
              </>
            ) : (
              <></>
            )}
            <Route path="/notifications" element={<Notifications />}></Route>
            <Route path="/idea-machine" element={<CampaignIdeas />}></Route>
            <Route
              path="/mails-statistics"
              element={<MailsStatistics />}
            ></Route>
            <Route
              path="/press-release-approval"
              element={<ItemType type="Press Release" />}
            ></Route>
            <Route
              path="/data-approval"
              element={<ItemType type="Data" />}
            ></Route>
            <Route path="/data-warehouse" element={<DataWarehouse />}></Route>
          </Route>
        </SentryRoutes>
      </BrowserRouter>
    </ModalProvider>
  );
}
export default Sentry.withProfiler(App);
