import React, { useEffect, useState, useRef } from "react";
import { Loading } from "../features/loader/Loading";
import {
  getRecordById,
  recordPressRelease,
} from "../features/press-releases/pressReleasesSlice";
import { formatDateMedium } from "helpers/help";
import Modal from "./Modal";
import PreviewEmailContent from "./PreviewEmailContent";
import { useDispatch, useSelector } from "react-redux";
import { _getUser } from "../features/auth/loginSlice";
import { setError } from "../features/error-handling/errorHandlingSlice";

export default function AutoSave(props) {
  const dispatch = useDispatch();
  const user = useSelector(_getUser);
  const [isChanged, setIsChanged] = useState(false);
  const [initialProps, setInitialProps] = useState(null);
  const restore_modal_ref = useRef(null);
  const [emailData, setEmailData] = useState(null);
  const [previewDisplay, setPreviewDisplay] = useState("smartphone");

  useEffect(() => {
    if (isChanged && initialProps) {
      if (diffBetweenLastRecordAndCurrentValues(initialProps, props)) {
        savePrRecord(props);
      }
    }
    setInitialProps(props);
    setIsChanged(false);
  }, [isChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !props.isRestored &&
      props.latestRecord &&
      new Date(props.latestRecord.updated_at).getTime() >
        new Date(props.prUpdatedAt).getTime()
    ) {
      openModal();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props) {
      setInitialProps(props);
    }
    const interval = setInterval(() => setIsChanged(true), 30000);
    return () => {
      clearInterval(interval);
      setInitialProps(null);
      setIsChanged(false);
    };
  }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const savePrRecord = async (props) => {
    await recordPressRelease({
      user_id: user.id,
      parent_id: props.id,
      email_subjects: props.emailSubjects,
      email_body: props.emailBody,
      relentless_mode: props.relentlessMode,
      signature: props.signature,
    });
    setIsChanged(false);
  };

  const restoreModal = {
    title: "Restore the latest version",
    target: "restore_modal",
    nameSaveButton: "Restore",
    add: () => props.restoreData(props.latestRecord.id),
  };

  const allRestoreVersion = {
    title: "Restore the latest version",
    target: "all_restore",
    disableFooter: true,
  };

  const previewDataModal = {
    title: "Preview Email",
    target: "preview_data",
    disableFooter: true,
    size: previewDisplay === "tablet" ? "lg" : "",
  };

  const openModal = () => {
    restore_modal_ref.current.click();
  };

  const onClickPreviewData = async (id) => {
    try {
      setEmailData(null);
      const recordPr = await getRecordById(id);
      setEmailData(recordPr);
    } catch (error) {
      dispatch(setError(error?.message));
    }
  };

  const diffBetweenLastRecordAndCurrentValues = (initialProps, props) => {
    let _emailSubjects = initialProps.emailSubjects;
    if (_emailSubjects === null) {
      _emailSubjects = [""];
    }
    if (
      JSON.stringify(_emailSubjects) !== JSON.stringify(props.emailSubjects) ||
      JSON.stringify(initialProps.emailBody) !==
        JSON.stringify(props.emailBody) ||
      JSON.stringify(initialProps.relentlessMode) !==
        JSON.stringify(props.relentlessMode)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#restore_modal"
        ref={restore_modal_ref}
      ></button>
      <Modal props={allRestoreVersion}>
        {props.records ? (
          <>
            {props?.records?.map((r, idx) => (
              <div
                className="d-flex pb-2 align-items-center justify-content-between"
                key={idx}
              >
                <div>{r.user_name}</div>
                <div>{formatDateMedium(r.updated_at)}</div>
                <div>
                  <button
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#preview_data"
                    onClick={() => onClickPreviewData(r.id)}
                  >
                    Preview
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    data-bs-dismiss="modal"
                    onClick={() => props.restoreData(r.id)}
                  >
                    Restore
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <Loading fullScreen="true" />
        )}
      </Modal>
      <Modal props={restoreModal}>
        <p>
          We have an Autosave that is newer than the current version. Do you
          want to restore the latest autosaved version?
        </p>
        <p>
          {props.latestRecord
            ? props.latestRecord.user_name +
              " " +
              formatDateMedium(props.latestRecord.updated_at)
            : ""}
        </p>
      </Modal>
      <Modal props={previewDataModal}>
        {!emailData ? (
          <Loading fullScreen="true" />
        ) : (
          <>
            <PreviewEmailContent
              icon={true}
              setPreviewDisplay={setPreviewDisplay}
              previewDisplay={previewDisplay}
              signature={emailData.signature}
              emailBody={emailData.email_body}
              emailSubject={
                emailData.email_subjects?.length > 0
                  ? emailData.email_subjects[0]
                  : ""
              }
            />
            <div className="mt-2 d-flex justify-content-end">
              <button
                className="btn btn-secondary"
                data-bs-toggle="modal"
                data-bs-target="#all_restore"
              >
                Back to list
              </button>
              <button
                className="btn btn-primary ms-2"
                data-bs-dismiss="modal"
                onClick={() => props.restoreData(emailData.id)}
              >
                Restore
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
