import React, { useState, useEffect } from "react";
import { fetchEvents } from "./eventsAPI";
import { Loading } from "../loader/Loading";
import { getTimeInterval } from "helpers/help";
import { DoughnutChart } from "../../components/DoughnutChart";
import axios from "axios";
import { Link } from "react-router-dom";
import PreviewEmail from "../../components/PreviewEmail";
import { setError } from "../error-handling/errorHandlingSlice";
import { useDispatch } from "react-redux";

export function EventUser({ event_user, filter }) {
  const dispatch = useDispatch();
  const [eventUser, setEventsUser] = useState(null);
  const EVENT_TYPE = ["ADDED", "EDITED", "DELETED", "SENT", "STOPPED"];
  const OBJECT_TYPE = ["lists", "press-releases", "campaigns"];
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setEvents(event_user, filter);
    return () => {
      source.cancel("axios request canceled");
    };
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const setEvents = async (user, filter) => {
    if (!user) return;
    try {
      setLoading(true);
      user["events"] = await fetchEvents(user.user_id, filter, source);
      user["expand"] = false;
      setEventsUser(user);
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  const toggleExpand = (user) => {
    setEventsUser({ ...user, expand: !user.expand });
  };

  const setChartData = (
    chartData,
    percentage,
    labels = ["Unopened", "Opened"],
  ) => {
    return {
      labels,
      datasets: [
        {
          data: chartData,
          backgroundColor: ["#0000001a", "#0d6efd"],
          text: percentage + "%",
        },
      ],
    };
  };

  return (
    <>
      <div className="card-title">{event_user.name}</div>
      <div className="card p-3">
        {isLoading || !eventUser ? (
          <Loading fullScreen="true" />
        ) : (
          <>
            {eventUser?.events?.length === 0 ? (
              <div className="wrapper-data mb-0">NO EVENTS</div>
            ) : (
              <></>
            )}
            {Array.isArray(eventUser.events) && eventUser.events?.length > 0 ? (
              <div className="d-flex flex-column overflow-hidden">
                {eventUser?.events?.map((e, idx) => {
                  if ((idx === 0 && !eventUser.expand) || eventUser.expand) {
                    let splitedPath = [""];
                    splitedPath = e.path.split(">");
                    return (
                      <div className="wrapper-data d-flex flex-wrap" key={idx}>
                        <div className="wrapper-preview" title="Preview email">
                          <PreviewEmail
                            signature={e.signature}
                            emailBody={e.email_body}
                            emailSubject={e.email_subjects}
                            id={`${e.object_id}-${new Date(e.date).getTime()}`}
                            icon={<i className="bi bi-eye"></i>}
                            key={idx}
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-between col wrapper-interval">
                          <div className="time-interval">
                            {getTimeInterval(new Date(e.date))}
                          </div>
                          <div>
                            <div className="pt-1">{splitedPath[0]}</div>
                            {e.path.substring(e.path.indexOf(">") + 1) &&
                            splitedPath.length > 1 ? (
                              <div className="color-grey">
                                {"> " +
                                  e.path.substring(e.path.indexOf(">") + 1)}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="pb-1">
                              <Link
                                to={`/${OBJECT_TYPE[e.object_type - 1]}/${e.object_id}`}
                              >
                                <i>{e.name}</i>
                              </Link>
                            </div>
                          </div>
                          <div className="color-grey">
                            {EVENT_TYPE[parseInt(e.event_type) - 1]}
                            <span>
                              {parseInt(e.event_type) === 5 ? (
                                <i className="ms-1 bi text-danger bi-triangle"></i>
                              ) : (
                                ""
                              )}
                            </span>
                            <span>
                              {parseInt(e.event_type) === 2 ? (
                                <i className="ms-1 bi bi-pencil-square"></i>
                              ) : (
                                ""
                              )}
                            </span>
                            <span>
                              {parseInt(e.event_type) === 1 ? (
                                <i className="ms-1 bi bi-plus-square"></i>
                              ) : (
                                ""
                              )}
                            </span>
                            <span>
                              {e.object_type === 3 &&
                              parseInt(e.event_type) === 4 &&
                              e.finish === 0 ? (
                                <i className="ms-1 bi text-warning bi-send"></i>
                              ) : (
                                ""
                              )}
                            </span>
                            <span>
                              {e.object_type === 3 &&
                              parseInt(e.event_type) === 4 &&
                              e.finish === 1 ? (
                                <i className="ms-1 bi text-success bi-send-check-fill"></i>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                        {e.object_type === 3 ? (
                          <>
                            <div className="divider"></div>
                            <div className="email-count col-md-2 col">
                              <div>Emails count</div>
                              <div>
                                {e.mails_count}
                                <i className="ms-1 bi bi-send"></i>
                              </div>
                            </div>
                            {e.statistics ? (
                              <>
                                <div className="divider"></div>
                                <div className="break-row"></div>
                                <div className="col flex-row d-flex justify-content-center wrapper-donut">
                                  <div className="d-flex flex-column align-items-center ms-2">
                                    <div>Opened</div>
                                    <DoughnutChart
                                      data={setChartData(
                                        [
                                          e.statistics.items_count -
                                            e.statistics.opened_count,
                                          e.statistics.opened_count,
                                        ],
                                        e.statistics.opened_percentage,
                                      )}
                                    />
                                  </div>
                                  <div className="d-flex flex-column align-items-center ms-2">
                                    <div>Bounced</div>
                                    <DoughnutChart
                                      data={setChartData(
                                        [
                                          e.statistics.items_count -
                                            e.statistics.bounce_count,
                                          e.statistics.bounce_count,
                                        ],
                                        e.statistics.bounce_percentage,
                                        ["Not Bounced", "Bounced"],
                                      )}
                                    />
                                  </div>
                                  <div className="d-flex flex-column align-items-center ms-2">
                                    <div>Unsubscribed</div>
                                    <DoughnutChart
                                      data={setChartData(
                                        [
                                          e.statistics.items_count -
                                            e.statistics.unsubscribed_count,
                                          e.statistics.unsubscribed_count,
                                        ],
                                        e.statistics.unsubscribed_percentage,
                                        ["Not Unsubscribed", "Unsubscribed"],
                                      )}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
                {eventUser.events.length > 1 ? (
                  <div
                    className="expand"
                    onClick={() => toggleExpand(eventUser)}
                  >
                    {eventUser.expand ? (
                      <i className="bi bi-arrow-up-short"></i>
                    ) : (
                      <i className="bi bi-arrow-down-short"></i>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
}
