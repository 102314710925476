import React, { memo, useEffect, useState } from "react";
import axios from "axios";
import apiClient from "../../services/api";
import { Loading } from "../loader/Loading";
import { Tooltip } from "../../components/Tooltip";
import "./successful-campaigns.scss";
import { checkPermission } from "helpers/help";
import { useDispatch } from "react-redux";
import { setError } from "../error-handling/errorHandlingSlice";

const SuccessfulCampaigns = ({
  showOwnCampaigns = false,
  showLastMonth,
  setHideSwitch,
  hideSwitch,
  showThreeMonths,
}) => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const extraCampaigns = 4;
  const dispatch = useDispatch();

  const range = (count) => [...Array(count).keys()];

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-GB",
      options,
    );
    return formattedDate;
  };

  const getMonthHeaderClass = (index, minCampaigns, max) => {
    let className = [];
    className.push("header");

    if (index === 1) {
      className.push("first");
    } else if (index === minCampaigns) {
      className.push("min");
    }
    if (index === max) {
      className.push("last");
    }

    if (index === minCampaigns + 1 && showOwnCampaigns) {
      className.push("bonus");
    }

    return className.join(" ");
  };

  const getMonthCellClass = (index, countCampaigns, minCampaigns, max) => {
    let className = [];
    className.push("cell");

    if (index > minCampaigns) {
      className.push(index <= countCampaigns ? "green center" : "extra center");
    } else {
      className.push(index > countCampaigns ? "bg-red" : "green");
    }

    if (index === max) {
      className.push("last");
    }

    return className.join(" ");
  };

  const fetchUserSuccessfulCampaigns = async function (source) {
    try {
      const response = await apiClient.get(
        `/api/campaigns-manager/user-successful-campaigns`,
        {
          cancelToken: source.token,
        },
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  };

  const fetchUsersSuccessfulCampaigns = async function (source) {
    try {
      const response = await apiClient.get(
        `/api/dashboard/users-successful-campaigns`,
        {
          cancelToken: source.token,
        },
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  };

  const getUserSuccessfulCampaigns = async () => {
    try {
      setLoading(true);
      let obj = [];
      if (showOwnCampaigns) {
        obj = await fetchUserSuccessfulCampaigns(source);
        if (!obj?.minCampaigns) {
          setHideSwitch({ ...hideSwitch, ownCampaigns: true });
        }
      } else if (
        checkPermission("role_pr", "fe-successful-campaigns") &&
        !showOwnCampaigns
      ) {
        obj = await fetchUsersSuccessfulCampaigns(source);
        if (obj?.length === 0) {
          setHideSwitch({ ...hideSwitch, allCampaigns: true });
        } else {
          setHideSwitch({ ...hideSwitch, allCampaigns: false });
        }
      }

      setData(obj);
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserSuccessfulCampaigns();
    return () => {
      source.cancel("axios request canceled");
      setData(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCampaignContent = (index, countCampaigns, min, max) => {
    return index > min && showOwnCampaigns ? <>💰</> : null;
  };

  const getToolTipContent = (index, month, countCampaigns, min, max) => {
    let toolTipContent = "";
    if (
      month?.completed_date[index - 1] &&
      month?.start_date[index - 1] &&
      month?.domain[index - 1] &&
      month?.campaigns[index - 1]
    ) {
      toolTipContent =
        month.domain[index - 1] +
        " (" +
        formatDate(month.completed_date[index - 1].substring(0, 10)) +
        "/" +
        formatDate(month.start_date[index - 1].substring(0, 10)) +
        "): " +
        month.campaigns[index - 1];
    }
    if (index <= countCampaigns && index <= min) {
      return toolTipContent;
    }

    if (index > min) {
      const isExtra = index <= countCampaigns;
      if (showOwnCampaigns) {
        const extraCount = index - min;
        const extraBonus =
          extraCount === 1 ? 100 : extraCount === 2 ? 150 : 200;
        const totalBonus =
          extraCount === 1
            ? 100
            : extraCount === 2
              ? 250
              : (extraCount - 2) * 200 + 250;
        return isExtra
          ? `Bonus for this campaign ${toolTipContent}:<br>£${extraBonus}<br>Total Bonus: <br>£${totalBonus} 🤑`
          : `You will get £${extraBonus} for this campaign 🤑`;
      } else {
        return isExtra ? toolTipContent : "";
      }
    }

    return "";
  };

  const getMonths = (data) => {
    let months = data.months ? data.months : [];
    if (showLastMonth) {
      months = months.slice(-1);
    }
    if (!showLastMonth && showThreeMonths) {
      months = months.slice(-3);
    }
    if (!showLastMonth && !showThreeMonths) {
      months = months.slice(-6);
    }
    return months;
  };

  const showData = (data) => {
    return (
      <>
        <h6>
          {data?.role} ({data?.minCampaigns})
        </h6>
        <div className="months">
          {getMonths(data).map((month, idx) => {
            return (
              <div key={month.header} className="month d-block" style={{ opacity: month.active ? 1 : 0.2 }} >
                <h6>
                  {month.header} - {month.count}
                </h6>
                <div className="campaigns">
                  {range(
                    Math.max(data.minCampaigns + extraCampaigns, month.count),
                  ).map((index) => (
                    <div key={index} className="month-cell">
                      <div
                        className={getMonthHeaderClass(
                          index + 1,
                          data.minCampaigns,
                          Math.max(
                            data.minCampaigns + extraCampaigns,
                            month.count,
                          ),
                        )}
                      >
                        {index === data.minCampaigns && showOwnCampaigns ? (
                          <span>BONUS</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <Tooltip
                        text={getToolTipContent(
                          index + 1,
                          month,
                          month.campaigns?.length,
                          data.minCampaigns,
                          Math.max(data.minCampaigns + extraCampaigns),
                        )}
                      >
                        <div
                          className={getMonthCellClass(
                            index + 1,
                            month.count,
                            data.minCampaigns,
                            Math.max(
                              data.minCampaigns + extraCampaigns,
                              month.count,
                            ),
                          )}
                        >
                          {getCampaignContent(
                            index + 1,
                            month.count,
                            data.minCampaigns,
                            Math.max(
                              data.minCampaigns + extraCampaigns,
                              month.count,
                            ),
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  if (isLoading) {
    return (
      <div className="user-successful-campaigns">
        <Loading fullScreen="true" />
      </div>
    );
  }

  if (showOwnCampaigns && !data?.minCampaigns) {
    return <></>;
  }

  return (
    <div className="user-successful-campaigns">
      {showOwnCampaigns && data ? showData(data) : <></>}
      {checkPermission("role_pr", "fe-successful-campaigns") &&
        !showOwnCampaigns &&
        data?.map((item, idx) => (
          <div style={{ marginBottom: "2.5rem" }} key={idx}>
            <h5>
              <b>{item.name}</b>
            </h5>
            {showData(item.data)}
          </div>
        ))}
    </div>
  );
};

export default memo(SuccessfulCampaigns);
