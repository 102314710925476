import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Nav from "../../components/Nav";
import { Loading } from "../loader/Loading";
import { createReview, fetchPrReviews, updateReview } from "./prAPI";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { formatDate } from "helpers/help";
import Select from "react-select";
import "./prReview.scss";
import { Tooltip } from "../../components/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { _getUser } from "../auth/loginSlice";
import { setError } from "../error-handling/errorHandlingSlice";

export function PrReview() {
  const dispatch = useDispatch();
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const per_page = 100;
  const [reviews, setReviews] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pressReleaseUrl, setPressReleaseUrl] = useState("");
  const [clientName, setClientName] = useState("");
  const [dataUrl, setDataUrl] = useState("");
  const [comment, setComment] = useState("");
  const [selectedReview, setSelectedReview] = useState(null);
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [checkInputs, setCheckInputs] = useState(false);
  const user = useSelector(_getUser);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const options = [
    "Pending",
    "In Progress",
    "Reviewed",
    "Feedback",
    "Feedback implemented",
    "Approved",
  ];

  const getPrReviews = async (pageOffset = 0) => {
    try {
      setLoading(true);
      const page = pageOffset + 1;
      const response = await fetchPrReviews(per_page, page, source);
      if (response) {
        setReviews(response.data);
        setPageCount(Math.ceil(response.total / per_page));
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setError(error?.message));
    }
  };

  const addReview = () => {
    setCheckInputs(false);
    setClientName("");
    setPressReleaseUrl("");
    setDataUrl("");
    setComment("");
  };

  const canSave =
    pressReleaseUrl.length > 0 && clientName.length > 0 && dataUrl.length > 0;

  const propModalReview = {
    title: "Add",
    target: "add_review",
    canSave: canSave,
    add: async () => {
      setCheckInputs(true);
      if (canSave) {
        try {
          setLoading(true);
          const data = {
            user_id: user.id,
            title: title,
            client_name: clientName,
            url: pressReleaseUrl,
            data_url: dataUrl,
            comment: comment,
            status: "Pending",
          };
          await createReview(data);
          await getPrReviews();
          setLoading(false);
        } catch (error) {
          setLoading(false);
          dispatch(setError(error?.message));
        }
      }
    },
  };

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
    getPrReviews(event.selected);
  };

  const handleSelect = (val) => {
    setStatus(val);
  };

  const propModalEditReview = {
    title: "Edit Status",
    target: "edit_status",
    add: async () => {
      if (status !== selectedReview.status) {
        try {
          setLoading(true);
          const data = {
            status: status,
            title: selectedReview.title,
            url: selectedReview.url,
            user_id: selectedReview.user_id,
          };
          await updateReview(data, selectedReview.id);
          let reviewIndex = reviews.findIndex(
            (r) => r.id === selectedReview.id,
          );
          if (reviewIndex > -1 && status !== "Approved") {
            reviews[reviewIndex]["over_time"] = false;
            reviews[reviewIndex]["status"] = status;
          }
          if (status === "Approved") {
            await getPrReviews();
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          dispatch(setError(error?.message));
        }
      }
    },
  };

  const onClickEditStatus = (data) => {
    setStatus(data.status);
    setSelectedReview(data);
  };

  const onClickChangeStatusFeedback = async (dataReview) => {
    try {
      setLoading(true);
      const data = {
        status: "Feedback Implemented",
        title: dataReview.title,
        url: dataReview.url,
        user_id: dataReview.user_id,
      };
      await updateReview(data, dataReview.id);
      let reviewIndex = reviews.findIndex((r) => r.id === dataReview.id);
      if (reviewIndex > -1) {
        reviews[reviewIndex]["over_time"] = false;
        reviews[reviewIndex]["status"] = data.status;
        reviews[reviewIndex]["can_edit_status"] = false;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setError(error?.message));
    }
  };

  useEffect(() => {
    document.title = "PR-Review - JournoResearch";
    dispatch(setError(""));
    getPrReviews();
    return () => {
      source.cancel("axios request canceled");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Loading fullScreen="true" />;
  }

  return (
    <>
      <Modal props={propModalReview}>
        <label>Title</label>
        <input
          type="text"
          className={`form-control ${title.length === 0 && checkInputs ? "is-invalid" : ""}`}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <label className="mt-3">Client Name</label>
        <input
          type="text"
          className={`form-control ${clientName.length === 0 && checkInputs ? "is-invalid" : ""}`}
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <label className="mt-3">Press Release URL</label>
        <input
          type="url"
          className={`form-control ${pressReleaseUrl.length === 0 && checkInputs ? "is-invalid" : ""}`}
          value={pressReleaseUrl}
          onChange={(e) => setPressReleaseUrl(e.target.value)}
        />
        <label className="mt-3">Data URL</label>
        <input
          type="url"
          className={`form-control ${dataUrl.length === 0 && checkInputs ? "is-invalid" : ""}`}
          value={dataUrl}
          onChange={(e) => setDataUrl(e.target.value)}
        />
        <label className="mt-3">Comment</label>
        <textarea
          type="text"
          className="form-control"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Modal>
      <Modal props={propModalEditReview}>
        {selectedReview ? (
          <Select
            className="select"
            options={options.map((val) => ({ value: val, label: val }))}
            onChange={(o) => handleSelect(o.value)}
            value={{ value: status, label: status }}
          />
        ) : (
          ""
        )}
      </Modal>
      <Nav selectedListItem={{ name: "Press Release Review" }}>
        <button
          className="btn me-2 btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#add_review"
          onClick={() => addReview()}
        >
          Request feedback
        </button>
      </Nav>
      {reviews?.length > 0 ? (
        <div className="row">
          <div className="table-responsive">
            <table className="table table-striped table-pr-reviews">
              <thead>
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col" className="col-table">
                    Title
                  </th>
                  <th scope="col" className="col-table">
                    Client Name
                  </th>
                  <th scope="col" className="col-table">
                    URL
                  </th>
                  <th scope="col" className="col-table">
                    Data URL
                  </th>
                  <th scope="col" className="col-table">
                    Comment
                  </th>
                  <th scope="col" className="col-table">
                    Status
                  </th>
                  <th scope="col" className="col-table">
                    Updated at
                  </th>
                </tr>
              </thead>
              <tbody>
                {reviews.map((data, index) => (
                  <tr key={index} className={data.over_time ? "over_time" : ""}>
                    <td>{data.user_name}</td>
                    <td className="col-client-name">
                      <div>{data.title}</div>
                    </td>
                    <td className="col-client-name">
                      <div>{data.client_name}</div>
                    </td>
                    <td className="col-table">
                      <div>
                        <a href={data.url} rel="noreferrer" target="_blank">
                          {data.url}
                        </a>
                      </div>
                    </td>
                    <td className="col-table">
                      <div>
                        <a
                          href={data.data_url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {data.data_url}
                        </a>
                      </div>
                    </td>
                    <td className="col-table">
                      <Tooltip
                        text={data.comment?.length > 55 ? data.comment : ""}
                      >
                        <div>{data.comment}</div>
                      </Tooltip>
                    </td>
                    <td className="col-table">
                      <div>
                        {data.status}
                        {data.can_edit_status === "all_options" ? (
                          <i
                            className="bi bi-pencil-fill text-primary ps-2"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_status"
                            onClick={() => onClickEditStatus(data)}
                          ></i>
                        ) : (
                          ""
                        )}
                        {data.can_edit_status === "Feedback" ? (
                          <Tooltip text="Change status to Feedback Implemented">
                            <i
                              className="bi bi-box-arrow-in-right text-success ps-2"
                              onClick={() => onClickChangeStatusFeedback(data)}
                            ></i>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td className="col-table">{formatDate(data.updated_at)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
      {!isLoading && pageCount > 0 ? (
        <>
          <div className="d-none d-sm-flex nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
          <div className="d-flex d-sm-none nav-pagination">
            <ReactPaginate
              previousLabel="&#x276E;"
              nextLabel="&#x276F;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
