import Modal from "../../components/Modal";
import ReactHtmlParser from 'html-react-parser';
import { getSelectedTemplate } from "./pressReleasesSlice";
import { useSelector } from "react-redux";

export const Templates = () => {
    const selectedTemplate = useSelector(getSelectedTemplate);
    const modalPropTemplates = {
        target: 'template',
        disableFooter: true,
        size: 'lg'
    }

    return <>
        {<Modal props={{...modalPropTemplates, title: selectedTemplate && selectedTemplate['title'] ? selectedTemplate['title'] : ''}}>
            {ReactHtmlParser(selectedTemplate && selectedTemplate['content'] ? selectedTemplate['content'] : '')}
        </Modal>}
    </>
}